import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DayPicker, MonthPicker, YearPicker } from 'react-dropdown-date'

const useStyles = makeStyles(theme => ({
    inputTitle: {
        fontWeight: 700,
        marginBottom: theme.spacing(1),
    },
    select: {
        width: '100%',
        padding: '14px 12px'
    }
}));

const DateDropdown = ({ name, startYear, endYear, reverse, register, setDateValue, value }) => {
    const [year, setYear] = useState(null)
    const [month, setMonth] = useState(null)
    const [day, setDay] = useState(null)

    const classes = useStyles();

    useEffect(() => {
        if (value) {
            setYear(new Date(value).getFullYear())
            setMonth(new Date(value).getMonth())
            setDay(new Date(value).getDate())
        }
    }, [value])


    useEffect(() => {
        const fixFormatDate = () => {
            const tempMonth = (Number(month) + 1).toString()
            const y = year || ''
            const m = month ? tempMonth.length < 2 ? '0' + tempMonth : tempMonth : ''
            const d = day ? day.toString().length < 2 ? '0' + day : day : ''

            return y + '-' + m + '-' + d;
        }
        if (year && month && day) {
            setDateValue(fixFormatDate())
        }
    }, [year, month, day])

    return (
        <>
            <Grid container justifyContent='space-between' spacing={3}>
                <Grid item xs={4}>
                    <YearPicker
                        id={'year'}
                        name={'year'}
                        //error={errors.year ? true : false}
                        defaultValue={'aña'}
                        start={startYear}
                        end={endYear}
                        reverse={reverse}
                        required={true}
                        classes={classes.select}
                        optionClasses={'option'}
                        value={year || ''}
                        onChange={year => {
                            setYear(year)
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MonthPicker
                        id='month'
                        name='month'
                        //error={errors.month ? true : false}
                        defaultValue={'luna'}
                        short
                        //year={year}
                        required={true}
                        classes={classes.select}
                        optionClasses={'option'}
                        onChange={month => {
                            setMonth(month)
                        }}
                        value={month || ''}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DayPicker
                        id={'day'}
                        name={'day'}
                        //error={errors.day ? true : false}
                        defaultValue='dia'
                        year={year}
                        month={month}
                        classes={classes.select}
                        optionClasses={'option'}
                        onChange={day => {
                            setDay(day)
                        }}
                        value={day || ''}
                    />
                </Grid>
            </Grid>
            <input name={name} type="string" ref={register} hidden />
        </>
    )
}

export default DateDropdown
