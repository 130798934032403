import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Swiper, SwiperSlide } from 'swiper/react'
import { Image } from 'components/atoms';



const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    zIndex: 1
  },
  swiper: {
    width: '100%',
    height: 645,
  },
  swiperNavButton: {
    width: `${theme.spacing(3)}px !important`,
    height: `${theme.spacing(3)}px !important`,
    padding: `${theme.spacing(2)}px !important`,
  },
  image: {
    objectFit: 'cover',
  }
}));

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  //const theme = useTheme();
  // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
  //   defaultMatches: true,
  // });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        effect="fade"
        speed={1000}
        pagination
        className={classes.swiper}
      >
        <SwiperSlide zoom><Image src='/images/photos/home/slide-1.jpg' srcSet='/images/photos/home/slide-1.jpg 2x' lazyProps={{ width: '100%', height: '100%' }} className={classes.image}></Image></SwiperSlide>
        <SwiperSlide zoom><Image src='/images/photos/home/slide-2.jpg' srcSet='/images/photos/home/slide-2.jpg 2x' lazyProps={{ width: '100%', height: '100%' }} className={classes.image}></Image></SwiperSlide>
      </Swiper>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
