import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { SectionAlternate, CardBase } from 'components/organisms';
import { Hero } from './components';
import { General } from 'views/DashBoardView/components';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
    },
    section: {
        '& .section-alternate__content': {
            paddingTop: 0,
            marginTop: theme.spacing(-5),
            position: 'relative',
            zIndex: 1,
        },
        '& .card-base__content': {
            padding: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(3),
            },
        },
    },
}));



const DashBoardView = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Hero />
            <SectionAlternate className={classes.section}>
                <Grid container spacing={4}>

                    <Grid item xs={12}>
                        <CardBase withShadow align="left">
                            <General />
                        </CardBase>
                    </Grid>
                </Grid>
            </SectionAlternate>
        </div>
    );
};

export default DashBoardView;
