import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Controller } from "react-hook-form";


const StatusSelect = ({ name, label, control, required, error }) => {
    return (
        <Controller
            render={props => (
                <Autocomplete
                    {...props}
                    options={statusList}
                    getOptionLabel={option => typeof option === "string" ? statusList.find(s => s.value === option).label : option.label}
                    getOptionSelected={(option, value) => {
                        return option.value === (typeof value === "string" ? statusList.find(s => s.value === value).value : value.value)
                    }}
                    renderInput={params => {
                        return <TextField
                            {...params}
                            label={label}
                            variant="outlined"
                            required={required}
                            error={error}
                        />
                    }}
                    onChange={(_, data) => {
                        return data && props.onChange(data.value)
                    }}
                />
            )}
            name={name}
            control={control}
        />
    );
}



const statusList = [
    { value: "single", label: 'No ta kasá òf tin un konbibensia legalisá di pareha' },
    { value: "married", label: "Kasá" },
    { value: "registeredPartnership", label: "Konbibensia legalisá di pareha" },
    { value: "divorced", label: "Divorsiá" },
    { value: "divorcedPartnership", label: "Divorsiá for di konbibensia legalisá di pareha" },
    { value: "widow", label: "Viuda/o" }
]

export default StatusSelect;