import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';



import {
    useMediaQuery,
    Grid,
    CircularProgress,
    Button
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import RequestDetails from 'components/RequestDetails';
import { useParams, useHistory } from 'react-router-dom';



const useStyles = makeStyles(theme => ({
    root: {
    },
    inputTitle: {
        fontWeight: 700,
        marginBottom: theme.spacing(1),
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));


const General = props => {
    const { className, ...rest } = props;
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState(null);
    const [error, setError] = useState(null);
    const history = useHistory();

    const fetchRequest = async () => {
        setLoading(true);
        const result = await fetch(`${process.env.REACT_APP_API}/api/request/${id}`)
        let data;
        if (result.ok) {
            data = await result.json()
        } else {
            setError({ message: `Oops algu a bai robes: ${result.statusText}` });
        }
        setLoading(false);
        setRequest(data);
    }
    useEffect(() => {
        fetchRequest();
    }, [id])
    const classes = useStyles

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    if (error) return (<Grid container spacing={isMd ? 4 : 2}><Grid item xs={12}><Alert severity='error'>{error.message}</Alert></Grid></Grid>)

    return (
        <div className={clsx(classes.root, className)} {...rest} style={{ width: '100%' }}>
            <Grid container spacing={isMd ? 4 : 2}>
                {!loading && request ? (
                    <>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                size="large"
                                onClick={() => history.push('/request-status')}
                            >
                                Bai Bèk
                            </Button>
                        </Grid>
                        <RequestDetails request={request} />
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                size="large"
                                onClick={() => history.push('/request-status')}
                            >
                                Bai Bèk
                            </Button>
                        </Grid>
                    </>) : <CircularProgress color="primary" />}

            </Grid>

        </div>
    );
};

General.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
};

export default General;
