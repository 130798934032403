import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery, GridList, GridListTile, GridListTileBar, Typography, CircularProgress } from '@material-ui/core';

const ImageViewer = ({ match }) => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    const useStyles = makeStyles(theme => ({
        root: {},
        inputTitle: {
            fontWeight: 700,
            marginBottom: theme.spacing(1),
        },
        gridList: {
            paddingTop: 40,
            transform: 'translateZ(0)',
        },
        gridListTile: {
            width: '100%'
        },

        title: {
            color: theme.palette.primary.light,
        },
        titleBar: {
            background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
    }))
    const classes = useStyles();


    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });



    useEffect(() => {
        const getImages = async () => {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_API}/api/request/${match.params.id}/images${match.params.categoryId ? `?categoryId=${match.params.categoryId}` : ''}`);
            const imgList = await response.json()
            setImages(imgList);
            setLoading(false);
        }
        getImages();
    }, [match])

    return (
        <div>
            <Grid container spacing={isMd ? 4 : 2}>
                <Grid item xs={2} />
                <Grid item xs={8}>
                    {loading && <CircularProgress color="primary" size='5rem' />}
                    {!loading &&
                        <GridList className={classes.gridList} cellHeight={'auto'} cols={0}>
                            {images && images.length > 0 ?
                                images.map((image, index) => (
                                    <GridListTile key={index} className={classes.gridListTile}>
                                        {
                                            image.type?.includes('application/pdf') ?
                                                <>
                                                    <Typography variant="h6" color="primary" className={classes.inputTitle}>{image.category.name}</Typography>
                                                    <iframe title={image.name} src={`${image.type},${image.base64}`} width="100%" height="580px"></iframe>
                                                </> :
                                                <>
                                                    <Typography variant="h6" color="primary" className={classes.inputTitle}>{image.category.name}</Typography>
                                                    <img src={`${image.type},${image.base64}`} alt={image.name} style={{ width: '100%', objectFit: 'cover' }} />
                                                    <GridListTileBar
                                                        title={image.name}
                                                    />
                                                </>
                                        }

                                    </GridListTile>
                                )) : <GridListTile className={classes.gridListTile}>
                                    <img src='/images/photos/noimage.png' alt='' style={{ width: '100%', objectFit: 'cover' }} />


                                </GridListTile>}
                        </GridList>}
                </Grid>
                <Grid item xs={2} />

            </Grid>
        </div>
    )
}

export default ImageViewer
