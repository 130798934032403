import KeyCloak from 'keycloak-js'

const keycloakConfig = {
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: 'SOAW',
    clientId: 'soaw-ui'

}

const keycloak = new KeyCloak(keycloakConfig);
export default keycloak;