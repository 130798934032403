import React, { useEffect } from 'react';
import { useKeycloak } from "@react-keycloak/web";
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout, PrivateRouteWithLayout } from './common';
import { Main as MainLayout } from './layouts';

import {
    Home as HomeView,
    RequestForm as RequestFormView,
    RequestFormStepper as RequestFormStepperView,
    RequestStatus as RequestStatusView,
    Admin as AdminView,
    AdminRequestFormStepper as AdminRequestFormStepperView,
    Success as SuccessView,
    ImageViewer as ImageViewerView,
    FoodPackageForm as FoodPackageView,
    ResilienceForm as ResilienceView,
    GoogleMapsSearch as GoogleMapsSearchView,
    RequestDetailsView,
    AdminRequestDetailsView,
    DashBoardView
} from './views';
import { useStateValue } from 'StateProvider';


const Routes = () => {
    const [keycloak, initialized] = useKeycloak();
    const [, dispatch] = useStateValue();

    useEffect(() => {

        dispatch({
            type: 'SET_INITIALIZED_KEYCLOAK',
            initializedKeycloak: initialized
        })

        if (keycloak.idTokenParsed) {
            dispatch({
                type: 'SET_ID_TOKEN',
                idToken: keycloak.idTokenParsed
            })
        }

    }, [dispatch, initialized, keycloak.idTokenParsed])


    return (
        <Switch>
            <Redirect exact from="/" to="/home" />
            {/* <PrivateRoute roles={['RealmAdmin']} path="/protected" component={Request} /> */}
            <RouteWithLayout
                component={HomeView}
                exact
                layout={MainLayout}
                path="/home"
            />
            <PrivateRouteWithLayout
                roles={['SoawAdmin']}
                component={DashBoardView}
                exact
                layout={MainLayout}
                path="/dashboard"
            />
            <PrivateRouteWithLayout
                roles={['SoawAdmin']}
                component={GoogleMapsSearchView}
                exact
                layout={MainLayout}
                path="/google-maps-search"
            />
            <PrivateRouteWithLayout
                roles={['SoawClient']}
                component={RequestFormView}
                exact
                layout={MainLayout}
                path="/request-form"
            />
            <PrivateRouteWithLayout
                roles={['SoawClient']}
                component={FoodPackageView}
                exact
                layout={MainLayout}
                path="/foodpackage-form"
            />
            <PrivateRouteWithLayout
                roles={['SoawClient']}
                component={ResilienceView}
                exact
                layout={MainLayout}
                path="/resilience-form"
            />
            <PrivateRouteWithLayout
                roles={['SoawClient']}
                component={RequestFormStepperView}
                exact
                layout={MainLayout}
                path="/request-form-stepper"
            />
            <PrivateRouteWithLayout
                roles={['SoawClient']}
                component={RequestFormStepperView}
                layout={MainLayout}
                path="/request-form-stepper/:id/edit"
            />
            <PrivateRouteWithLayout
                roles={['SoawClient']}
                component={RequestDetailsView}
                layout={MainLayout}
                path="/request-details-view/:id"
            />
            <PrivateRouteWithLayout
                roles={['SoawAdmin']}
                component={RequestFormStepperView}
                exact
                layout={MainLayout}
                path="/admin/request-form-stepper"
            />
            <PrivateRouteWithLayout
                roles={['SoawAdmin']}
                component={AdminRequestFormStepperView}
                layout={MainLayout}
                path="/admin/request-form-stepper/:id/edit"
            />
            <PrivateRouteWithLayout
                roles={['SoawAdmin']}
                component={AdminRequestDetailsView}
                layout={MainLayout}
                path="/admin/request-details-view/:id"
            />
            <PrivateRouteWithLayout
                roles={['SoawClient']}
                component={RequestStatusView}
                exact
                layout={MainLayout}
                path="/request-status"
            />

            <PrivateRouteWithLayout
                roles={['SoawAdmin']}
                component={AdminView}
                exact
                layout={MainLayout}
                path="/admin"
            />
            <RouteWithLayout
                component={SuccessView}
                exact
                layout={MainLayout}
                path="/success"
            />
            <RouteWithLayout
                roles={['SoawAdmin']}
                component={ImageViewerView}
                exact
                layout={MainLayout}
                path="/request/:id/(category)?/:categoryId?"
            />
        </Switch>
    );
};

export default Routes;
