import React, { useState, useEffect } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';


const MapContainer = ({ google, style }) => {
    const [showingInfoWindow, setShowingInfoWindow] = useState(false)
    const [activeMarker, setActiveMarker] = useState(null);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState([]);
    const [showMarker, setShowMarker] = useState(false);
    const [address, setAddress] = useState('')
    const [mapCenter, setMapCenter] = useState({
        lat: 12.169570,
        lng: -68.990021
    })
    const [points, setPoints] = useState([{
        lat: 12.169570,
        lng: -68.990021
    }]);

    const onMarkerClick = (props, marker, e) => {

        setActiveMarker(marker);
        setShowingInfoWindow(true);
    }

    const handleSelect = async (option) => {
        if (option) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/api/request/address?street=${option.registeredAddress}`);
                const addresses = await response.json();
                const address = option.registeredAddressNumber + ' ' + option.registeredAddress
                const addressResult = await geocodeByAddress(address)
                const latLng = await getLatLng(addressResult[0])
                setMapCenter(latLng);
                setShowMarker(true)
                setRequest(addresses.filter(request => request.userId === option.userId))
            } catch (error) {
                console.error('Error', error)
            }
        }

    };
    const onInfoWindowClose = () => { }


    useEffect(() => {
        let active = true;

        setLoading(true);

        (async () => {
            const response = await fetch(`${process.env.REACT_APP_API}/api/request/address?street=${address}`);
            const addresses = await response.json();

            if (active) {
                setOptions(addresses);
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [address]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <>
            <Autocomplete
                id="street-autocomplete"
                style={{ width: 300 }}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                getOptionSelected={(option, value) => {
                    return option.userId === value.userId
                }}
                getOptionLabel={(option) => option.registeredAddress + ' ' + option.registeredAddressNumber}
                options={options}
                loading={loading}
                onChange={(event, value) => handleSelect(value)}
                onInputChange={(event, newInputValue) => {
                    setAddress(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Buska adres..."
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />

            <Map
                google={google}
                zoom={14}
                initialCenter={mapCenter}
                center={mapCenter}
                style={style}
                on
            //bounds={bounds}
            >
                {showMarker &&
                    <Marker
                        position={mapCenter}
                        onClick={onMarkerClick}
                    />}
                <InfoWindow
                    marker={activeMarker}
                    visible={showingInfoWindow}>
                    <div>
                        <h1>{`${request.length > 0 && request[0].lastName}`}</h1>
                        <h1>{`${request.length > 0 && request[0].firstName}`}</h1>
                        petishon:
                        <ol>
                            {request.map(item => {
                                return <li key={item.id}>{item.type === 1 ? "Onderstand" : item.type === 2 ? "Pekete di kuminda" : item.type === 3 ? "Karchi sosial" : ''}</li>
                            })}
                        </ol>


                    </div>
                </InfoWindow>
            </Map>
        </>
    );
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API
})(MapContainer)