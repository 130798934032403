import React from 'react'
import MaterialTable, { Column } from "@material-table/core";
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const RequestStatusList = ({ requests }) => {
    const history = useHistory()
    return (
        <Grid item xs={12}>
            <MaterialTable
                title="Lista di Pedido"
                columns={[
                    {
                        title: 'Formulario', field: 'type', render: rowData => {
                            switch (rowData.type) {
                                case 1:
                                    return 'Onderstand'
                                case 2:
                                    return 'Pakete di kuminda'
                                case 3:
                                    return 'Karchi Sosial'
                                default:
                                    break;
                            }
                        }
                    },
                    { title: 'Fam', field: 'lastName' },
                    { title: 'Nomber', field: 'firstName' },
                    { title: 'Number di identifikashon', field: 'identificationNumber' },
                    { title: 'Fecha di petishon', field: 'created', render: rowData => moment(rowData.created).format('DD-MM-YYYY') },
                    { title: 'Status', field: 'status', render: rowData => (rowData.status?.includes("{") && (typeof JSON.parse(rowData.status) === 'object')) ? JSON.parse(rowData.status).Value : rowData.status },
                ]
                }
                data={requests}
                // detailPanel={rowData => {
                //     return <Request id={rowData.id} />
                // }}
                actions={[
                    {
                        icon: 'info',
                        tooltip: 'detail',
                        onClick: (event, rowData) => history.push(`/request-details-view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'edit',
                        onClick: (event, rowData) => history.push(`/request-form-stepper/${rowData.id}/edit`)
                    }
                ]}
                options={{
                    search: false,
                    actionsColumnIndex: -1
                }}
                style={{ width: '100%' }}
            />
        </Grid>
    )
}

export default RequestStatusList
