import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    useMediaQuery,
    Grid,

} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import GoogleMap from 'components/GoogleMap';


const useStyles = makeStyles(theme => ({
    root: {
        width: 'stretch'
    },
    inputTitle: {
        fontWeight: 700,
        marginBottom: theme.spacing(1),
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));

const General = props => {
    const { className, ...rest } = props;


    const classes = useStyles

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });



    // if (error) return (<Grid container spacing={isMd ? 4 : 2}><Grid item xs={12}><Alert severity='error'>{error.message}</Alert></Grid></Grid>)

    return (
        <div style={{ width: '100%' }}>
            <GoogleMap style={{ height: '90%', width: '95%' }} />
        </div>
    );
};

General.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
};

export default General;
