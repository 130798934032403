import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    AppBar,
    Toolbar,
    Drawer,
    IconButton,
    Divider,
} from '@material-ui/core';
import ForumIcon from '@material-ui/icons/Forum';
import { ContactForm } from 'common';
import {
    Hero,
} from './components';

// import {
//     mapData,
//     advantages,
//     locations,
//     properties,
//     reviews,
//     events,
//     gallery,
// } from './data';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
    },
    banner: {
        height: 645,
        width: '100%',
    },
    appBarBottom: {
        top: 'auto',
        bottom: 0,
        background: 'transparent',
        boxShadow: 'none',
    },
    toolbarBottom: {
        width: '100%',
        margin: '0 auto',
        padding: theme.spacing(0, 2),
    },
    chatIconButton: {
        position: 'absolute',
        right: theme.spacing(3),
        left: 'auto',
        top: theme.spacing(-3),
        background: theme.palette.primary.main,
        width: 55,
        height: 55,
        boxShadow: '0 2px 10px 0 rgba(23,70,161,.11)',
        '&:hover': {
            background: theme.palette.primary.main,
        },
    },
    forumIcon: {
        color: 'white',
        width: 25,
        height: 25,
    },
    contactForm: {
        padding: theme.spacing(3, 2),
        maxWidth: 800,
        margin: '0 auto',
    },
}));

const Home = () => {
    const classes = useStyles();

    const [openBottombar, setOpenBottombar] = React.useState(false);

    const handleBottombarOpen = () => {
        setOpenBottombar(true);
    };

    const handleBottombarClose = () => {
        setOpenBottombar(false);
    };

    return (
        <div className={classes.root}>
            <Hero className={classes.banner} />
            <Divider />
            <AppBar position="fixed" className={classes.appBarBottom}>
                <Toolbar disableGutters className={classes.toolbarBottom}>
                    <IconButton
                        className={classes.chatIconButton}
                        onClick={handleBottombarOpen}
                    >
                        <ForumIcon className={classes.forumIcon} />
                    </IconButton>
                    <Drawer
                        anchor="bottom"
                        open={openBottombar}
                        onClose={handleBottombarClose}
                    >
                        <div className={classes.contactForm}>
                            <ContactForm />
                        </div>
                    </Drawer>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Home;
