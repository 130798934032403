import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import { KeycloakProvider } from '@react-keycloak/web'
import keycloak from './keycloak';
import AOS from 'aos';

import theme from './theme';
import Routes from './Routes';

import 'swiper/swiper-bundle.css';
import 'aos/dist/aos.css';
import './assets/scss/index.scss';
import { CircularProgress } from '@material-ui/core';



const browserHistory = createBrowserHistory();

browserHistory.listen(location => {
  // Use setTimeout to make sure this runs after React Router's own listener
  setTimeout(() => {
    // Keep default behavior of restoring scroll position when user:
    // - clicked back button
    // - clicked on a link that programmatically calls `history.goBack()`
    // - manually changed the URL in the address bar (here we might want
    // to scroll to top, but we can't differentiate it from the others)
    if (location.action === 'POP') {
      return;
    }
    // In all other cases, scroll to top
    window.scrollTo(0, 0);
  });
});

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      delay: 50,
      duration: 500,
      easing: 'ease-in-out',
    });
    AOS.refresh();
  })
  return (
    <KeycloakProvider keycloak={keycloak} LoadingComponent={<CircularProgress size={180} color="primary" style={{ display: 'block', margin: 'auto' }} />}>
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </ThemeProvider>
    </KeycloakProvider>

  );
}

export default App;
