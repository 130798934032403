
import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Grid } from '@material-ui/core'
import { CardBase } from 'components/organisms';
import { makeStyles } from '@material-ui/core/styles';
import RequestDetails from './RequestDetails';

const Request = ({ id }) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        try {
            setIsLoading(true)
            const result = await fetch(`${process.env.REACT_APP_API}/api/request/${id}`).then(res => res.json())
            setData(result);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            setError(error)
        }

    }

    useEffect(() => {
        fetchData();
    }, [id])

    const useStyles = makeStyles(theme => ({
        root: {},
        inputTitle: {
            fontWeight: 700,
            marginBottom: theme.spacing(1),
        },
        gridList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
        },
        title: {
            color: theme.palette.primary.light,
        },
        titleBar: {
            background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
        button: {
            margin: theme.spacing(1),
        },
    }));
    const classes = useStyles();

    return (
        isLoading ? <CircularProgress color="primary" size={180} style={{ display: 'block', margin: 'auto' }} /> : error ? <Alert severity='error'>{error}</Alert> :
            <CardBase withShadow align="left" className={classes.menu}>
                <Grid container spacing={2}>
                    <RequestDetails request={data} />
                </Grid>
            </CardBase >

    )
}

export default Request
