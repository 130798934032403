import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Controller } from "react-hook-form";


const AddressSelect = ({ control, name, label, required, error, placeholder }) => {
    return (
        <Controller
            render={props => (
                <Autocomplete
                    {...props}
                    options={addressList}
                    freeSolo
                    getOptionLabel={option => option}
                    renderOption={option => (
                        <React.Fragment>
                            {option}
                        </React.Fragment>
                    )}
                    getOptionSelected={(option, value) => option === value}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={label}
                            variant="outlined"
                            placeholder={placeholder}
                            required={required}
                            error={error}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    onChange={(_, data) => props.onChange(data)}
                />
            )
            }
            name={name}
            control={control}
        />
    );
}

const addressList = [
    '',
    'A.ALTAGRACIA DE LANNOY-WILLEMS BOULEVARD',
    'ABACO WEG',
    'ABATTOIR WEG',
    'ABRAHAM DE VEER STRAAT',
    'ABRAHAM MENDEZ CHUMACEIRO BOULEVARD',
    'ABRAHAM MENDEZ CHUMACEIRO KADE',
    'ABRAHAMSZ',
    'ACACIASTRAAT',
    'ACHILLESWEG',
    'ACKLINS WEG',
    'ACTUAL',
    'ADJUDANTEN WEG',
    'ADMIRAALS WEG',
    'AIMARA WEG',
    'AJAX WEG',
    'ALABAMA WEG',
    'ALABLANCA WEG',
    'ALASKA WEG',
    'ALBINA STRAAT',
    'ALEXANDER LAAN',
    'ALGONKIN WEG',
    'ALIDA',
    'ALLEGHANY WEG',
    'ALMADINA STRAAT',
    'ALOE STRAAT',
    'ALPEN WEG',
    'ALTO VISTA WEG',
    'AMALIA VAN SOLMS LAAN',
    'AMANDEL WEG',
    'AMAZONE WEG',
    'AMERIKA WEG',
    'AMERIKANENKAMP F',
    'AMPERE STRAAT',
    'AMSTEL STRAAT',
    'AMSTERDAM OTRABANDA',
    'AMSTERDAM STA ROSA',
    'ANASA WEG',
    'ANDES WEG',
    'ANDICOERI WEG',
    'ANDRES BELLO WEG',
    'ANDROMEDA WEG',
    'ANDROS WEG',
    'ANEGADA WEG',
    'ANGLO WEG',
    'ANGOLA WEG',
    'ANGUILLA WEG',
    'ANJELIER WEG',
    'ANSINGH STRAAT',
    'ANTIGUA WEG',
    'ANTILLEN WEG',
    'ANZUE WEG',
    'APARAI WEG',
    'APELSINA WEG',
    'APENIJNEN WEG',
    'APOLLO STRAAT',
    'APPELDAM WEG',
    'APPELSINAWEG',
    'APURE WEG',
    'AQUARIUS WEG',
    'ARANCANOS WEG',
    'ARARAT',
    'ARASJI WEG',
    'ARAUCA WEG',
    'ARCHITECTEN WEG',
    'ARCTURUS WEG',
    'ARDENNEN WEG',
    'ARENAS WEG',
    'ARGENTINA WEG',
    'ARGUS WEG',
    'ARIKARA WEG',
    'ARIKOK WEG',
    'ARNHEM STRAAT',
    'AROWAKEN WEG',
    'ARRARAT A',
    'ARRARAT WEG',
    'ARRARATWIJK',
    'ARTEMIS WEG',
    'ARTILLERIE WEG',
    'ARUBA STRAAT',
    'ASBEST WEG',
    'ASCENCION',
    'ASIENTO',
    'ASTEROIDEN WEG',
    'ATEMISIA WEG',
    'ATHABASKEN WEG',
    'ATJEH STRAAT',
    'ATLAS STRAAT',
    'ATRATO WEG',
    'AURIGA WEG',
    'AUSTRALI',
    'AVENIDA DE LOS MARES',
    'AVES WEG',
    'AWA BLANKU',
    'AWAKATI WEG',
    'AZORENWEG',
    'AZTEKEN WEG',
    'BAAI KLAAS A',
    'BAAI MACOLA WEG',
    'BAAS GRACIE',
    'BACHAQUERO WEG',
    'BACOBA WEG',
    'BACOENA WEG',
    'BACOVAL',
    'BADEN POWELL WEG',
    'BAHAMAS WEG',
    'BAI KLAS',
    'BAJONET STRAAT',
    'BAKKER STRAAT',
    'BAKUFAL',
    'BALAOE STRAAT',
    'BALASHI WEG',
    'BALATA WEG',
    'BALI STEEG',
    'BALKAN WEG',
    'BANANA WEG',
    'BANCKERT LAAN',
    'BANKA STEEG',
    'BANKWERKERS WEG',
    'BAPOR KIBRA',
    'BAPOR KIBRA B',
    'BARACOEDA STRAAT',
    'BARAHONA WEG',
    'BARALTWIJK',
    'BARALTWIJK D',
    'BARBADOS WEG',
    'BARBARA BEACH',
    'BARBER',
    'BARBER &nbsp;N',
    'BARBER A',
    'BARBER AH',
    'BARBER AI',
    'BARBER AJ',
    'BARBER AK',
    'BARBER C',
    'BARBER KAYA O',
    'BARBER KAYA O.',
    'BARBER O',
    'BARBER Q',
    'BARBOUQUET',
    'BARBOUQUET A',
    'BARBOUQUET B',
    'BARBOUQUET C',
    'BARBOUQUET D',
    'BARBOUQUET E',
    'BARBOUQUET F',
    'BARBOUQUET G',
    'BARBOUQUET H',
    'BARBOUQUET I',
    'BARBOUQUET K',
    'BARBOUQUET L',
    'BARBOUQUET P',
    'BARBOUQUET Q',
    'BARBOUQUET R',
    'BARBUKET',
    'BARENTS LAAN',
    'BARGE STRAAT',
    'BARICAGEEL WEG',
    'BARIKA',
    'BARTOL BAAI',
    'BASORA PRETU WEG',
    'BASTET STRAAT',
    'BATALJON WEG',
    'BATAVIERWIJK',
    'BATAVIERWIJK B',
    'BATIPANJA STRAAT',
    'BATIPANJAWIJK',
    'BATTERIJ STRAAT',
    'BAYA BEACH',
    'BEATRIX LAAN',
    'BEATRIX PLEIN',
    'BEGONIA WEG',
    'BELLEVUE',
    'BELLISIMA WEG',
    'BELVEDERE STRAAT',
    'BEMBE WEG',
    'BEN HOYER',
    'BENTA WEG',
    'BENTURA',
    'BENTURA WEG',
    'BEQUIA WEG',
    'BEREHEIN WEG',
    'BERG ALTENA',
    'BERG ALTENA G',
    'BERG ARRARAT',
    'BERG CARMEL',
    'BERG CARMEL WEG',
    'BERG DOMI',
    'BERG DOMI STRAAT',
    'BERG DOMI STRAAT A',
    'BERG STRAAT',
    'BERGANTIN BAAI',
    'BERKEL STRAAT',
    'BERMUDA WEG',
    'BERNAGIE STRAAT',
    'BERNHARD LAAN',
    'BERRY WEG',
    'BESHI WEG',
    'BETHANCOURTSTRAAT',
    'BETHENCOURT STRAAT',
    'BEURS',
    'BEVER',
    'BIBIR',
    'BIBIR KONTENTU',
    'BIESHEUVEL',
    'BIJGELEGEN',
    'BIJGELEGEN A',
    'BIJGELEGEN WEG',
    'BILLITON STEEG',
    'BIMINIS WEG',
    'BINNEN WEG',
    'BISENTO F',
    'BISENTU',
    'BITTER STRAAT',
    'BIVAK',
    'BIVAK A',
    'BLANQUILLA WEG',
    'BLAUW A',
    'BLAUW B',
    'BLAUW C',
    'BLAUW D',
    'BLAUW E',
    'BLAUW F',
    'BLAUW G',
    'BLAUW H',
    'BLAUW I',
    'BLAUW J',
    'BLAUW K',
    'BLAUW L',
    'BLAUW M',
    'BLAUW N',
    'BLAUWBAAI',
    'BLAUWDUIF WEG',
    'BLAUWE STEEG',
    'BLEINHEIM',
    'BLENCHI WEG',
    'BLIKSLAGERS WEG',
    'BLOEMFONTEIN',
    'BLOEMHOF',
    'BLOEMPOT',
    'BLOK STEEG',
    'BLOMONTE',
    'BLOMONTE WEG',
    'BLUE BAY',
    'BOCA SAMI O',
    'BOCA SAMI P',
    'BOCA TABLA',
    'BOCA WEG',
    'BOEBI WEG',
    'BOERHAAVE STRAAT',
    'BOERHAVENSTRAAT',
    "BOK'E SAMI",
    "BOK'I MANGEL",
    'BOKA ASCENCION',
    'BOKA BRAUN',
    'BOKA DJEGU',
    'BOKA GENTIL',
    'BOKA GRANDI',
    'BOKA GRANDI',
    'BOKA HULU',
    'BOKA KOKOLISHI',
    'BOKA KORTALEIN',
    'BOKA LABADERA',
    'BOKA MANSALINJA',
    'BOKA PATRICK',
    'BOKA PLATE',
    'BOKA POS SPANJO',
    'BOKA SAN PEDRO',
    'BOKA SANTA MARTHA',
    'BOKA SANTU PRETU',
    'BOKA SANTU PRETU NOORD',
    'BOKA SINT MARIE',
    'BOKA TABLA',
    'BOKA WANDOMI',
    'BOLDINGH STRAAT',
    'BOLIVIA WEG',
    'BOMBARDIERS WEG',
    'BONA VISTA',
    'BONABISTA',
    'BONACCA WEG',
    'BONAIRE STRAAT',
    'BONAM',
    'BONAM K',
    'BONAM KAYA A',
    'BONAM KAYA B',
    'BONAM KAYA C',
    'BONAM KAYA D',
    'BONAM KAYA D',
    'BONAM KAYA E',
    'BONAM KAYA F',
    'BONAM KAYA G',
    'BONAM WEG',
    'BONAM WEG STRAAT A',
    'BONGO WEG',
    'BONI STEEG',
    'BONITO STRAAT',
    'BOOT STEEG',
    'BORDET STRAAT',
    'BORINQUEN WEG',
    'BORNEO STEEG',
    'BOS WEG',
    'BOTER STEEG',
    'BOTOCUDOS WEG',
    'BOTTELIER',
    'BOU BARBER',
    'BOU BARBER AG',
    'BOUDEWIJN HENDRIK STEEG',
    'BOY ECURY WEG',
    'BRAGASDUSHI WEG',
    'BRAK STEEG',
    'BRAKKEPUT ABOU',
    'BRAKKEPUT ARIBA',
    'BRAKKEPUT ARIBA AB',
    'BRAKKEPUT ARIBA AC',
    'BRAKKEPUT ARIBA C',
    'BRAKKEPUT ARIBA D',
    'BRAKKEPUT ARIBA E',
    'BRAKKEPUT ARIBA F',
    'BRAKKEPUT ARIBA G',
    'BRAKKEPUT MEI MEI B',
    'BRAKKEPUT MEI MEI C',
    'BRAKKEPUT MEIMEI',
    'BRAMENDI',
    'BRAMENDI WEG',
    'BRAMENDI WEG STRAAT A',
    'BRAZIL WEG',
    'BREEDE STRAAT OTRABANDA',
    'BREEDE STRAAT PUNDA',
    'BREEDESTRAAT OTROBANDA',
    'BRIEM',
    'BRIEVENGAT',
    'Brievengat E',
    'BRIGADIERS WEG',
    'BRION PLEIN',
    'BRION WEG',
    'BRIONWERF',
    'BROKOPONDO STRAAT',
    'BRONS WEG',
    'BRUINE STEEG',
    'BRUSCA WEG',
    'BUENA VISTA',
    'BUENA VISTA (BANDA DI HOFI ABOU)',
    'BUENA VISTA (BANDA DI PATRICK)',
    'BUENA VISTA A',
    'BUENA VISTA B',
    'BUENA VISTA G',
    'BULADO STRAAT',
    'BULLEN BAAI',
    'BULLENBAAI',
    'BURUNDANGA',
    'BUURT STEEG',
    'CABIMAS WEG',
    'CABO VERDE',
    'CABO VERDE WEG',
    'CADUSHI STRAAT',
    'CADUSHI STRAAT KAYA A',
    'CADZAND STRAAT',
    'CAICOS WEG',
    'CAIQUETIOS WEG',
    'CALIFORNIE WEG',
    'CAMALIA WEG',
    'CAMPER STRAAT',
    'CANADA WEG',
    'CANASTER WEG',
    'CANASTERWEG KAYA  A',
    'CANASTERWEG KAYA  B',
    'CANASTERWEG KAYA &nbsp;A',
    'CANASTERWEG KAYA &nbsp;B',
    'CANOA WEG',
    'CANTZLAAR WEG',
    'CARACAS',
    'CARACAS BAAI',
    'CARACASBAAI WEG',
    'CARANJITOE STRAAT',
    'CARAWARA WEG',
    'CARIBEN PLEIN',
    'CARONI WEG',
    'CARRIACOU WEG',
    'CARTHAGENA STRAAT',
    'CAS ABOU A',
    'CAS ABOU B',
    'CAS ABOU C',
    'CAS ABOU D',
    'CAS ABOU E',
    'CAS CORA',
    'CAS CORA D',
    'CAS CORA E',
    'CAS CORA RESORT',
    'CAS CORA WEG',
    'CASHU WEG',
    'CASHUTUIN RESORT',
    'CASSANDRA WEG',
    'CASTOR WEG',
    'CASTRIES WEG',
    'CAUCA WEG',
    'CAUTINO STEEG',
    'CAVALERIE WEG',
    'CEDER STRAAT',
    'CELEBES STEEG',
    'CENTRALE MARKT',
    'CERES STRAAT',
    'CERITO',
    'CEYLON WEG',
    'CHANGO',
    'CHARLES DEBROT WEG',
    'CHARLOTTE STRAAT',
    'CHARO',
    'CHARO WEG',
    'CHAYA WILLEMS BOULEVARD OOST',
    'CHAYA WILLEMS BOULEVARD OOST',
    'CHAYA WILLEMS BOULEVARD WEST',
    'CHAYA WILLEMS BOULEVARD WEST',
    'CHER ASIL STRAAT',
    'CHER ASILE',
    'CHER ASILESTRAAT',
    'CHEROKEZEN WEG',
    'CHEYENNE STRAAT',
    'CHILE WEG',
    'CHIMICHIMI WEG',
    'CHOBOLOBO',
    'CHOGOGO RESORT',
    'CHOGOGO W',
    'CHOGOGO Y',
    'CHOGOGO Z',
    'CHOLOMA',
    'CHOLOMA C',
    'CHONCHOROGAI WEG',
    'CHRISTINA LAAN',
    'CHUCHUBI WEG',
    'CLIO WEG',
    'COCHENILLE STRAAT',
    'COCO STRAAT',
    'COCOLODE WEG',
    'COCORI',
    'COCORIE L',
    'COCORIE M',
    'COCORIE N',
    'COCORIE O',
    'COCOWIJK',
    'COLOMBIA WEG',
    'COLON',
    'COLON W',
    'COLONIA B',
    'COLONIA C',
    'COLONIA D',
    'COLORADO WEG',
    'COLUMBUS STRAAT',
    'COMANCHE STRAAT',
    'COMMANDEURS WEG',
    'COMMEWIJNE STRAAT',
    'CONCORDIA STRAAT',
    'CONGO WEG',
    'CONSCIENTIE STEEG',
    'CONSTANCIA',
    'COPPENAME STRAAT',
    'CORAL ESTATE',
    'CORANTIJN STRAAT',
    'CORCOBA STRAAT',
    'CORNELIS BAAI',
    'CORNELIS BAAI',
    'CORNELIS DIRKS WEG',
    'CORONET',
    'CORONET H',
    'CORONET I',
    'CORONET K',
    'CORONET WEG',
    'CORONIE STRAAT',
    'COROSOL WEG',
    'CORRI',
    'CORRI WEG',
    'COSTA RICA WEG',
    'COTTICA STRAAT',
    'COZUMEL WEG',
    'CREOLE WEG',
    'CROL STRAAT',
    'CUBA',
    'CUBA WEG',
    'CUBAGUA WEG',
    'CURACAO DOK MAATSCHAPPIJ',
    'CURACAO STRAAT',
    'CURASOL',
    'CYBELE STRAAT',
    'CYPRUS WEG',
    'DA COSTA GOMEZ PLEIN',
    'DAAIBOOI BAAI',
    'DADEL WEG',
    'DAIMARI WEG',
    'DAIMARI WEG KAYA C',
    'DAKOTA WEG',
    'DAL WEG',
    'DAM PRETU',
    'DAM STEEG',
    'DAM WEG',
    'DAMAKOR',
    'DANIEL',
    'DANZA STRAAT',
    'DAPHNE WEG',
    'DARIO SALAS STRAAT',
    'DE BIESHEUVEL',
    'DE HOOP BIJ HEINTJE KOOL',
    'DE HOOP BIJ STA ROSA',
    'DE JONGH STRAAT',
    'DE KLUNDERT',
    'DE OJEDA STRAAT',
    'DE ROUVILLE WEG',
    'DE RUYTERKADE',
    'DE SAVAAN',
    'DE SAVAAN KAYA A',
    'DE SAVAAN KAYA B',
    'DE SAVAAN KAYA C',
    'DE SAVAAN KAYA D',
    'DE SAVAAN U',
    'DE SAVAAN W',
    'DE SAVAAN X',
    'DE SAVAAN Y',
    'DEIN',
    'DEIN A',
    'DEIN B',
    'DEIN C',
    'DEIN D',
    'DEIN E',
    'DEIN F',
    'DEIN G',
    'DEIN H',
    'DEIN I',
    'DEIN J',
    'DEIN K',
    'DEIN KAYA A',
    'DEIN KAYA B',
    'DEIN KAYA C',
    'DEIN KAYA D',
    'DEIN KAYA E',
    'DEIN KAYA F',
    'DEIN KAYA G',
    'DEIN KAYA H',
    'DEIN KAYA J',
    'DEIN KAYA M',
    'DEIN KAYA N',
    'DEIN KAYA O',
    'DEIN KAYA P',
    'DEIN KAYA Q',
    'DEIN KAYA R',
    'DEIN L',
    'DEIN M',
    'DEIN N',
    'DEIN O',
    'DEIN P',
    'DEIN Q',
    'DEIN R',
    'DEIN S',
    'DEIN T',
    'DEIN U',
    'DEIN V',
    'DEIN W',
    'DEIN X',
    'DEIN Y',
    'DELTA STRAAT',
    'DEMERARA WEG',
    'DEMETER WEG',
    'DEMPEL',
    'DEMPEL STRAAT',
    'DEN COCO',
    'DEN HAAG STRAAT',
    'DEN HELDER STRAAT',
    'DEN HULANDA',
    'DEN KOBA',
    'DESIRADE WEG',
    'DEVENTER STRAAT',
    'DIAMANT WEG',
    'DIANA STRAAT',
    'DIDO WEG',
    'DIDOWEG KAYA A',
    'DINORCHI',
    'DINTEL STRAAT',
    'DIRK OTTERINCK STRAAT',
    'DIVIDIVI WEG',
    'DJADO STRAAT',
    'DJONORA MARTHA',
    'DJONORAMARTHA WEG',
    'DOK WEG',
    'DOKTERSTUIN',
    'DOKTERSTUIN E',
    'DOKTERSTUIN G',
    'DOKTERSTUIN L',
    'DOKTERSTUIN T',
    'DOKTERSTUIN V',
    'DOLFIJN STRAAT',
    'DOMI',
    'DOMI ABOU C',
    'DOMINGUITO',
    'DOMINGUITO WEG',
    'DOMINICA WEG',
    'DOMMEL STRAAT',
    'DONCKER STRAAT',
    'DONDER STRAAT',
    'DONGE STRAAT',
    'DORADO STRAAT',
    'DORNASOL WEG',
    'DOS BOKA',
    'DR CAPRILES WEG',
    'DR EVERTSZ WEG',
    'DR HENRI FERGUSON WEG',
    'DR J. EYCKMAN WEG',
    'DR JACQUES ELLIS WEG',
    'DR JONCKHEER STRAAT',
    'DR K ANSLIJN STRAAT',
    'DR LENS WEG',
    'DR M J HUGENHOLTZ WEG',
    'DR M L KING BOULEVARD',
    'DR WILLEM P MAAL WEG',
    'DR. CAPRILESWEG',
    'DR. EVERTSZWEG',
    'DR. FERGOSONWEG',
    'DR. J. EYCKMANWEG',
    'DR. JACQUES ELLISWEG',
    'DR. JONCKHEERSTRAAT',
    'DR. K. ANSLIJNSTRAAT',
    'DR. LENSWEG',
    'DR. M.J. HUGENHOLTZWEG',
    'DR. WILLEM P. MAALWEG',
    'DRAAI WEG',
    'DRIEL STRAAT',
    'DRUIF WEG',
    'DRUKKERIJ STRAAT',
    'DUINKERKEN',
    'DUIVELSKLIP',
    'DULCE ESPERANSA',
    'DUSU',
    'DUSUPE',
    'DWARS WEG',
    'DWERG STEEG',
    'E.E.G.WEG',
    'ECUADOR WEG',
    'EDISON STRAAT',
    'EEG WEG',
    'EEM STRAAT',
    'EEND WEG',
    'EENDRACHT',
    'EENDRACHT WEG',
    'EENHOORN STRAAT',
    'EIFEL WEG',
    'EINDHOVEN STRAAT',
    'EKAKS WEG',
    'EL GRECO WEG',
    'EL SALVADOR WEG',
    'ELEUTHERA WEG',
    'ELIAS MORENO BOULEVARD',
    'ELIAS R.A. MORENO BULEVAR',
    'ELLEBOOG STRAAT',
    'ELMES STRAAT',
    'ELMINA WEG',
    'ELS STRAAT',
    'ELSTSTRAAT',
    'EMANCIPATIE BOULEVARD',
    'EMMA BRUG',
    'EMMA LAAN',
    'EMMA STRAAT',
    'EMMASTAD',
    'ENOCH',
    'ERIDANUSWEG',
    'ERIE WEG',
    'EROS WEG',
    'ESKIMO WEG',
    'ESMERALDA WEG',
    'ESPERANZA BIJ MINGELITU',
    'ESPERANZA BIJ QUINTA VIOLETA',
    'ESPERANZA BIJ RONDE KLIP',
    'ESPERANZA BIJ SALSBACH',
    'ESPERANZA BIJ SAPATE',
    'ESPERANZA BIJ WATERLOO',
    'ESPERANZA BIJ WATERLOO A',
    'ESPERANZA BIJ WATERLOO B',
    'ESPERANZA BIJ WATERLOO C',
    'ESPERANZA BIJ WATERLOO D',
    'ESPERANZA BIJ WATERLOO E',
    'ESPERANZA BIJ WATERLOO F',
    'ESPERANZA BIJ WATERLOO G',
    'ESPERANZA BIJ WATERLOO H',
    'ESPERANZA WEG',
    'ESSER STRAAT',
    'ESSO WEG',
    'EXUMA WEG',
    'FABI',
    'FARADAY WEG',
    'FARADAYSTRAAT',
    'FATA¥A',
    'FATIMA WEG',
    'FELIS CHAKUTOE PLEIN',
    'FENSOHN STRAAT',
    'FERDINAND STRAAT',
    'FICUS WEG',
    'FIGOE WEG',
    'FIGUWEG',
    'FLAIRA WEG',
    'FLAMBOYANWEG',
    'FLAMINGO WEG',
    'FLEERCHI STRAAT',
    'FLEUR DE MARIE',
    'FLIP',
    'FLIP A',
    'FLIP B',
    'FLIP C',
    'FLIP D',
    'FLIP E',
    'FLIP F',
    'FLIP H',
    'FLIP I',
    'FLIP K',
    'FLORENCE STRAAT',
    'FLORIDA (BANDABOU)',
    'FLORIDA (BANDARIBA)',
    'FLORIDA WEG',
    'FLUIT STEEG',
    'FOKKER WEG',
    'FONTEIN',
    'FONTEIN VILLA PARK A',
    'FONTEIN VILLA PARK B',
    'FONTEIN VILLA PARK C',
    'FONTEIN VILLA PARK D',
    'FONTEIN VILLA PARK E',
    'FONTEIN VILLA PARK F',
    'FONTEIN VILLA PARK G',
    'FONTEIN VILLA PARK H',
    'FONTEIN VILLA PARK I',
    'FONTEIN VILLA PARK J',
    'FONTEIN VILLA PARK K',
    'FONTEIN VILLA PARK L',
    'FONTEIN VILLA PARK M',
    'FONTEIN VILLA PARK N',
    'FONTEIN VILLA PARK O',
    'FONTEIN VILLA PARK P',
    'FONTEIN VILLA PARK Q',
    'FONTEIN VILLA PARK R',
    'FONTEIN VILLA PARK S',
    'FONTEIN VILLA PARK T',
    'FONTEIN VILLA PARK U',
    'FORT AMSTERDAM',
    'FORT EENNACHT WEG',
    'FORT NASSAU WEG',
    'FORT WAAKZAAMHEID STRAAT',
    'FORTUNA ABOU',
    'FORTUNA ABOU A',
    'FORTUNA ABOU B',
    'FORTUNA ABOU L',
    'FORTUNA ARIBA',
    'FOSFAAT WEG',
    'FRANCIA',
    'FRANCIA B',
    'FRANCIA C',
    'FRANK STEEG',
    'FRANKLIN DELANO ROOSEVELT WEG',
    'FRANSCHE BLOEM WEG',
    'FRATER CANDIDUS STRAAT',
    'FRATER RADULPHUS WEG',
    'FRATER RICHARDUS WEG',
    'FREDERIK HENDRIK LAAN',
    'FREDERIK STRAAT',
    'FUIK',
    'FUIK BAAI',
    'FUIK STRAAT',
    'FUSELIERS WEG',
    'GAITO WEG',
    'GAITU',
    'GALLERO STEEG',
    'GALVANI STRAAT',
    'GARIPITO WEG',
    'GASPARITU',
    'GASTHUIS STRAAT',
    'GATO A',
    'GATO B',
    'GATO C',
    'GATO D',
    'GATO E',
    'GATO F',
    'GATO G',
    'GATO H',
    'GATO I',
    'GATO J',
    'GATO K',
    'GATO L',
    'GATO M',
    'GATO N',
    'GATO O',
    'GATO P',
    'GATO Q',
    'GATU',
    'GELE STEEG',
    'GELEEN STRAAT',
    'GENERAALS WEG',
    'GENIE WEG',
    'GEORGE MADURO WEG',
    'GEORGIA WEG',
    'GEPIE STRAAT',
    'GEUL STRAAT',
    'GIBRALTAR',
    'GIRBALTAR G',
    'GIROUETTE',
    'GIROUETTE A',
    'GIROUETTE B',
    'GIROUETTE C',
    'GIROUETTE D',
    'GLADIOLEN WEG',
    'GOEDE HOOP BIJ SELINDA',
    "GOEDE HOOP BIJ SER'I HULANDA",
    'GOEROEBOEROE WEG',
    'GOETOE WEG',
    'GONAVE WEG',
    'GORA',
    'GORA A',
    'GORA B',
    'GORA C',
    'GORA O',
    'GOSIE',
    'GOSIE WEG',
    'GOUDSMID WEG',
    'GOUVENEUR VAN LANSBERGE WEG',
    'GOUVENEURSPLEIN',
    'GOUVERNEMENTS PLEIN',
    'GOVERNEUR VAN SLOBBE WEG',
    'GRAHAM BELL STRAAT',
    'GRANAATAPPEL WEG',
    'GRANBEEUW WEG',
    'GRANBEUW',
    'GRAVEN STRAAT',
    'GREBBELINIE WEG',
    'GRENADA WEG',
    'GRENADIERS WEG',
    'GRENEN WEG',
    'GRIMOU',
    'GRINOLI',
    'GRINOLI P',
    'GRINOLI Q',
    'GRINOLI R',
    'GRINOLI S',
    'GROENE STEEG',
    'GROOT DAVELAAR',
    'GROOT DAVELAAR A',
    'GROOT DAVELAAR B',
    'GROOT DAVELAAR WEG',
    'GROOT KWARTIER',
    'GROOT KWARTIER WEG',
    'GROOT KWARTIER ZUID A',
    'GROOT PISCADERA',
    'GROOT PISCADERA B',
    'GROOT PISCADERA C',
    'GROOT SANTA MARTHA A',
    'GROOT SANTA MARTHA B',
    'GROOT SANTA MARTHA C',
    'GROOT SANTA MARTHA D',
    'GROOT SINT JORIS',
    'GROOT SINT JORIS WEG',
    'GROOT ST MICHIEL',
    'GROOT ST. JORIS B',
    'GROOT STA MARTHA',
    'GROOTHOORN STRAAT',
    'GROTE BERG',
    'GROTE BERG Y',
    'GUACAIPURO WEG',
    'GUADELOUPE WEG',
    'GUAJIRA WEG',
    'GUANA WEG',
    'GUARANIE WEG',
    'GUATEMALA WEG',
    'GUIAMBO WEG',
    'GUIAMBO WEG',
    'GUYABA WEG',
    'HABAAI',
    'HABAAI WEG',
    'HAITI WEG',
    'HAMBER',
    'HAMELBERG J.H.J. WEG',
    'HANCHI KAS CHIKITU',
    'HANCHI POS DI MARIA',
    'HANCHI SNOA',
    'HANCHI VI COCO',
    'HANCHI BAKA',
    'HANCHI BANJO',
    'HANCHI BIENTU',
    'HANCHI KANGA',
    'HANCHI MORDENAR',
    'HANCHI PAREDA',
    'HANDELSKADE',
    'HANENBERG',
    'HANENBERG A',
    'HANENBERG VILLAS',
    'HANSJE',
    'HARMONIE (GROTE BERG)',
    'HARMONIE BANDA ARIBA',
    'HARZ WEG',
    'HATO',
    'HATO A',
    'HAVEN STRAAT',
    'HECUBA WEG',
    'HEELSUM STRAAT',
    'HEEMSKERK LAAN',
    'HEEREN STRAAT',
    'HEERLEN STRAAT',
    'HEGIRA STRAAT',
    'HEINTJE KOOL',
    'HEINTJE KOOL B',
    'HEINTJE KOOL WEG',
    'HEL',
    'HELENA STRAAT',
    'HELFRICHDORP',
    'HELICON STRAAT',
    'HELMIN MAGNO WIELS BOULEVARD',
    'HENDRIK LAAN',
    'HENDRIK PLEIN',
    'HENDRIK STEEG',
    'HERA WEG',
    'HERCULES WEG',
    'HERMANUS',
    'HERMES WEG',
    'HERST',
    'HERST B',
    'HERST C',
    'HESTIA WEG',
    'HEUVEL WEG',
    'HIMALAJA WEG',
    'HIMALAYAWEG',
    'HISPANOLA WEG',
    'HISPAÑOLAWEG',
    'HOBA WEG',
    'HOEK STRAAT',
    'HOEK VAN HOLLAND STRAAT',
    'HOENDERBERG',
    'HOENDERBERG N',
    'HOF WEG',
    'HOFI ABOU',
    'HOFI ABOU AF',
    'HONDURAS WEG',
    'HOOG STRAAT',
    'HUDSON WEG',
    'HURONS WEG',
    'HURONWEG',
    'HUZAREN WEG',
    'HYDRA WEG',
    'HYDRA WEG KAYA A',
    'IBIS WEG',
    'IDA WEG',
    'IJMUIDEN STRAAT',
    'IJSSELMEER STRAAT',
    'IJSSELSTRAAT',
    'IJZER STRAAT',
    'ILAND',
    'ILAND WEG',
    'INAGUA WEG',
    'INCA STRAAT',
    'INCA STRAAT',
    'INDIANA WEG',
    'INDIGO STRAAT',
    'INDJU WEG',
    'INDUSTRIE PARK BRIEVENGAT',
    'INDUSTRIE PARK SERU LORA WEG',
    'INFANTERISTEN WEG',
    'IRENE LAAN',
    'ISLA WEG',
    'J.H.J. HAMELBERGWEG',
    'JACOB BECK STRAAT',
    'JACOB BINCKE STRAAT',
    'JALOUSI',
    'JAMAICA WEG',
    'JAN ABEL',
    'JAN BAARDA KADE',
    'JAN BOOS',
    'JAN DONKER',
    'JAN DONKER B',
    'JAN DORET',
    'JAN ERASMUS STRAAT',
    'JAN H FERGUSON STRAAT',
    'JAN HAAYEN WEG',
    'JAN KOK',
    'JAN KOK B',
    'JAN KOK C',
    'JAN KOKO A',
    'JAN LUIS A',
    'JAN LUIS G',
    'JAN LUIS H',
    'JAN LUIS I',
    'JAN LUIS J',
    'JAN LUIS K',
    'JAN LUIS L',
    'JAN LUIS M',
    'JAN LUIS N',
    'JAN NOORDUYN WEG',
    'JAN OTZEN STRAAT',
    'JAN SOFAT',
    'JAN SOFAT H',
    'JAN SOFAT I',
    'JAN SOFAT J',
    'JAN SOFAT K',
    'JAN SOFAT L',
    'JAN SOFAT M',
    'JAN SOFAT N',
    'JAN SOFAT O',
    'JAN SOFAT P',
    'JAN SOFAT Q',
    'JAN STEEG',
    'JAN THIEL',
    'JAN THIEL A',
    'JAN THIEL OOST',
    'JAN VAN CAMPEN STRAAT',
    'JAN VAN ERPECUM STRAAT',
    'JAN VAN NASSAU LAAN',
    'JANCHI WEVER',
    'JANCHI WEVER O',
    'JANDORET Q',
    'JANDORET R',
    'JANDORET S',
    'JANDORET T',
    'JANTHIEL BAAI',
    'JANTJE WEVER',
    'JANWE',
    'JANWE STRAAT',
    'JASMIJN WEG',
    'JAVA STRAAT',
    'JAYA GUAMARES',
    'JEAN RODIER STRAAT',
    'JENEFU',
    'JEREMI',
    'JIRAJARA WEG',
    'JIVAROS WEG',
    'JO CORSEN STRAAT',
    'JOHAN DE LAET STRAAT',
    'JOHAN VAN WALBEECK PLEIN',
    'JOHN B. GORSIRA WEG',
    'JOHN DE POOL STRAAT',
    'JOHN F. KENNEDY BOULEVARD',
    'JOJO CORREA PLEIN',
    'JONCHI',
    'JONGBLOED',
    'JONGBLOED G',
    'JONGBLOED H',
    'JONGBLOED I',
    'JONGBLOED J',
    'JONGBLOED WEG',
    'JONGBLOEDWEG AA',
    'JONIS BERG',
    'JUAN BAEZA',
    'JUAN BEAZA P',
    'JUAN BEAZA R',
    'JUAN BEAZA S',
    'JUAN BEAZA X',
    'JUAN DOMINGO BIJ MAHUMA A',
    'JUAN DOMINGO BIJ MAHUMA B',
    'JUAN DOMINGO BIJ MAHUMA C',
    'JUAN DOMINGO BIJ MAHUMA D',
    'JUAN DOMINGO BIJ MAHUMA E',
    'JUAN DOMINGO BIJ MAHUMA F',
    'JUAN DOMINGO BIJ MAHUMA G',
    'JUAN DOMINGO BIJ MAHUMA H',
    'JUAN DOMINGO BIJ MAHUMA I',
    'JUAN DOMINGO BIJ MAHUMA J',
    'JUAN DOMINGO BIJ MAHUMA K',
    'JUAN DOMINGO BIJ MAHUMA L',
    'JUAN DOMINGO BIJ MAHUMA M',
    'JUAN DOMINGO BIJ MAHUMA N',
    'JUAN DOMINGO BIJ MAHUMA O',
    'JUAN DOMINGO BIJ MAHUMA P',
    'JUAN DOMINGO BIJ MAHUMA Q',
    'JUAN DOMINGO BIJ MAHUMA R',
    'JUAN DOMINGO BIJ MAHUMA T',
    'JUAN DOMINGO BIJ MAHUMA U',
    'JUAN DOMINGO BIJ MAHUMA X',
    'JUAN DOMINGO BIJ OTRABANDA',
    'JUAN DOMINGU BIJ MAHUMA',
    'JUAN HATO',
    'JUAN HATO WEG',
    'JUAN LUIS',
    'JUAN LUIS M',
    'JUANA MATEOWEG',
    'JUANA MATHEO WEG',
    'JUANOTA',
    'JUANOTAWEG',
    'JULIAN',
    'JULIANA BRUG',
    'JULIANA DORP',
    'JULIANA LAAN',
    'JULIANA PLEIN',
    'JULIANA VAN STOLBERG LAAN',
    'JULIANADORP F',
    'JUMENTO WEG',
    'JUNO STRAAT',
    'JUPITER STRAAT',
    'JURA WEG',
    'JUWELIERS WEG',
    'KABAYE',
    'KADDO WEG',
    'KADUSHI STRAAT',
    'KALABARI',
    'KALBAS WEG',
    'KALK STEEG',
    'KAMINDA AGUILA',
    'KAMINDA AKUAMARIN',
    'KAMINDA ALUMINIO',
    'KAMINDA AMATISTA',
    'KAMINDA ANDRE J E KUSTERS',
    'KAMINDA ANDRE J.E. KUSTERS',
    'KAMINDA BRIEVENGAT',
    'KAMINDA BROEDERNAN DI BRAKAPOTI',
    'KAMINDA CAS GRANDI',
    'KAMINDA CHANO MARGARETHA',
    'KAMINDA CHANO MARGARETHA',
    'KAMINDA DAFLA GRANDI',
    'KAMINDA DJOS ANTHONIA',
    'KAMINDA DUVERT "BOY" NICOLINA',
    'KAMINDA ELEKTRISHEN',
    'KAMINDA FRIDO',
    'KAMINDA GEORGE WILLEMS',
    'KAMINDA GEORGE WILLEMS',
    'KAMINDA GIRIGORIO A CURIE',
    'KAMINDA GIRIGORIO A. "NAI" CURIE',
    'KAMINDA GIROGORIO A. "NAI" CURIE',
    'KAMINDA HENSLEY F.A. "BAM BAM" MEULENS',
    'KAMINDA HIDASTA',
    'KAMINDA HOFI ABOU',
    'KAMINDA HOSE P. LIBERIA',
    'KAMINDA ITALIA',
    'KAMINDA JOHAN M. STATIUS VAN EPS',
    'KAMINDA JOSE "PAPA" LIBERIA',
    'KAMINDA JOSE C. ANTHONIA',
    'KAMINDA KALALU',
    'KAMINDA KALALÚ',
    'KAMINDA LAURENTIUS M. MATHILDA',
    'KAMINDA LAURENTIUS M. MATHILDA "LOU DI DESUPE"',
    'KAMINDA LORA',
    'KAMINDA MGR DR AMADO ROMER',
    'KAMINDA MGR. AMADO ROMER',
    'KAMINDA MITOLOGIA',
    'KAMINDA MONICA KAPEL-MATHEEUW',
    'KAMINDA NACHO POLIS',
    'KAMINDA NIKEL',
    'KAMINDA ONIKS',
    'KAMINDA OPAL',
    'KAMINDA PA FUIK',
    'KAMINDA PALOMBA',
    'KAMINDA PAPA LIBERIA',
    'KAMINDA PARA KARPINTE',
    'KAMINDA PARA KARPINTÉ',
    'KAMINDA PROSPERO (POPO)  G. ROJER',
    'KAMINDA PROSPERO (POPO) &nbsp;G. ROJER',
    'KAMINDA RIGNALD S. "DOBLE R" RECORDINO',
    'KAMINDA ROMA',
    'KAMINDA ROOI SANTU',
    'KAMINDA SALIÑA',
    'KAMINDA SALINJA',
    'KAMINDA SAN SEBASTIAN',
    'KAMINDA SAN WILLIBRORDUS',
    'KAMINDA SANTA BRIGITTA',
    'KAMINDA SERAPIO A. PINEDO',
    'KAMINDA SERU WATERLOO',
    'KAMINDA SHON WE CRATSZ',
    'KAMINDA TAKI',
    'KAMINDA TURKUAS',
    'KAMINDA VENANCIO F.ISENIA',
    'KAMINDA VIKTO BARTHOLOMEUS',
    'KAMINDA WILLEMS J. CRATSZ',
    'KAMINDA WINFRIDO HERMANS',
    'KAMINDA YAKIMA',
    'KANARIE WEG',
    'KANGA',
    'KANGA A',
    'KANGA B',
    'KANGA C',
    'KANGA D',
    'KANGA E',
    'KANGA F',
    'KANGA I',
    'KANGA J',
    'KANGA K',
    'KANGA L',
    'KANGA M',
    'KANGA N',
    'KANGA O',
    'KANGA P',
    'KANGA Q',
    'KANGA R',
    'KANGA S',
    'KANGA W',
    'KANGA Y',
    'KANONNIERS WEG',
    'KANSAS WEG',
    'KAPITEINS WEG',
    'KARPATA',
    'KARPATEN WEG',
    'KAS ABOU',
    'KAS CHIKITU',
    'KAS CHIKITU M',
    'KAS GRANDI',
    'KASHU TUIN',
    'KASHUTUIN AA',
    'KASHUTUIN AB',
    'KASHUTUIN RESORT',
    'KASTELITA',
    'KATOENTUIN',
    'KATUNA STRAAT',
    'KAUKASUS WEG',
    'KAYA A.M. [SHON TOMI] PRINCE',
    'KAYA ABEHA',
    'KAYA ABNACI',
    'KAYA ABRAHAMSZ',
    'KAYA ABRAM EDGARDO SALAS',
    'KAYA ABUNDANSIA',
    'KAYA ACACIA',
    'KAYA ACERAS',
    'KAYA ACHABUS',
    'KAYA ACHILLES',
    'KAYA ADAGIO',
    'KAYA ADAMAWA',
    'KAYA ADELIZA F. BASILIO CIJNTJE',
    'KAYA ADELIZA F. BASILIO-CIJNTJE "Lita Resadó',
    'KAYA ADONAI',
    'KAYA ADRIANUS KOOYMAN',
    'KAYA ADRIÁTIKO',
    'KAYA AEGEUS',
    'KAYA AEGIS',
    'KAYA AESCULAPIUS',
    'KAYA AFGHANIS',
    'KAYA AFIDO',
    'KAYA AFRIKAANTJE',
    'KAYA AGAN',
    'KAYA AGRIKULTURA',
    'KAYA AIRU',
    'KAYA AKARO',
    'KAYA AKOERIO',
    'KAYA AKORDEON',
    'KAYA ALABASTER',
    'KAYA ALATA',
    'KAYA ALDERLIESTE',
    'KAYA ALEGRO',
    'KAYA ALEJANDRO "YANDI" COFFIL',
    'KAYA ALEX SINTIAGO',
    'KAYA ALEXANDRINA R. ALBERTUS',
    'KAYA ALEXANDRINA R. ALBERTUS "Djina"',
    'KAYA ALGECIRAS',
    'KAYA ALIANSA',
    'KAYA ALKAPARA',
    'KAYA ALLEGRO',
    'KAYA ALMIRANTE PEDRO LUIS BRION',
    'KAYA ALONSO DE OJEDA',
    'KAYA ALTOSTRATUS',
    'KAYA ALVARIHNO',
    'KAYA AMADOR NITA',
    'KAYA AMBISHON',
    'KAYA AMERICO VESPUCCI',
    'KAYA AMISTAT',
    'KAYA AMOR PLATONIKO',
    'KAYA AMOS',
    'KAYA AMPLIFAYER',
    'KAYA AMSTERDAM',
    'KAYA ANAHUAC',
    'KAYA ANAMÚ',
    'KAYA ANASITA',
    'KAYA ANASTACIA A.MARTINA',
    'KAYA ANDANTE',
    'KAYA ANDEIFI',
    'KAYA ANDESIT',
    'KAYA ANDIRA',
    'KAYA ANDRES CARLINO SNIJDERS',
    'KAYA ANDRUW JONES',
    'KAYA ANDRUW R.B. JONES',
    'KAYA ANEISAK',
    'KAYA ANEISAK',
    'KAYA ANGEL',
    'KAYA ANGEL F. ALEXANDER',
    'KAYA ANGEL J LEANEZ',
    'KAYA ANGEL J. LEAÑEZ',
    'KAYA ANGELA M. JULIANA-MARIA',
    'KAYA ANGÉLIKA',
    'KAYA ANGUA DI REDA',
    'KAYA ANHELA KO-FUNDADO SPORTTEAM DOMINO UNITAS',
    'KAYA ANKER',
    'KAYA ANNA',
    'KAYA ANSELLIA',
    'KAYA ANSELMUS T. "BOY" DAP',
    'KAYA ANTENE',
    'KAYA ANTÈNE',
    'KAYA ANTIGONE',
    'KAYA ANTOINE MADURO',
    'KAYA ANTOINE PIJPERS',
    'KAYA ANTONIA F.M. NEWTON-CORTHALS',
    'KAYA ANTONILIA M. RUITER PHELIPA',
    'KAYA ANTONILIA M. RUITER-PHILIPA "Yùfrou Núnú"',
    'KAYA ANTONIO A. MELFOR',
    'KAYA ANTONIO GUZMAN BLANCO',
    'KAYA ANTONIO PLANTIJN',
    'KAYA ANTONIUS SCHIPPER',
    'KAYA ANTONIUS TH. R. SCHIPPER',
    'KAYA ANTRE',
    'KAYA ANUBIS',
    'KAYA APACHE',
    'KAYA APHRODITE',
    'KAYA APTERA',
    'KAYA ARA¥A',
    'KAYA ÁRABE',
    'KAYA ARAGUA',
    'KAYA ARAÑA',
    'KAYA ARARUT',
    'KAYA ARCADIA',
    'KAYA ARCILLA',
    'KAYA AREMBOS',
    'KAYA ARES',
    'KAYA ARIADNE',
    'KAYA ARIADNE',
    'KAYA ÁRNIKA',
    'KAYA ARON',
    'KAYA ÁRTIKO',
    'KAYA ASHTORETH',
    'KAYA ASUNCION',
    'KAYA ATALANTA',
    'KAYA ATHENA',
    'KAYA ATROPOS',
    'KAYA AUGUST CH QUAST',
    'KAYA AUGUSTO BOELIJN',
    'KAYA AUGUSTO J. FRAAI',
    'KAYA AURA C. THODÉ - MARTIS',
    'KAYA AURA C. THODE-MARTIS',
    'KAYA AURA E. WINKEL',
    'KAYA AURA EVELINE WINKEL',
    'KAYA AURORA',
    'KAYA AVENTURIN',
    'KAYA AVERSA',
    'KAYA AWASERU',
    'KAYA AZOV',
    'KAYA AZURIT',
    'KAYA BACCHUS',
    'KAYA BACH',
    'KAYA BACTRIS',
    'KAYA BAGAS',
    'KAYA BAIDOR',
    'KAYA BAILARIN',
    'KAYA BAKAT•R',
    'KAYA BAKATÒR',
    "KAYA BALA 'I ORO",
    'KAYA BALA`I ORO',
    'KAYA BALASTER',
    'KAYA BALBOA',
    'KAYA BALERIAN',
    'KAYA BALINJA',
    'KAYA BALOFON',
    'KAYA BÁLTIKO',
    'KAYA BAMBOEPALM',
    'KAYA BANANA DI REF',
    'KAYA BANDERA',
    'KAYA BANDERITA',
    'KAYA BANGANJA',
    'KAYA BAÑO',
    'KAYA BARA DI HAK',
    'KAYA BARACHIAS',
    'KAYA BARACHIEL',
    'KAYA BARBA DI PALU',
    'KAYA BARBERA',
    'KAYA BARBULETE',
    'KAYA BARI',
    'KAYA BARINAS',
    'KAYA BARKA',
    'KAYA BASALT',
    'KAYA BASIL',
    'KAYA BASRALOKUS',
    'KAYA BASTÈL',
    'KAYA BASTIMENTOS',
    'KAYA BATIDO DI TOBO',
    'KAYA BAUL',
    'KAYA BAYESTRIM',
    'KAYA BAYETE',
    'KAYA BAZJAN KARPATA',
    'KAYA BEATRIX J. "BEA" MUZO-FRANSISCO',
    'KAYA BEETHOVEN',
    'KAYA BELA DI PASKU',
    'KAYA BELENOS',
    'KAYA BELIZE',
    'KAYA BENCHA',
    'KAYA BENTAHA',
    'KAYA BERBENA',
    'KAYA BERGAMO',
    'KAYA BERILO',
    'KAYA BERNADINA M. HANSEN CLEMENTINA',
    'KAYA BERNARDINA M. HANSEN-CLEMENTINA "Chichi Bèlè"',
    'KAYA BÉRO',
    'KAYA BEROM',
    'KAYA BET¢NIKA',
    'KAYA BETONIKA',
    'KAYA BETÓNIKA',
    'KAYA BEU',
    'KAYA BICHI KANDELA',
    'KAYA BIENESTAR',
    'KAYA BIESHEUVEL',
    'KAYA BIGONIA',
    'KAYA BILEAN',
    'KAYA BILOXI',
    'KAYA BINIBINI',
    'KAYA BINIBINI',
    'KAYA BIPER',
    'KAYA BISKANIA',
    'KAYA BISMARK',
    'KAYA BLOUSTENCHI',
    'KAYA BODOGO',
    'KAYA BOGOTA',
    'KAYA BOKO',
    'KAYA BOKSIT',
    'KAYA BOLIVAR',
    'KAYA BOLO PRETU',
    'KAYA BOLOGNA',
    'KAYA BONIFASIO',
    'KAYA BOR',
    'KAYA BOREAS',
    'KAYA BOREAS',
    'KAYA BOSPORUS',
    'KAYA BOYO',
    'KAYA BRASIA',
    'KAYA BRASSAVOLA',
    'KAYA BRAYE',
    'KAYA BRIANTE',
    'KAYA BRIGIDA',
    'KAYA BRIGIT',
    'KAYA BRISA',
    'KAYA BROMELIA',
    'KAYA BRONSIT',
    'KAYA BRÒNSIT',
    'KAYA BRUDERNAN DI DONGEN',
    'KAYA BUENA VISTA',
    'KAYA BUENOS AIRES',
    'KAYA BUKI',
    'KAYA BUQSHA',
    'KAYA BURUNDANGA',
    'KAYA BUTUELA',
    'KAYA C WINKEL G',
    'KAYA C. WINKEL',
    'KAYA CABERNET',
    'KAYA CACIQUE DE MANAURE',
    'KAYA CACIQUE MANAURE',
    'KAYA CAJUN',
    'KAYA CALDERANA',
    'KAYA CALIZA',
    'KAYA CALLIOPE',
    'KAYA CARACAS',
    'KAYA CARILLON',
    'KAYA CARIÑENA',
    'KAYA CARL KRIJT',
    'KAYA CARMEN A. IGNACIO VICTORINA',
    'KAYA CARMEN A. IGNACIO-VICTORINA "Shon Leen"Frúmú"',
    'KAYA CARTAGO',
    'KAYA CASTELLATUS',
    'KAYA CASUALS',
    'KAYA CATANIA',
    'KAYA CATTLEYA',
    'KAYA CAYENNE',
    'KAYA CEDERELA',
    'KAYA CEDIS',
    'KAYA CHAGOS BANK',
    'KAYA CHANGA',
    'KAYA CHANGO',
    'KAYA CHAPAPOTI',
    'KAYA CHAPI',
    'KAYA CHARLES MADURO',
    'KAYA CHARON',
    'KAYA CHENIN BLANC',
    'KAYA CHETURMS',
    'KAYA CHI KU CHA',
    'KAYA CHI KU CHÁ',
    'KAYA CHI KU CHÁ',
    'KAYA CHI KU CHA KAYA A',
    'KAYA CHI NEMA-KOSEDO',
    'KAYA CHIKOTI',
    'KAYA CHILIN',
    'KAYA CHIMASAIRA',
    'KAYA CHIPI CHIPI',
    'KAYA CHONCHI SINT JAGO',
    'KAYA CHOROMI',
    'KAYA CIANITA',
    'KAYA CINSAULT',
    'KAYA CINTIA',
    'KAYA CIRRUS',
    'KAYA CLAUDOMIRA M. "CLOVIS" LODEWIJKS',
    'KAYA CLOVIS A. BAZUR',
    'KAYA COLETA V. OSTIANA',
    'KAYA COLON',
    'KAYA CONSOLEA',
    'KAYA CONSUS',
    'KAYA CORDOBA',
    'KAYA CÓRDOBA',
    'KAYA CORNELIS J. "CORNES" FELICIA',
    'KAYA CORONA',
    'KAYA COSENZA',
    'KAYA CRISTOBAL COLON',
    'KAYA CRUZEIRO',
    'KAYA CUIVA',
    'KAYA CUMULUS',
    'KAYA CUPIDO',
    'KAYA CURACAOSCHE COURANT',
    'KAYA DADELPALM',
    'KAYA DAEDALUS',
    'KAYA DAGDA',
    'KAYA DAKUE',
    'KAYA DALAKOCHI',
    'KAYA DALDER',
    'KAYA DALPEGA',
    'KAYA DAMASCO',
    'KAYA DAMASIUS HOYER',
    'KAYA DAMPANCHI',
    'KAYA DANIEL',
    'KAYA DANILO I. "DENNY" PLANTIJN',
    'KAYA DARDANELLA',
    'KAYA DARG',
    'KAYA DATU',
    'KAYA DAVID',
    'KAYA DE SAVAAN',
    'KAYA DEBORAH',
    'KAYA DEKAN',
    'KAYA DELPHI',
    'KAYA DENIS E. TROEMAN',
    'KAYA DENIS TROEMAN',
    'KAYA DHARMAPALA',
    'KAYA DIABAAS',
    'KAYA DINARA',
    'KAYA DIODORA',
    'KAYA DIONYSUS',
    'KAYA DIORIT',
    'KAYA DISIPLINA',
    'KAYA DISKET',
    'KAYA DISKO',
    'KAYA DJEMBE',
    'KAYA DJOGODO',
    'KAYA DJOGODÓ',
    'KAYA DODO PALM',
    'KAYA DÒDÒ PALM',
    'KAYA DOLOMIT',
    'KAYA DOMINICO J.R. HERRERA',
    'KAYA DOMINICO J.R. HERRERA',
    'KAYA DONAR',
    'KAYA DORI',
    'KAYA DÒRI',
    'KAYA DORNFELDER',
    'KAYA DORUS',
    'KAYA DOSPLAKA',
    'KAYA DÒSPLAKA',
    'KAYA DOUWE ZALM',
    'KAYA DOY SILLE',
    'KAYA DOY SILLÉ',
    'KAYA DR D. M. NOUEL',
    'KAYA DR GAERSTE',
    'KAYA DR GUNGU MAAL',
    'KAYA DR JULIUS BENESCH',
    'KAYA DR M. BERKENVELD',
    'KAYA DR. D.M. NOUEL',
    'KAYA DR. FRANK DE CASTRO',
    'KAYA DR. GUNGU MAAL',
    'KAYA DR. J.W.M. DIEMONT',
    'KAYA DR. JULIUS BENESCH',
    'KAYA DR. T. GAERSTE',
    'KAYA DRAM',
    'KAYA DRIL',
    'KAYA DRS OY SPROCK',
    'KAYA DRS. OY SPROCK',
    'KAYA DRUMS',
    'KAYA DUBLOEN',
    'KAYA DULSURA',
    'KAYA EBRAND E. VIRGINIE',
    'KAYA EBRAND E. VIRGINIE "Ebran Daanchi"',
    'KAYA EDGAR P."GACHI" SUPRIANO',
    'KAYA EDUARDO PIETERSON',
    'KAYA EDWARD G.C. PLAATE',
    'KAYA EDWIN L THOMASA',
    'KAYA EDWIN L. THOMASA',
    'KAYA EGEO',
    'KAYA EHEMBER',
    'KAYA EKALIATH',
    'KAYA EKALIPTO',
    'KAYA EKSITO',
    'KAYA ELEAZAR C. "CHAL" ATALITA',
    'KAYA ELEGANSIA',
    'KAYA ELIAS NEPOMOCEMO',
    'KAYA ELIAS NEPOMUCENO',
    'KAYA ELIJAH',
    'KAYA ELISABETH R. PHELIPA',
    'KAYA ELISHA',
    'KAYA ELIZABETH R. PHILIPA "BÈCHI"',
    'KAYA ELOGIO',
    'KAYA EMETERIO F. DORAN',
    'KAYA EMETERIO F. DORAN "Theo"',
    'KAYA EMPLIFAYER',
    'KAYA ENERGIA',
    'KAYA ENKANTO',
    'KAYA EPIDENDRUM',
    'KAYA EPONA',
    'KAYA ERATO',
    'KAYA ERNST A. "AUGUSTU" GRESSMAN',
    'KAYA ERQUIMIDEZ M. "KIMU" CANDELARIA',
    'KAYA ESCOBARIA',
    'KAYA ESCOLASTICA RUSSIL',
    'KAYA ESCUDO',
    'KAYA ESPINELA',
    'KAYA EUGÈNE A.M. TESTING',
    'KAYA EURO',
    'KAYA EUSEBIO (Jo) BERNARDUS',
    'KAYA EXOLASTICA RUSSIL',
    'KAYA F',
    'KAYA FABIANA C. ANTONIA-MARTINA',
    'KAYA FABIANA C.ANTONIA-MARTINA',
    'KAYA FAGÒT',
    'KAYA FAKS',
    'KAYA FALCON',
    'KAYA FARO',
    'KAYA FECUNDITAS',
    'KAYA FELIPE R. "FEO" SULVARAN',
    'KAYA FELISIDAT',
    'KAYA FELIX J JULIANA',
    'KAYA FELIX J. JULIANA',
    'KAYA FELIX J.JULIANA',
    'KAYA FELPA',
    'KAYA FELSITA',
    'KAYA FÈNKEL',
    'KAYA FENSU',
    'KAYA FÈNSU',
    'KAYA FERRARA',
    'KAYA FIDES',
    'KAYA FIFI ROMER',
    'KAYA FILES',
    'KAYA FILIPINO',
    'KAYA FINFIN',
    'KAYA FIRENZE',
    'KAYA FIRMESA',
    'KAYA FISHTAIL',
    'KAYA FLAMBOYAN',
    'KAYA FLENT',
    'KAYA FLOR DI LIBERTAT',
    "KAYA FLOR'I ANGEL",
    'KAYA FLORA CIJNTJE',
    'KAYA FLORENCIO C. "LOU" MARTINA',
    'KAYA FLORENCIO C. "LOU" MARTINA',
    'KAYA FLORIN',
    'KAYA FLOUTA',
    'KAYA FOLKLOR',
    'KAYA FOMORII',
    'KAYA FONOGRAF',
    'KAYA FORT DE FRANCE',
    'KAYA FORTE',
    'KAYA FORTUNATO H. VAN AANHOLT',
    'KAYA FOXTAIL',
    'KAYA FRANC',
    'KAYA FRANCIA',
    'KAYA FRANCISCO P. EISDEN',
    'KAYA FRANK DE CASTRO',
    'KAYA FRANKLYN "PENG" DE WINDT',
    'KAYA FRATER ANDREAS LEMMENS',
    'KAYA FRATER CANUTES',
    'KAYA FRATER FIDELIUS',
    'KAYA FRATER GUALBERT',
    'KAYA FRATERNAN DI SKERPENE',
    'KAYA FREYA',
    "KAYA FRUT'E DAM",
    "KAYA FRUT'I PAN",
    "KAYA FRUT'I PAN",
    'KAYA FUERTE',
    'KAYA FUÈRTÈ',
    'KAYA GABARDIN',
    'KAYA GABARRO',
    'KAYA GABRIEL',
    'KAYA GABRIEL A. "GABICHI" MARTIS',
    'KAYA GABRIEL A. GABICHI" MARTIS',
    'KAYA GALATEA',
    'KAYA GALEARIS',
    'KAYA GALENA',
    'KAYA GAMAY',
    'KAYA GARAFI',
    'KAYA GARAPA',
    'KAYA GARBANSO',
    'KAYA GARGANEGA',
    'KAYA GAROEDA',
    'KAYA GEFION',
    'KAYA GENIUS',
    'KAYA GEORGETOWN',
    'KAYA GERSH',
    'KAYA GHANA',
    'KAYA GIBRALTAR',
    'KAYA GILBERT G. "GIBI" DJAOEN',
    'KAYA GILBERTO A."BETI" DORAN',
    'KAYA GINGERPALM',
    'KAYA GIRASOL',
    'KAYA GITER',
    'KAYA GLADYS TH. L. MADURO - PANNEFLEK',
    'KAYA GLADYS TH.I. MADURO',
    'KAYA GOBI',
    'KAYA GOMESA',
    'KAYA GOUPIA',
    'KAYA GOURDES',
    'KAYA GRAFITO',
    'KAYA GRAMOFON',
    'KAYA GRANIT',
    'KAYA GRAP',
    'KAYA GREIS',
    'KAYA GROSCHEN',
    'KAYA GROTE BERG',
    'KAYA GRUSONIA',
    'KAYA GUAMARES',
    'KAYA GUARANI',
    'KAYA GUINEA',
    'KAYA GUIRO',
    'KAYA GUSANIO',
    'KAYA GUTIEREZ I GERALDINA',
    'KAYA GUZMAN BLANCO',
    'KAYA H•LSIT',
    'KAYA HAGGAI',
    'KAYA HAIDA',
    'KAYA HALERU',
    'KAYA HALO',
    'KAYA HANDEL',
    'KAYA HARDIN',
    'KAYA HARMONIA',
    'KAYA HAROLD N. COSSTER',
    'KAYA HARPA',
    'KAYA HARRISIA',
    'KAYA HATIORA',
    'KAYA HAVANA',
    'KAYA HAZAÑA',
    'KAYA HECTOR P. CURIEL',
    'KAYA HEIN STELP',
    'KAYA HEITJE',
    'KAYA HELDU',
    'KAYA HELIOS',
    'KAYA HELLER',
    'KAYA HEMATITA',
    'KAYA HENDRIK E. "HENNY" VAN VELZEN',
    'KAYA HENNY EMAN',
    'KAYA HERMACHIS',
    'KAYA HESPERIDES',
    "KAYA HOF'I FLOR",
    "KAYA HÒF'I FLOR",
    'KAYA HOMAN',
    'KAYA HONOR',
    'KAYA HORACIO SPROCK',
    "KAYA HOSE 'I ÑAÑA",
    'KAYA HOSE I NANA',
    'KAYA HOSE S. JANSEN',
    'KAYA HOSE S. JANSEN "Cha Pèpi"',
    'KAYA HOSLIT',
    'KAYA HÒSLIT',
    'KAYA HUBERT D. FERNANDEZ',
    'KAYA HUBERT M. "IBI" SCHOOP',
    'KAYA HUBERT SALAS JR.',
    'KAYA HYGIEIA',
    'KAYA ICARUS',
    'KAYA INDIGÓ',
    'KAYA ÍNDIKO',
    'KAYA INFROU',
    'KAYA INIGO',
    'KAYA INIGO',
    'KAYA INSEKTO',
    'KAYA INSEKTO',
    'KAYA INTERKOM',
    'KAYA INTERKÓM',
    'KAYA INTERNET',
    'KAYA IPSEA',
    'KAYA IRENA M. SCHOOP',
    'KAYA IRENA M. SCHOOP "Yùfrou Irena"',
    'KAYA ISAAK',
    'KAYA ISAIAH',
    'KAYA ISAIAS S. "IYO" PIMENTEL',
    'KAYA ISHVARA',
    'KAYA ISIS',
    'KAYA ITO MAURICIO',
    'KAYA IXORA',
    'KAYA JACOB L NAAR',
    'KAYA JACOB NAAR',
    'KAYA JACOB POSNER',
    'KAYA JAN THIEL',
    'KAYA JANCHI BOSKALJON',
    'KAYA JANTEN',
    'KAYA JANUS',
    'KAYA JEREMIAH',
    'KAYA JO MATHILDA',
    'KAYA JOCASTA',
    'KAYA JOEL',
    'KAYA JOHANNES R. FRANCISCO',
    'KAYA JOHANNES R. FRANSISCO "Dito"',
    'KAYA JOHN DE JONGH',
    'KAYA JOHN HORRIS SPROCKEL',
    'KAYA JOHN J. WILLEKES',
    'KAYA JOHN MENGE',
    'KAYA JOHN MONSANTO',
    'KAYA JOMBI MENSING',
    'KAYA JONAS',
    'KAYA JONCHI',
    'KAYA JONGBLOED NOORD',
    'KAYA JONGE WACHT',
    'KAYA JORGE SUARES',
    'KAYA JORGITO O. "ORITO" ANTERSIJN',
    'KAYA JOSE A CASSERES',
    'KAYA JOSE A. CASSERES',
    'KAYA JOSE JOSE "JO" MARIA PINEDOE',
    'KAYA JOSE M. "JO" MATHILDA',
    'KAYA JOSE MARIA PINEDO',
    'KAYA JOSUA',
    'KAYA JUAN A. LESIRE',
    'KAYA JUAN DAMPUES',
    'KAYA JUAN DAMPUES',
    'KAYA JUAN DE LA COSA',
    'KAYA JUAN F. GEERTRUIDA "Johan Méhito"',
    'KAYA JUAN F. GERTRUIDA',
    'KAYA JUAN R. CAMELIA',
    'KAYA JUF POLITA DIRKSZ',
    'KAYA JUFFR. PAULITA DIRKSZ',
    'KAYA JULES BLASINI',
    'KAYA JULIO ABRAHAM',
    'KAYA JULIO MARTIENA',
    'KAYA JULIO MARTINA',
    'KAYA JULIO O. "MARACUCHO" HELLBURG',
    'KAYA JULIUS P. SERVILIO',
    'KAYA JULIUS P. SERVILIO "Yúyú',
    'KAYA JUNIOR SALAS',
    'KAYA KACHU',
    'KAYA KADANS',
    'KAYA KADENA DI AMOR',
    'KAYA KADENA PLACHI',
    'KAYA KADUSHI',
    'KAYA KAKDARA',
    'KAYA KAKINA',
    'KAYA KAKIÑA',
    'KAYA KALA',
    'KAYA KALABANA',
    'KAYA KALKI',
    'KAYA KALSIT',
    'KAYA KAMACHO',
    'KAYA KAMILLE',
    'KAYA KAMPÈSHI',
    'KAYA KANAL',
    'KAYA KANCHI',
    'KAYA KANÉ',
    'KAYA KANELUBLUM',
    'KAYA KANELUBLUM',
    'KAYA KANKANTRI',
    'KAYA KARABANA',
    'KAYA KARABOBO',
    'KAYA KARAMÈLÈ',
    'KAYA KARBONSTEIN',
    'KAYA KARDEMON',
    'KAYA KARIBE',
    'KAYA KARKO',
    'KAYA KARKÓ',
    'KAYA KARST',
    'KAYA KARTA',
    'KAYA KASET',
    'KAYA KASÈT',
    'KAYA KASHA',
    'KAYA KASHIMIRI',
    'KAYA KASTAÑA',
    'KAYA KASTANEA',
    'KAYA KASUELA',
    'KAYA KATHLEAS',
    'KAYA KATOENTUIN',
    "KAYA KATUN 'SEDA",
    'KAYA KATUNB÷M',
    'KAYA KATUNBOM',
    'KAYA KATUNBOM',
    'KAYA KATUNBÓM',
    'KAYA KAYMAN',
    'KAYA KAZERNE',
    'KAYA KEKFRANKOS',
    'KAYA KENT U ZELF',
    'KAYA KERKPALM',
    'KAYA KERNER',
    'KAYA KETEMBELE',
    'KAYA KETURAH',
    'KAYA KHAKI',
    'KAYA KIMBALA',
    'KAYA KIOWA',
    'KAYA KITOKI',
    'KAYA KITRIN',
    'KAYA KIWA',
    'KAYA KLARINÈT',
    'KAYA KLAVE',
    'KAYA KLEI',
    'KAYA KLEKONCHI',
    'KAYA KLEMOK',
    'KAYA KLEMÒK',
    "KAYA KO'I LECHI",
    'KAYA KOHARA',
    'KAYA KOKADA',
    'KAYA KOKCHI',
    'KAYA KOKO LE POL',
    'KAYA KOKOLISHI',
    'KAYA KOKOSPALM',
    'KAYA KOKOU',
    'KAYA KOKS',
    'KAYA KOLONCHI',
    'KAYA KOLONEL CAREL N. WINKEL (CAY)',
    'KAYA KOLONELWINKEL',
    'KAYA KOLORITA',
    'KAYA KÒMBITU',
    'KAYA KOMEHEIN',
    'KAYA KOMINO',
    'KAYA KOMPIUTER',
    'KAYA KONFEIT',
    'KAYA KONFO',
    'KAYA KONFÓ',
    'KAYA KONGA',
    'KAYA KONINGSPALM',
    'KAYA KONKISTA',
    'KAYA KONOFES',
    'KAYA KONOFL¢',
    'KAYA KONOFLÓ',
    'KAYA KONOFLð',
    'KAYA KONTRABAS',
    'KAYA KOOYMAN',
    'KAYA KORANT',
    'KAYA KORNELIN',
    "KAYA KORON'I LA BIRGUI",
    "KAYA KORON'I LA BIRGEN",
    "KAYA KORON'I LA BIRGUI",
    'KAYA KORONASHON',
    'KAYA KORUNA',
    'KAYA KOSECHA',
    'KAYA KOTO',
    'KAYA KOTOMISI',
    'KAYA KRA STENCHI',
    'KAYA KRATOS',
    'KAYA KREINÁ',
    'KAYA KRENWA',
    'KAYA KRES ULDER',
    'KAYA KREUZER',
    'KAYA KREWCHI',
    'KAYA KRISHNA',
    'KAYA KRISOKOLA',
    'KAYA KRONER',
    'KAYA KUARSO',
    'KAYA KUARTA',
    'KAYA KUIDA',
    'KAYA KUIHI',
    "KAYA KUK'I INDJAN",
    'KAYA KUKI INDJAN',
    'KAYA KUNA',
    'KAYA KUNUKERO',
    'KAYA KURA PIEDRA',
    'KAYA KURTISA',
    'KAYA KUSTBATTERIJ',
    'KAYA KYAT',
    'KAYA LA PAZ',
    'KAYA LA PLATA',
    'KAYA LABARIANO',
    'KAYA LABIZJAN',
    'KAYA LAELIA',
    'KAYA LAMPER',
    'KAYA LANDO',
    'KAYA LANGULE',
    'KAYA LANTERA',
    'KAYA LARIMAR',
    'KAYA LATANIA',
    'KAYA LAUREL',
    'KAYA LEALTAT',
    'KAYA LÈIS',
    'KAYA LEMPIRA',
    'KAYA LEONARD "LEO" RENAUD',
    'KAYA LEOPOLD III',
    'KAYA LEPTA',
    'KAYA LEPTOP',
    'KAYA LÈPTÓP',
    'KAYA LÈTER',
    'KAYA LEVA',
    'KAYA LEYDI',
    'KAYA LIBERTADORA',
    'KAYA LIBRA ESTERLINA',
    'KAYA LIMA',
    'KAYA LIMONITA',
    'KAYA LINASA',
    'KAYA LINASA',
    'KAYA LIRE',
    'KAYA LIVISTONA',
    'KAYA LIVORNO',
    'KAYA LOBI',
    'KAYA LOCHA',
    'KAYA LOGRO',
    'KAYA LOISEAUX MADURO',
    'KAYA LOUIS C. ZSCHUSCHEN',
    'KAYA LOUIS C. ZSCHUSCHEN',
    'KAYA LOUIS DE LANOOY',
    'KAYA LUCIA M. HOSEPA CELESTINA',
    'KAYA LUDWIG "LUTI" SAMSON',
    'KAYA LUIS A.J. DE LANNOY',
    'KAYA LUIS F. PHILLIPS',
    'KAYA LUIS PHILLIPS',
    'KAYA LYCASTE',
    'KAYA MA FEITA-FRUMU',
    'KAYA MA PIETERNELLA',
    'KAYA MABI',
    'KAYA MACABEO',
    'KAYA MACARIO M. "MAKAI" PRUDENCIA',
    'KAYA MADI',
    'KAYA MADURO PANNEFLEK',
    'KAYA MAESTRONAN DI SKOL',
    'KAYA MAFALDA J. SALSBACH-MINGUEL',
    'KAYA MAGELLAN',
    'KAYA MAGNESIT',
    'KAYA MAKARIOS',
    'KAYA MAKUTU',
    'KAYA MALBEC',
    'KAYA MALOA',
    'KAYA MALOGIA',
    'KAYA MALVASIA',
    'KAYA MALVERN',
    'KAYA MALWA',
    'KAYA MAMA TERA',
    'KAYA MAMMILARIA',
    'KAYA MAMONDENGUE',
    'KAYA MAN SUSHI',
    'KAYA MANAGUA',
    'KAYA MANAURE',
    'KAYA MANDOLIN',
    'KAYA MANGASINA',
    'KAYA MANILA',
    'KAYA MANUEL ANTONIO GUITIERREZ &nbsp;I ORLANDO GREGORIO GERARDINA',
    'KAYA MANUEL V. RIBEIRO',
    'KAYA MARENGO',
    'KAYA MARIA F.PIETERSZ-LACLE',
    'KAYA MARIA I MARGARITHA CARMELA-DAM',
    'KAYA MARIANITA M. BENITA - CONRADUS',
    'KAYA MARIBOMBA',
    'KAYA MARIFON',
    'KAYA MARIGOLD',
    'KAYA MARÍMBULA',
    'KAYA MARKKA',
    'KAYA MARKUSA',
    'KAYA MARMARA',
    'KAYA MARQUISETTE',
    'KAYA MARSANNE',
    'KAYA MARSELAN',
    'KAYA MARVEDIS',
    'KAYA MASALA',
    'KAYA MATA SIGUARAYA',
    'KAYA MATAKI',
    'KAYA MATRIMONIAL',
    'KAYA MATUCANA',
    'KAYA MAX F. HENRIQUEZ',
    'KAYA MEGAFON',
    'KAYA MEGAFÒN',
    'KAYA MEIBERG',
    'KAYA MELAFIER',
    'KAYA MELÓDIKA',
    'KAYA MENDELSSOHN',
    'KAYA MENSAHERO',
    'KAYA MENTA',
    'KAYA MERCELINA M. KWIDAMA OSEPA',
    'KAYA MERCELINA M. KWIDAMA-OSEPA "Ma Chè"',
    'KAYA MERIDA',
    'KAYA MERLOT',
    'KAYA METEOROLOGICO',
    'KAYA MIDAS',
    'KAYA MIGUEL SURIEL',
    'KAYA MIGUELITO SENIOR',
    'KAYA MIKEAS',
    'KAYA MIKEÁS',
    'KAYA MILA',
    'KAYA MILIEME',
    'KAYA MILITAR',
    'KAYA MILON DI SERU',
    'KAYA MIMOSA',
    'KAYA MINADO',
    'KAYA MINGUEL',
    'KAYA MINGUELITO SENIOR',
    'KAYA MINOS',
    'KAYA MINOTAURO',
    'KAYA MINT',
    'KAYA MINT',
    'KAYA MINUGUA BANK',
    'KAYA MIRANDA',
    'KAYA MISA ALEGRE',
    'KAYA MIYSIS',
    'KAYA MOBILOFON',
    'KAYA MOCHILA',
    'KAYA MODOC',
    'KAYA MOISES',
    'KAYA MOLINA',
    'KAYA MONAGAS',
    'KAYA MONEDAS',
    'KAYA MONTEVIDEO',
    'KAYA MONZONIT',
    'KAYA MORA',
    'KAYA MORICHE PALM',
    'KAYA MORINGO',
    'KAYA MORRIGAN',
    'KAYA MOZAMBIQUE',
    'KAYA MOZART',
    'KAYA MUIZENBERG',
    'KAYA MUSCAT',
    'KAYA MYRUGIA A.B. ELIZABETH',
    'KAYA NAHUM',
    'KAYA NAIGUATA',
    'KAYA NAIGUATÁ',
    'KAYA NAIRA',
    "KAYA NANZ'I KOKO",
    'KAYA NANZI KOKO',
    'KAYA NAPOLI',
    'KAYA NARCISUS',
    'KAYA NATHAN',
    'KAYA NAVIGATOR',
    'KAYA NEBLINA',
    'KAYA NECHI PIETERS',
    'KAYA NEHEMIAH',
    'KAYA NEMESIO F. "CHI" DOMITILIA',
    'KAYA NEMON',
    'KAYA NENE HUECK',
    'KAYA NENE HÜECK',
    'KAYA NENE ROZENDAL',
    'KAYA NÈNÈ ROZENDAL',
    'KAYA NEREIDE',
    'KAYA NEREUS',
    'KAYA NETUMUSKAT',
    'KAYA NEWBURN I PATRICKS',
    'KAYA NEWBURN I. PATRICKS',
    'KAYA NIKE',
    'KAYA NIM',
    'KAYA NIMFA',
    'KAYA NOBLESA',
    'KAYA NOORD JONG BLOED',
    'KAYA NOORD JONGBLOED',
    'KAYA NORMAN E. CALMES',
    'KAYA NOTYLIA',
    'KAYA NUBIA',
    'KAYA NUEVA ESPARTA',
    'KAYA O C BERNABELA',
    'KAYA O.C. BERNABELA',
    'KAYA OBADIAH',
    'KAYA OBSIDIAAN',
    'KAYA ODYSEO',
    'KAYA OGETE',
    'KAYA OKRA',
    'KAYA OLIBA',
    'KAYA OLIEPALM',
    'KAYA ONCIDIUM',
    'KAYA ONZA',
    'KAYA ORE',
    'KAYA ORENGA',
    'KAYA ORFEO',
    'KAYA ÒRGEL',
    'KAYA ORKETA',
    'KAYA ORKIDIA',
    'KAYA OROYA',
    'KAYA ORSELLI',
    'KAYA OSWALD A.M. SPECHT',
    'KAYA OSWIN J.M. "OSSY" HENRIQEUZ',
    'KAYA OSWIN J.M. "OSSY" HENRIQUEZ',
    'KAYA OTAHEITI',
    'KAYA OTAHEITO',
    'KAYA OTTO SENIOR',
    'KAYA OUCHI',
    'KAYA PA‰TON',
    'KAYA PACHI JAN BAKHUIS',
    'KAYA PADOVA',
    'KAYA PAGAI',
    'KAYA PAISA',
    'KAYA PAIUTE',
    'KAYA PALOMINO',
    'KAYA PAMPA',
    'KAYA PANACEA',
    'KAYA PANDELLIS',
    'KAYA PANIWERI',
    'KAYA PANLEFI',
    'KAYA PANSEIKU',
    'KAYA PAPACHI SUTHERLAND',
    'KAYA PAPAYA',
    'KAYA PAPILON',
    'KAYA PARAISO',
    'KAYA PARCHITA',
    'KAYA PARNASO',
    'KAYA PASÍFIKO',
    'KAYA PASTOR WOLTERS',
    'KAYA PATER HOFHUIS',
    'KAYA PATER M.D. LATOUR',
    'KAYA PAUK',
    'KAYA PAUL DE LIMA',
    'KAYA PEDRO WACAO',
    'KAYA PEGASAYA',
    'KAYA PEGMATIT',
    'KAYA PENCE',
    'KAYA PÈNKI',
    'KAYA PENNY',
    'KAYA PEPER',
    'KAYA PEPERMUNT',
    'KAYA PERGOLA',
    'KAYA PÈRGOLA',
    'KAYA PÈRSIKO',
    'KAYA PERUGIA',
    'KAYA PES',
    'KAYA PESCARA',
    'KAYA PESETA',
    'KAYA PESO',
    'KAYA PETERSELI',
    'KAYA PETRONILIA M. "PETOI" COCO',
    'KAYA PHA‰TON',
    'KAYA PHA‰TON',
    'KAYA PHAËTON',
    'KAYA PIANO',
    'KAYA PIASTRES',
    'KAYA PICHIBÉ',
    'KAYA PIDI SKINA',
    "KAYA PIED'I BOS",
    "KAYA PIED'I KANDELA",
    "KAYA PIED'I SABABA",
    "KAYA PIED'I SABADA",
    'KAYA PIEDA MACHU',
    'KAYA PIEDA NATURAL',
    'KAYA PIEDA PRESIOSO',
    'KAYA PIEDRA MACHU',
    'KAYA PIKARON',
    'KAYA PIKI',
    'KAYA PÍKOLO',
    'KAYA PIKON',
    'KAYA PILON',
    'KAYA PIRITA',
    'KAYA PIRO',
    'KAYA PISPIS',
    'KAYA PITA',
    'KAYA PITIS',
    'KAYA PLANTASHON',
    'KAYA PLATEAU',
    'KAYA PLATIO',
    'KAYA PLUG',
    'KAYA POINT A PITRE',
    'KAYA POLKAR',
    'KAYA POMEZ',
    'KAYA PÒMP',
    'KAYA PORFIDO',
    'KAYA PORON',
    'KAYA PORT AU PRINCE',
    'KAYA PORT OF SPAIN',
    'KAYA PORTICI',
    'KAYA POS',
    'KAYA POTOMAC',
    'KAYA PREI',
    'KAYA PRELUDIO',
    'KAYA PRESTIGIO',
    'KAYA PRIM',
    'KAYA PROF. ENRIQUE ECKER',
    'KAYA PROGRESO',
    'KAYA PROMÈNTÒN',
    'KAYA PRONOIA',
    'KAYA PROSPERIDAT',
    'KAYA PURESA',
    'KAYA PUSPAS',
    'KAYA QUETZAL',
    'KAYA QUITO',
    'KAYA R. M. CAMELIA',
    'KAYA R.J. BEAUJON',
    'KAYA RADAR',
    'KAYA RADIO',
    'KAYA RAFAEL',
    'KAYA RAFAEL HANSEN',
    'KAYA RAKELIOU',
    'KAYA RAND',
    'KAYA RANKIL',
    'KAYA RAPPEN',
    'KAYA RASPU',
    'KAYA RAYMOND "BOY" BRION',
    'KAYA REAL',
    'KAYA REF BARRIER',
    'KAYA REF BUCCO',
    'KAYA REF CYRENE',
    'KAYA REF ETHEL',
    'KAYA REF KINGMAN',
    'KAYA REF NINGALOO',
    'KAYA REF PALANCAR',
    'KAYA REF SOUFRIERE',
    'KAYA REF TUBLE',
    'KAYA REGENBOOG',
    'KAYA REINIR BOR',
    'KAYA REIS',
    'KAYA REMA',
    'KAYA REMIEL',
    'KAYA RENBAK',
    'KAYA RENE DE ROOY',
    "KAYA RESPOND'I BOKA",
    'KAYA RHAPIS',
    'KAYA RIESLING',
    'KAYA RIMINI',
    'KAYA RIVOLI',
    'KAYA ROBARDO',
    'KAYA ROEBELINI',
    'KAYA ROI CATOCHI',
    'KAYA ROI KATOCHI',
    'KAYA ROSAMAREIN',
    'KAYA ROSEANA',
    'KAYA RUBLO',
    'KAYA RUDA',
    'KAYA RUKU',
    'KAYA S0UER ARCADIA',
    'KAYA SABA BANK',
    'KAYA SABANA BAKA',
    'KAYA SAFRAN',
    'KAYA SAGARIA',
    'KAYA SAGRAD¢',
    'KAYA SAGRAD÷',
    'KAYA SAGRADO',
    'KAYA SAGRADÓ',
    'KAYA SAKADO',
    'KAYA SAKADÓ',
    'KAYA SAKSOFON',
    'KAYA SALISH',
    'KAYA SALVIA',
    'KAYA SAMBARKU',
    'KAYA SAMMAEL',
    'KAYA SAMMUEL',
    'KAYA SAMUEL',
    'KAYA SAN ANDRES',
    'KAYA SAN DAMASITO P. ANITA',
    'KAYA SAN JOSE',
    'KAYA SAN JUAN',
    'KAYA SANTEEM',
    'KAYA SANTIAGO DE CHILE',
    'KAYA SANTIMAC',
    'KAYA SATELITE',
    'KAYA SATIN',
    'KAYA SAVIGNON',
    'KAYA SAW PALMETTO',
    'KAYA SAYA KU DJÈKI',
    'KAYA SAYET',
    'KAYA SCHOMBURGIA',
    'KAYA SCHUBERT',
    'KAYA SECOTAN',
    'KAYA SECURITAS',
    'KAYA SEINPOST',
    'KAYA SEKOU',
    'KAYA SÈLDU',
    'KAYA SELENE',
    'KAYA SELULAR',
    'KAYA SEMI KOK',
    'KAYA SENAL',
    'KAYA SEÑAL',
    'KAYA SENTE BIBU',
    'KAYA SEPELIN',
    "KAYA SER'I KALKI",
    "KAYA SER'I MANGUSA",
    "KAYA SER'I PAPAYA",
    "KAYA SER'I SPILON",
    'KAYA SERAFIN',
    'KAYA SERENA',
    'KAYA SERENÁ',
    'KAYA SERI',
    'KAYA SERU ANITA',
    'KAYA SERU BAHA SO',
    'KAYA SERU BARTOL',
    'KAYA SERU BARTÒL',
    'KAYA SERU BAYAN',
    'KAYA SERU BLANKU',
    'KAYA SERU BRANDAAR',
    'KAYA SERU CABAYE',
    'KAYA SERU CABAYÉ',
    'KAYA SERU CUEBA',
    'KAYA SERU DI MILON',
    'KAYA SERU DINGU',
    'KAYA SERU DJUKU',
    'KAYA SERU GABILAN',
    'KAYA SERU GRANDI',
    'KAYA SERU GRASIA',
    'KAYA SERU KOSTA',
    'KAYA SERU KRISTOF',
    'KAYA SERU KRISTÒF',
    'KAYA SERU LARGU',
    'KAYA SERU LUCIA',
    'KAYA SERU MACAMBA',
    'KAYA SERU MACHU',
    'KAYA SERU MAHOC',
    'KAYA SERU MAHUMA',
    'KAYA SERU MALE',
    'KAYA SERU MALÉ',
    'KAYA SERU MANGASINA',
    'KAYA SERU MANGUSA',
    'KAYA SERU MATEO',
    'KAYA SERU MEIBERG',
    'KAYA SERU MOLINA',
    'KAYA SERU NOBO',
    'KAYA SERU PAIS',
    'KAYA SERU PAPAYA',
    'KAYA SERU PARA MIRA',
    'KAYA SERU PIETER',
    'KAYA SERU POPCHI',
    'KAYA SERU PÒPCHI',
    'KAYA SERU RONDO',
    'KAYA SERU SABLICA',
    'KAYA SERU SPILON',
    'KAYA SERU TINTA',
    'KAYA SERU YUANA',
    'KAYA SESAM',
    'KAYA SEVERINA L. MARTINA-ANITA',
    'KAYA SEVERITA L. MARTINA ANITA',
    'KAYA SHEKERÉ',
    'KAYA SHERS',
    'KAYA SHÈRS',
    'KAYA SHI MARTINA',
    'KAYA SHILLING',
    'KAYA SHON LOUIS PERRET',
    'KAYA SHON TONI PRINCE',
    'KAYA SILANTRO',
    'KAYA SILOFON',
    'KAYA SILVANER',
    'KAYA SIMIA DI MÒSTERT',
    'KAYA SIMON PIETERS KWIERS',
    'KAYA SINFONIA',
    'KAYA SINKURIA',
    'KAYA SIRAG',
    'KAYA SIXTO R. "CHITO" WALLÉ',
    'KAYA SKALCHI',
    'KAYA SKAPADEPCHI',
    'KAYA SKONDITE',
    'KAYA SLABRIKI',
    'KAYA SOBREKAMA',
    'KAYA SOEUR BATHILDA',
    'KAYA SOEUR BATHILDE',
    'KAYA SOEUR NICAPHORA',
    'KAYA SOEUR NICEPHORA',
    'KAYA SOEUR THERESITA',
    'KAYA SOEUR URSULINA',
    'KAYA SOL',
    'KAYA SOLE',
    'KAYA SOMBRÉ CHINES',
    'KAYA SONAR',
    'KAYA SONRISA',
    'KAYA SÒPITU',
    'KAYA SOPROPO',
    'KAYA SOUER CANTALICIA',
    'KAYA SOUER EUBERTA',
    'KAYA SOUER FELICITAS',
    'KAYA SOUER FIRMINA',
    'KAYA SPARTA',
    'KAYA SPENIKOK',
    'KAYA SPINÈT',
    'KAYA SPRENGERI',
    'KAYA STA. BRIGITTA',
    'KAYA STAKATO',
    'KAYA STANSHA',
    'KAYA STENIA',
    'KAYA STENLLY C CORNELIA',
    'KAYA STENLLY C. CORNELIA',
    'KAYA STIFULE',
    'KAYA STILA',
    'KAYA STOKI',
    'KAYA STRAUSS',
    'KAYA STRENA',
    'KAYA STRIPAN',
    'KAYA STUIVER',
    'KAYA SUCRE',
    'KAYA SUEZ',
    'KAYA SUPRIANO J. LOURENS',
    'KAYA SUPRIANO J. LOURENS "Pián"',
    'KAYA SÙR ARCADIA wereldnaam Petronella L. Herera',
    'KAYA SÙR CANTALICIA wereldnaam Cicilia F. Pourier',
    'KAYA SÙR EUBERTA wereldnaam Eulogia J. Maduro',
    'KAYA SUR EUBERTHA',
    'KAYA SÙR FELICITAS wereldnaam Maria A. Statia',
    'KAYA SÙR FIRMINA wereldnaam Catharina Tuijp',
    'KAYA TAFETAN',
    'KAYA TAI TAI',
    'KAYA TALKO',
    'KAYA TALON',
    'KAYA TAMBÚ',
    'KAYA TAMBUREIN',
    'KAYA TAMBURERO',
    'KAYA TAMTAM',
    'KAYA TANGER',
    'KAYA TANKI',
    'KAYA TANNAT',
    'KAYA TANZANIT',
    'KAYA TAPAKONCHI',
    'KAYA TAPUSHI',
    'KAYA TARASCAN',
    'KAYA TARIFA',
    'KAYA TATI',
    'KAYA TEGUCIGALPA',
    'KAYA TEIM',
    'KAYA TEKU',
    'KAYA TELEFON',
    'KAYA TELEFÒN',
    'KAYA TELEGRAFIA',
    'KAYA TELEGRAM',
    'KAYA TELEKOMUNIKASHON',
    'KAYA TELEKS',
    'KAYA TELÈKS',
    'KAYA TELETIPO',
    'KAYA TELEVISHON',
    'KAYA TELSTAR',
    'KAYA TÈLSTAR',
    'KAYA TEMÉTIKA',
    'KAYA TENTALARIA',
    'KAYA TERA CORA',
    'KAYA TERNURA',
    'KAYA TERPSICHORE',
    'KAYA TERT',
    'KAYA THEBE',
    'KAYA THEMIS',
    'KAYA THOMAS D.TOKAAY',
    'KAYA THOMAS HENRIQUEZ',
    'KAYA TIBARÒS',
    'KAYA TIBOURIN',
    'KAYA TIMBAL',
    'KAYA TIMICUAN',
    'KAYA TINASHI',
    'KAYA TINASHI',
    'KAYA TIRAMISÚ',
    'KAYA TIRIYO',
    'KAYA TITIARO',
    'KAYA TITO SOUBLETTE',
    'KAYA TIYIN',
    'KAYA TJON (AUGUSTO) QUAST',
    'KAYA TOBO',
    'KAYA TONI KUNCHI',
    'KAYA TOR VAN DER LINDE',
    'KAYA TORINO',
    'KAYA TOUSSAINT',
    'KAYA TRACHIET',
    'KAYA TRANKE',
    'KAYA TRANSMISORA',
    'KAYA TRÈKTÒR',
    'KAYA TRINITARIA',
    'KAYA TRIUNFO',
    'KAYA TROMBON',
    'KAYA TRONCHI',
    'KAYA TULA',
    'KAYA TUNA',
    'KAYA TUNDRA',
    'KAYA TURMALINA',
    'KAYA TUSCARORA',
    'KAYA TUTIFRUTI',
    'KAYA TUTUMBA',
    'KAYA TUTURUTU',
    'KAYA TYCHE',
    'KAYA UNAGATU',
    'KAYA UÑAGATU',
    'KAYA UNION',
    'KAYA URDAL',
    'KAYA URIEL',
    'KAYA URSULITA F. VAN UYTRECHT HUDSON',
    'KAYA URSULITA F. VAN UYTRECHT-HUDSON "Yùfrou Ula"',
    'KAYA USTER',
    'KAYA VANDA',
    'KAYA VANDER',
    'KAYA VATU',
    'KAYA VENEZIA',
    'KAYA VENTRE',
    'KAYA VERCELLI',
    'KAYA VERDI',
    'KAYA VICTOR M. COMINENCIA',
    'KAYA VICTOR M. COMINENCIA "Vi"',
    'KAYA VICTOR X. ARCHANGEL',
    'KAYA VIEQUES',
    'KAYA VIKTORIA',
    'KAYA VIRGILIO A. KOEIERS',
    'KAYA VIRGINIA V. BITO',
    'KAYA VIRGINIA V. BITO - RICHARDSON',
    'KAYA VIROLA',
    'KAYA VIRTUD',
    'KAYA VIRTUT',
    'KAYA VIVALDI',
    'KAYA VIVIENNE L. FRANS',
    'KAYA VLADIMIER R.R. "COCO" BALENTIEN',
    'KAYA VLATAP',
    'KAYA VOLKSWONINGEN',
    'KAYA VREDEBERG',
    'KAYA VRUMINGA',
    'KAYA WABI',
    'KAYA WADJO',
    'KAYA WAMA',
    'KAYA WAMPARA',
    'KAYA WANA',
    'KAYA WANDU',
    'KAYA WANS',
    'KAYA WANTOMBA',
    'KAYA WAPOTI',
    'KAYA WASHINGTONI',
    'KAYA WATAPANA',
    'KAYA WAWA SCHOLTZ',
    'KAYA WAYA',
    'KAYA WEI',
    'KAYA WÈNCHI',
    'KAYA WEWE HELLBURG',
    'KAYA WICHARERO',
    'KAYA WILFRED (WILI) PIETERS',
    'KAYA WILFRIDO T. VALERIAAN',
    'KAYA WILFRIDO T. VALERIAAN',
    'KAYA WILLEM (JOMBI) F.G. MENSING',
    'KAYA WILLEMS J. CRATZ',
    'KAYA WILSON (PAPA) GODETT',
    'KAYA WILSON (PAPA) GODETT',
    'KAYA WIMPIRI',
    'KAYA WLADIMIER R.R. "COCO" BALENTIEN',
    'KAYA YADE',
    'KAYA YANCHI BOSKALJON',
    'KAYA YANTEN',
    'KAYA YANURA',
    'KAYA YASPE',
    'KAYA YAY ROMER',
    'KAYA YAY RÖMER',
    'KAYA YEN',
    'KAYA YÈN',
    "KAYA YERB'I FLORIDA",
    "KAYA YERB'I HOLE",
    "KAYA YERB'I SERU",
    "KAYA YERB'I SUELTU",
    'KAYA YERBA BUENO',
    'KAYA YERBA DI HOLE',
    'KAYA YERBA DI KAMISA',
    'KAYA YERBA LUISA',
    'KAYA YERBA SANGURA',
    'KAYA YERBA SANTA',
    'KAYA YEYE',
    'KAYA YOBIDA',
    'KAYA YOEL',
    'KAYA YOTIN',
    'KAYA Z',
    'KAYA ZAIRE',
    'KAYA ZEILPLA',
    'KAYA ZEPHANIAH',
    'KAYA ZEPHYR',
    'KAYA ZETES',
    'KAYA ZEUS',
    'KAYA ZIM',
    'KAYA ZINFANDEL',
    'KAYA ZIOTYCH',
    'KAYA ZJAZJIMEIN',
    'KAYA ZJOZJOLI',
    'KAYA ZR. L. DEUTEKOM',
    'KAYA ZUNI',
    'KAYA ZUSTER DEUTEKOM',
    'KAYA ZUSTERNAN DI SCHIJNDEL',
    'KAYUDA WEG',
    'KENCHAN',
    'KENEPA',
    'KENEPA WEG',
    'KENT U ZELF',
    'KENT U ZELF A',
    'KENT U ZELF B',
    'KENTUCKY WEG',
    'KEUKENSTRAAT',
    'KIJKDUIN STRAAT',
    'KINGSTON WEG',
    'KINIKINI WEG',
    'KINTJAN WEG',
    'KIP STEEG',
    'KIRINDONGO ABOU',
    'KIRINGDONGO ABOU M',
    'KIRINGDONGO ABOU N',
    'KIRINGDONGO ABOU O',
    'KITARA WEG',
    'KLARET',
    'KLASMULATO WEG',
    'KLEERMAKERS WEG',
    'KLEIN  PISCADERA',
    'KLEIN  ST MICHIEL',
    'KLEIN &nbsp;PISCADERA',
    'KLEIN &nbsp;ST MICHIEL',
    'KLEIN BLOEMHOF',
    'KLEIN BLOEMPOT',
    'KLEIN BONAIRE STEEG',
    'KLEIN CURACAO STRAAT',
    'KLEIN DAVELAAR',
    'KLEIN HOFJE',
    'KLEIN KWARTIER',
    'KLEIN KWARTIER E',
    'KLEIN KWARTIER F',
    'KLEIN KWARTIER G',
    'KLEIN KWARTIER K',
    'KLEIN MARCHENA',
    'KLEIN MARCHENA D',
    'KLEIN MARCHENA E',
    'KLEIN SANTA MARTHA N',
    'KLEIN SANTA MARTHA O',
    'KLEIN SANTA MARTHA P',
    'KLEIN SANTA MARTHA Q',
    'KLEIN SANTA MARTHA R',
    'KLEIN SANTA MARTHA S',
    'KLEIN SANTA MARTHA T',
    'KLEIN SANTA MARTHA U',
    'KLEIN SANTA MARTHA V',
    'KLEIN SINT JORIS',
    'KLEIN ST MICHIEL',
    'KLEIN STA MARTHA',
    'KLEIN STA MARTHA Y',
    'KLEIN STA ROSA',
    'KLEIN ZUURZAK',
    'KLEINE BERG',
    'KLEINE STEEG',
    'KLIP STRAAT',
    'KLOOF',
    'KNIP A',
    'KNIP B',
    'KNIP C',
    'KNIP D',
    'KOERIERS WEG',
    'KOLIBRI WEG',
    'KOLONCHI',
    'KOLONELS WEG',
    'KOLONIA',
    'KOMKOMMER WEG',
    'KONFORTABEL',
    'KONING STRAAT',
    'KONINGIN WILHELMINA STRAAT',
    'KONINGINNE LAAN',
    'KONINGSPLEIN',
    'KONOFLð',
    'KORAAL SPECHT A',
    'KORAALSPECHT WEG',
    'KORAL PARTIER',
    'KORAL PARTIER KAYA C',
    'KORAL PARTIER KAYA D',
    'KORAL SPECHT',
    'KORAL TABAK',
    'KORAN STRAAT',
    'KORPORAAL WEG',
    'KORPORAL',
    'KORTENAER STRAAT',
    'KORTIJN',
    'KORTIJN A',
    'KORTIJN WEG',
    'KOSTA FIRMA',
    'KRAAYENHOF STRAAT',
    'KRAKEEL',
    'KRALENDIJK WEG',
    'KRUISEBLOEM WEG',
    'KUDISHI',
    'KUINDER STRAAT',
    'KUIPER STRAAT',
    'KUNUKU ABOU',
    'KUNUKU DI KATUN BIJ BARBER',
    'KUNUKU DI KATUN BIJ SPAANSE WATER',
    'KUNUKU DI KATUN HATO',
    'KUNUKU GRANDI',
    'KUNUKU GRANDI K',
    'KUNUKU GRANDI P',
    'KUNUKU LARGU',
    'KURA',
    'KURA DI WAKAWA',
    'KURA PERRET',
    'KWARCHI F',
    'KWARTJE',
    'KWATTA WEG',
    'KWEN LUN WEG',
    'L.B. SMITHPLEIN',
    'LA CONCEPCION WEG',
    'LA FAMAWIJK',
    'LA GUAIRA WEG',
    'LA JOYA VILLAS',
    'LA PLATA WEG',
    'LA QUINTA VILLA RESORT',
    'LA QUINTA VILLA RESORT',
    'LABADERA',
    'LABADERASTRAAT',
    'LADRONCHI STRAAT',
    'LAGO WEG',
    'LAGUN',
    'LAGUN BLANKU',
    'LAGUN E',
    'LAGUN F',
    'LAGUN G',
    'LAGUN H',
    'LAGUN I',
    'LAGUN J',
    'LAGUN K',
    'LAGUN L',
    'LAGUN M',
    'LAGUN N',
    'LAGUN O',
    'LAGUN P',
    'LAGUN Q',
    'LAGUN R',
    'LAGUN S',
    'LAGUNILLAS WEG',
    'LAGUNISOL RESORT',
    'LAMOENCHI WEG',
    'LAMUNGRAS WEG',
    'LAND EN ZEE ZICHT',
    'LANDHUIS Brievengat',
    'LANG LEVEN',
    'LANGE STRAAT',
    'LANGE TUIN',
    'LANGULE',
    'LARAHA WEG',
    'LAS ALMAS',
    'LAUFFER STRAAT',
    'LAWA WEG',
    'LEDA WEG',
    'LEDA WEG STRAAT A',
    'LEDA WEG STRAAT C',
    'LEIDEN STRAAT',
    'LEK STRAAT',
    'LELIE WEG',
    'LELIENBERG',
    'LELIENBERG B',
    'LELIENBERG C',
    'LELIENBERG D',
    'LELIENBERG E',
    'LELIENBERG F',
    'LELIENBERG G',
    'LELIENBERG H',
    'LELIENBERG I',
    'LELIENBERG J',
    'LELIENBERG K',
    'LEONARD B. SMITH PLEIN',
    'LIBANON WEG',
    'LIBIE',
    'LIBIE D',
    'LIJSJE',
    'LINDBERGH WEG',
    'LINGE STRAAT',
    'LIVERPOOL',
    'LIVERPOOL WEG',
    'LIVINSTONE RESORT',
    'LOMBOK STRAAT',
    'LOO STRAAT',
    'LOODGIETERS WEG',
    'LOPEZ DE MORLA STRAAT',
    'LOS FRAILES WEG',
    'LOS ROQUES WEG',
    'LOUIS DE COLLIGNY LAAN',
    'LOUISE DE COLIGNYLAAN',
    'LOUISIANA WEG',
    'LUCAYAN WEG',
    'LUDUNU Y',
    'LUIS PAULA',
    'LUITENANTS WEG',
    'LUNA PARK WEG',
    'LYRA WEG',
    'MA LOUISA',
    'MAAN STRAAT',
    'MAAS STRAAT',
    'MAASTRICHT STRAAT',
    'MACURA WEG',
    'MADEIRA WEG',
    'MADURO STRAAT',
    'MADURU',
    'MAGDALENA WEG',
    'MAHAAI WEG',
    'MAHAI',
    'MAHOK STRAAT',
    'MAHUMA',
    'MAHUMA AB',
    'MAHUMA AC',
    'MAHUMA AD',
    'MAHUMA AE',
    'MAHUMA AG',
    'MAHUMA AI',
    'MAHUMA KAYA A',
    'MAHUMA KAYA B',
    'MAHUMA KAYA B',
    'MAHUMA KAYA C',
    'MAHUMA KAYA D',
    'MAHUMA KAYA D',
    'MAHUMA KAYA E',
    'MAHUMA KAYA E',
    'MAHUMA KAYA F',
    'MAHUMA KAYA F',
    'MAHUMA KAYA G',
    'MAHUMA KAYA G',
    'MAHUMA KAYA H',
    'MAHUMA KAYA H',
    'MAHUMA KAYA J',
    'MAHUMA KAYA K',
    'MAHUMA KAYA L',
    'MAHUMA KAYA M',
    'MAHUMA KAYA N',
    'MAHUMA KAYA O',
    'MAHUMA KAYA P',
    'MAHUMA KAYA P',
    'MAHUMA WEG',
    'MAINSHI WEG',
    'MAJOORS WEG',
    'MAKAPRIJN WEG',
    'MAKREE STRAAT',
    'MAKREELSTRAAT',
    'MALPAIS',
    'MAMAYA WEG',
    'MAMAYA WEG STRAAT A',
    'MAMAYA WEG STRAAT B',
    'MAMBRE',
    'MAMOTICA WEG',
    'MAMPURITU WEG',
    'MANGO WEG',
    'MANHATTAN WEG',
    'MANSALINJA',
    'MARACA WEG',
    'MARACAIBO WEG',
    'MARANON WEG',
    'MARAVIA I BISINDARIO',
    'MARCHENA',
    'MARCHENA AN',
    'MARCONI STRAAT',
    'MARGARETA',
    'MARGARITA WEG',
    'MARGRIET LAAN',
    'MARI PAMPUN',
    'MARIA BOR',
    'MARIA CHRISTINA',
    'MARIA MAGDALENA',
    'MARIA MAI',
    'MARICHI',
    'MARIE GALANTE WEG',
    'MARIE PAMPUN PLEIN',
    'MARIEDOL',
    'MARIGOT WEG',
    'MARINE BASIS PARERA',
    'MARINIERS WEG',
    'MARINIERSKAZERNE SUFFISANT',
    'MARIPOSA',
    'MARKTSTRAAT',
    'MAROWIJNE STRAAT',
    'MARS WEG',
    'MARTHA KOOSJE',
    'MARTINIQUE WEG',
    'MASBANGOE STRAAT',
    'MASKOKI WEG',
    'MATAKO WEG',
    'MATANCIA',
    'MATANCIA STRAAT',
    'MATANZAS WEG',
    'MATHEY WERF',
    'MATO GROSSO STRAAT',
    'MATROZEN WEG',
    'MAURITS LAAN',
    'MAYAGUANA WEG',
    'MAYAS WEG',
    'MAYO GUIADO SPIRITUAL',
    'MAZURCA STRAAT',
    'MECANICIENS WEG',
    'MEDEMA PARK',
    'MEEUW WEG',
    'MEHORANO WEG',
    'MEIBERG',
    'MEKKA STRAAT',
    'MENEGRANDE WEG',
    'MERCURIUS STRAAT',
    'METSELAARS WEG',
    'MEXICO WEG',
    'MGR KIECKENS WEG',
    'MGR NIEUWINDT STRAAT',
    'MGR VERRIET WEG',
    'MGR. KIECKENSWEG',
    'MGR. NIEWINDTSTRAAT',
    'MGR. VERRIETWEG',
    'MICA WEG',
    'MIDDEN STRAAT',
    'MIJN WENSCH',
    'MILON WEG',
    'MINA SCHAARBAAI C',
    'MINA SCHARBAAI',
    'MINA SCHARBAAI D',
    'MINERVA STRAAT',
    'MINGELITO',
    'MIRA WEG',
    'MIRLA PLATE E',
    'MIRLA PLATE F',
    'MIRLA PLATE WEG',
    'MISHE',
    'MISPEL WEG',
    'MISSOURI WEG',
    'MOFFI WEG',
    'MOHIKANEN WEG',
    'MOLEN PLEIN',
    'MON REPOS',
    'MON REPOS WEG',
    'MONA WEG',
    'MONCHI',
    'MONCHI AA',
    'MONCHI AB',
    'MONTAGNE &nbsp;REY D',
    'MONTAGNE REY A',
    'MONTAGNE REY B',
    'MONTANA ABOU J',
    'MONTANA ABOU KAYA D',
    'MONTANA REY C',
    'MONTANJA',
    'MONTANJA ABOU',
    'MONTANJA ABOU KAYA A',
    'MONTANJA ABOU KAYA A',
    'MONTANJA ABOU KAYA B',
    'MONTANJA ABOU KAYA C',
    'MONTANJA ABOU KAYA C',
    'MONTANJA ABOU KAYA D',
    'MONTANJA ABOU KAYA E',
    'MONTANJA ABOU KAYA F',
    'MONTANJA ABOU KAYA G',
    'MONTANJA ABOU KAYA H',
    'MONTANJA ABOU KAYA J',
    'MONTANJA ABOU KAYA K',
    'MONTANJA ABOU KAYA K',
    'MONTANJA ABOU KAYA L',
    'MONTANJA ABOU KAYA M',
    'MONTANJA ABOU KAYA M',
    'MONTANJA ABOU KAYA O',
    'MONTANJA ABOU KAYA X',
    'MONTANJA ABOU KAYA Z',
    'MONTANJA ABOU KAYA Z',
    'MONTANJA REY',
    'MONTANJA REY G',
    'MONTANJA REY H',
    'MONTANJA REY I',
    'MONTANJA REY J',
    'MONTANJA REY K',
    'MONTANJA REY L',
    'MONTE BELLO',
    'MONTE BELLO STRAAT',
    'MONTE BELLO STRAAT A',
    'MONTE CARMELO',
    'MONTE CARMELO D',
    'MONTE CARMELO H',
    'MONTE CARMELO I',
    'MONTE VERDE',
    'MONTE VERDE I',
    'MONTE VERDE J',
    'MONTE VERDE L',
    'MONTE VERDE M',
    'MONTE VERDE N',
    'MONTE VERDE O',
    'MONTE VERDE Q',
    'MONTE VERDE R',
    'MONTEURS WEG',
    'MONTEZUMA WEG',
    'MONTSERRAT WEG',
    'MOONTJE WEG',
    'MORGENSTER',
    'MORTIERS WEG',
    'MOTET WEG',
    'MOTET WERF',
    'MOTETE',
    'MOTILONES WEG',
    'MOUNT PELEE STRAAT',
    'MOUNT PLEASANT',
    'MR. JACOB BECKSTRAAT',
    'MUIZENBERG',
    'MUIZENBERG NOBO D',
    'MUNDU NOBO',
    'MUSKETIERS WEG',
    'NA BIJ STA. ROSA G',
    'NA BIJ STA. ROSA P',
    'NABIJ STA ROSA',
    'NABIJ VAN ENGELEN',
    'NABIJ VERGENOEGEN',
    'NABIJ VETTER',
    'NANI',
    'NANIE STRAAT',
    'NASSAU STRAAT',
    'NAUWE STEEG',
    'NAVAJOS WEG',
    'NEBRASKA WEG',
    'NEGROPONT',
    'NEPTUNUS WEG',
    'NESTOR WEG',
    'NEVIS WEG',
    'NICKERIE STRAAT',
    'NIEUW NEDERLAND',
    'NIEUW NEDERLAND T',
    'NIEUW NEDERLAND U',
    'NIEUW NEDERLAND V',
    'NIEUW NEDERLAND W',
    'NIEUW NEDERLAND X',
    'NIEUW NEDERLAND Y',
    'NIEUWE CARACASBAAIWEG',
    'NIEUWE FOKKER WEG',
    'NIEUWE PARERA WEG',
    'NIEUWE STRAAT',
    'NIEUWPOORT',
    'NIGER WEG',
    'NIGHTINGALE WEG',
    'NIJHOFPARK',
    'NIJL WEG',
    'NIJMEGEN STRAAT',
    'NIKIBOKO WEG',
    'NILDA PINTO STRAAT',
    'NOORD JAN THIEL',
    'NOORD KANT',
    'NOORD KUST',
    'NOORD ROOSENDAAL',
    'NOORD SALSBACH',
    'NOORD SKERPENE',
    'NOORD STA ROSA',
    'NOORD ZAPATEER',
    'NOORD ZAPATEER KAYA A',
    'NOORD ZAPATEER KAYA B',
    'NOORD ZAPATEER KAYA C',
    'NOORD ZAPATEER O',
    'NOORD ZAPATEER Q',
    'NOORDZEE STRAAT',
    'NOORWEGEN',
    'NORMANDIE',
    'NORMANDIE C',
    'NORMANDIE D',
    'NUYENS WEG',
    'OBERON STRAAT',
    'OCEAN RESORT',
    'OERAL WEG',
    'OHM STRAAT',
    'OJANA WEG',
    'OKLAHOMA WEG',
    'OLEANDER WEG',
    'OLIJFI STRAAT',
    'OLINDA WEG',
    'OLYMPUS WEG',
    'ONA WEG',
    'ONBEKENDE PLAATS/ADRES',
    'ONEIDA WEG',
    'ONIMA WEG',
    'ONTARIO WEG',
    'ONVERWACHT',
    'ONVERWACHT K',
    'ONVERWACHT N',
    'ONVERWACHT S',
    'OOST JONGBLOED',
    'OOST JONGBLOED WEG',
    'OOST STA ROSA',
    'OOST STRAAT',
    'OOSTBURG STRAAT',
    'OOSTERBEEK STRAAT',
    'OOSTPUNT',
    'ORANJE BERG',
    'ORANJE NASSAU LAAN',
    'ORANJE STRAAT',
    'ORCHILLA WEG',
    'ORDONNANS WEG',
    'OREGANO WEG',
    'ORHILLAWEG',
    'ORINOCO WEG',
    'ORION WEG',
    'OSSAGAS WEG',
    'OTRABANDA',
    'OTRABANDA D',
    'OTROBANDA D',
    'OUDE CARACASBAAI WEG',
    'OUDE NIJL WEG',
    'OUDE WATER',
    'OUDE WEG NAAR BULLENBAAI',
    'PALAS STRAAT',
    'PALLASSTRAAT',
    'PALU BLANKU',
    'PAMIR WEG',
    'PAMPANO STRAAT',
    'PAMPUNA WEG',
    'PANAMA',
    'PANAMA WEG',
    'PANNEKOEK',
    'PANNEKOEK AJ',
    'PANNEKOEK AK',
    'PANNEKOEK AL',
    'PANNEKOEK J',
    'PANNEKOEK L',
    'PANNEKOEK M',
    'PANNEKOEK N',
    'PANNEKOEK O',
    'PANNEKOEK T',
    'PANORAMA WEG',
    'PAPAGAYO BEACH RESORT',
    'PAPAGAYO RESORT AB',
    'PAPAYA BIJ GROTE BERG',
    'PAPAYA BIJ WESTPUNT',
    'PAPAYA WEG',
    'PARADA DI BÚS EVARISTO "VA" PETRUS',
    'PARADA DI BUS EVARISTO D. VA PETRUS',
    'PARADERA',
    'PARADIJS',
    'PARADIJS H',
    'PARADIJS I',
    'PARADIJS J',
    'PARADIJS O',
    'PARADIJS S',
    'PARAGUANA WEG',
    'PARAGUAY WEG',
    'PARAGUAY WEG',
    'PARALLEL WEG',
    'PARAMARIBO WEG',
    'PARANA WEG',
    'PARASASA',
    'PARASASA Q',
    'PARASASA R',
    'PARERA',
    'PARERA WEG',
    'PARERAWIJK',
    'PARIMA WEG',
    'PARIS',
    'PARK WEG',
    'PARKE CIRO GALLOPPI',
    'PARKIETEN WEG',
    'PARMANTIERS WEG',
    'PASENSHI',
    'PASENSHI WEG',
    'PASEO ATLANTICO',
    'PASEO KORAALSPECHT',
    'PASEO MEDITERRANEO',
    'PASEO PACIFICO',
    'PASSAAT STRAAT',
    'PASTOOR BLOMMERDEWEG',
    'PASTOOR VAN KOOLWIJKWEG',
    'PASTOR BLOMMERDE WEG',
    'PASTOR VAN KOOLWIJK WEG',
    'PATAGONIE WEG',
    'PATER EEUWENS WEG',
    'PATER EUWENSWEG',
    'PATER L. JANSENSTRAAT',
    'PATER LUDOVICUS JANSEN STRAAT',
    'PATER POEISZ STRAAT',
    'PATER POIESZSTRAAT',
    'PATIA WEG',
    'PATRICK',
    'PATRIJS WEG',
    'PAWNIS WEG',
    'PELIKAAN WEG',
    'PEN',
    'PENELOPE WEG',
    'PENSTRAAT',
    'PERGAMA WEG',
    'PERICLES STRAAT',
    'PERNIS STRAAT',
    'PERSEUS WEG',
    'PERU WEG',
    'PEST BAAI',
    'PETROLEUM WEG',
    'PHILIPSBURG WEG',
    'PHOENIX WEG',
    'PICOE STRAAT',
    'PICUSSTRAAT',
    'PIER MARI PAMPOEN',
    'PIERRE LE GRAND STRAAT',
    'PIET HEIN STRAAT',
    'PIET KEGEL STRAAT',
    'PIETERMAAI',
    'PIETERMAAI PLEIN',
    'PIETERMAAI WEG',
    'PIETERSZ STEEG',
    'PIJPFITTERS WEG',
    'PIMAS WEG',
    'PIMPIRI WEG',
    'PINDUS WEG',
    'PINOS WEG',
    'PISCADERA BAAI',
    'PISCADERA BAAI A',
    'PISCADERA BAAI D',
    'PISCADERA BAY RESORT A',
    'PISCADERA BAY RESORT B',
    'PISCADERA BAY RESORT C',
    'PISCADERA BAY RESORT D',
    'PISCADERA BAY RESORT E',
    'PISCADERA BAY RESORT F',
    'PISCADERA BAY RESORT G',
    'PISCADERA BAY RESORT H',
    'PISCADERA BAY RESORT I',
    'PISCADERA BAY RESORT J',
    'PISCADERA BAY RESORT K',
    'PISCADERA BAY RESORT L',
    'PISCADERA BAY RESORT M',
    'PISCADERA BAY RESORT N',
    'PISCADERA BAY RESORT O',
    'PISCADERA BAY RESORT P',
    'PISCADERA BERDE',
    'PISCADERA BERDE D',
    'PISCADERA WEG',
    'PISKADERA BAAI',
    'PISKECHI STRAAT',
    'PLANTAGE WEG',
    'PLANTERSRUST',
    'PLASA A. OBED ANTHONY',
    'PLASA ANGEL JOB',
    'PLASA CESARIO A. CAMELIA',
    'PLASA DI FLOR',
    'PLASA DI PAS',
    'PLASA DJERI PALM',
    'PLASA Dr. C.(Boy) WINKEL',
    'PLASA Dr. COLA DEBROT',
    'PLASA FRATERNAN DI TILBURG',
    'PLASA HARIRI',
    'PLASA HENDRIK G. M. PIETERS-KWIERS',
    'PLASA HORACIO HOYER',
    'PLASA JOJO CORREA',
    'PLASA JONGE WACHT',
    'PLASA Mgr. JULIO HENRIQUEZ',
    'PLASA MORDY MADURO',
    'PLASA MUNDO MERCED',
    'PLASA PIAR',
    'PLASA REGGY H. VENLOO',
    'PLASA ROBERTO H. EUSTATIUS',
    'PLASA SHON TIN ZIMMERMAN',
    'PLASA SIMON BOLIVAR',
    'PLASA SMEETS',
    'PLASA SYLVIUS G.M. "BOY" ROZENDAL',
    "PLAY'I SHON",
    'PLAYA ABOU',
    'PLAYA BOCA SAMI',
    'PLAYA BOCA SAMI',
    'PLAYA BOCA SIMON',
    'PLAYA BOTO',
    'PLAYA CANOA',
    'PLAYA CHIKI',
    'PLAYA FORTI',
    'PLAYA GRANDI',
    'PLAYA HUNDU',
    'PLAYA HUNKU',
    'PLAYA JEREMI',
    'PLAYA KALKI',
    'PLAYA KAS ABOA',
    'PLAYA KENEPA',
    'PLAYA KENEPA CHIKI',
    'PLAYA KENEPA GRANDI',
    'PLAYA LAGUN',
    'PLAYA LARGU',
    'PLAYA MANSALI¥A',
    'PLAYA STA KRUS',
    'PLAZA HENDRIK G.M. PIETERS KWIERS',
    'PLAZA JOJO CORREA',
    'PLAZA MARGARET ABRAHAM',
    'PLAZA Mr. Dr. M.F. DA COSTA GOMEZ',
    'PLAZA MUNDO MERCED',
    'PLENCHI DI TRAFIKO ZEGU',
    'PLENCHI SHON TIN ZIMMERMAN',
    'PLENCHI TRÁFIKO H. E. MR. RAFIC HARIRI',
    'PLETTERIJ WEG',
    'PLETTERIJWEGOOST',
    'PLUTO STRAAT',
    'POERARI STRAAT',
    'POLARIS WEG',
    'POLKA STRAAT',
    'POLLUX WEG',
    'PONCA WEG',
    'POPO',
    'POPO A',
    'POPO B',
    'POPO C',
    'POPO D',
    'POPO K',
    'PORTO MARI',
    'PORTO RICOWEG',
    'PORTOMARIE BAAI',
    'PORTUGEZEN WEG',
    'POS CABAI C',
    'POS CABAI WEG',
    'POS DI MARIE HANCHI',
    'POS DI WANGA',
    'POS KABAI',
    'POS SALU',
    'POS SPA¥O',
    'POSEIDON STRAAT',
    'PRAIRIE WEG',
    'PRESIDENTE ROMULO BETANCOURT BOULEVARD',
    'PRETU',
    'PRETU BIJ DE SAVAAN N',
    'PRINSEN LAAN',
    'PRINSEN STRAAT',
    'PRINSES MAGRIET LAAN',
    'PRINSES MAGRIET PLEIN',
    'PRINSES MARGRIETPLEIN',
    'PRINSESSE LAAN',
    'PROF. KERNKAMPWEG',
    'PROF. KNAPPERTWEG',
    'PROFESOR KERNKAMP WEG',
    'PROFESOR KNAPPERT WEG',
    'PROVIDENCIA WEG',
    'PUEBLO WEG',
    'PUERTO CABELLO WEG',
    'PUERTO RICO WEG',
    'PUNDA',
    'PUNDA A',
    'PUNDA B',
    'PUNDA C',
    'PUNDA E',
    'PUNTA CARDON WEG',
    'PUNTO MATEO',
    'PUTTEN STRAAT',
    'QUADRILLASTRAAT',
    'QUARANTAINE WEG',
    'QUIL STRAAT',
    'QUILLSTRAAT',
    'QUINTA LEONOR',
    'QUINTA STRAAT',
    'QUINTA VIOLETA',
    'RAFAEL',
    'RANCHO',
    'RANCHO E',
    'RANCHO I',
    'RANCHO J',
    'RANCHO M',
    'RANCHO Q',
    'RANCHO T',
    'RECOMPENSA E',
    'RECOMPENSA F',
    'RECTOR ZWIJSEN STRAAT',
    'REDA WEG',
    'REF',
    'REGENTESSE LAAN',
    'REGGE STRAAT',
    'REHDERSLUST',
    'REIGER WEG',
    'REKOMPENSA',
    'REMBRANDT STRAAT',
    'RENKUM STRAAT',
    'RESEDA WEG',
    'RESIDENSIA PALMA REAL',
    'REUZEN STEEG',
    'RHEA STRAAT',
    'RIALTO STRAAT',
    'RIF CORAL ESTATE K',
    'RIF CORAL ESTATE L',
    'RIF CORAL ESTATE M',
    'RIF CORAL ESTATE N',
    'RIF CORAL ESTATE O',
    'RIF CORAL ESTATE P',
    'RIF CORAL ESTATE Q',
    'RIF CORAL ESTATE R',
    'RIF CORAL ESTATE S',
    'RIF CORAL ESTATE T',
    'RIF CORAL ESTATE U',
    'RIF CORAL ESTATE V',
    'RIF ST MARIE',
    'RIFFORT',
    'RIFWATER STRAAT',
    'RIGEL WEG',
    'RIJKENBERG',
    'RIJKSEENHEID BOULEVARD',
    'RIJN STRAAT',
    'RINCON WEG',
    'RIO CANARIO',
    'RIO CANARIO WEG',
    'RIO MAGDALENA',
    'RIO MAGDALENA AG',
    'RIOUW STRAAT',
    'ROATAN WEG',
    'ROBEKKI STRAAT',
    'ROER STRAAT',
    'ROERMOND STRAAT',
    'ROI DOMI',
    'ROI INDJAN',
    'ROI KATOCHI',
    'ROMANGEL WEG',
    'RONDE KLIP',
    'RONDE KLIP A',
    'RONDE KLIP B',
    'RONDE KLIP C',
    'RONDE KLIP D',
    'RONDE KLIP E',
    'RONDE KLIP F',
    'RONDE KLIP G',
    'RONDE KLIP H',
    'RONDE KLIP I',
    'RONDE KLIP J',
    'RONDE KLIP WEG',
    'RONDE WEG',
    'RONDEKLIP ZUID R',
    'RONDEKLIP ZUID S',
    'RONTGEN STRAAT',
    'ROODE STEEG',
    'ROODE WEG',
    'ROOI CATOOTJE A',
    'ROOI CATOOTJE WEG',
    'ROOI SANTU',
    'ROOSENDAAL',
    'ROOSENDAAL WEG',
    'RORAIMA WEG',
    'ROTONDE CARACASBAAI',
    'ROTÒNDE J. M. G. "JUANCHO" EVERTSZ',
    'ROTONDE JUANCHO EVERTSZ',
    'ROTONDE LUCINA E. DA COSTA GOMEZ-MATHEEUWS',
    'ROTONDE PALU BLANKU',
    'ROTONDE PISACDERA',
    'ROTONDE PISCADERA',
    'ROTONDE ROBERTO BETO ADAMUS',
    'ROTONDE ZEGU',
    'ROTONDO PALU BANKU',
    'ROTTERDAM WEG',
    'ROYAL GARDEN RESORT',
    'ROYAL PALM RESORT',
    'ROYAL PALM RESORT S',
    'ROYAL PALM RESORT U',
    'ROYAL PALM RESORT V',
    'ROZEN WEG',
    'ROZENBURG',
    'RUBI WEG',
    'RUST',
    'RUST EN BURGH',
    'RUST EN BURGH LAAN',
    'RUST EN PAD',
    'RUST EN PAD AA',
    'RUST EN PAD AB',
    'RUST EN PAD AC',
    'RUST EN PAD AD',
    'RUST EN PAD AE',
    'RUST EN PAD Z',
    'RUST EN VREDE BIJ BRIEVENGAT',
    'RUST EN VREDE BIJ MINGELITU',
    'RUST EN VREDE BIJ TUCACAS',
    'RUST IN VREDE BIJ SAN HERONIMO',
    'RUTHERFORD STRAAT',
    'SABA STEEG',
    'SABANA BAKA',
    'SABANA CRAZ',
    'SABANA HUNDU',
    'SABANA HUNDU B',
    'SABANA HUNDU C',
    'SABANA HUNDU D',
    'SABANA HUNDU R',
    'SABANA LARGU',
    'SABANA WESTPUNT',
    'SABEL STRAAT',
    'SAFFIER WEG',
    'SALIÑA ABAOSTRAAT A',
    'SALIÑA ABOU STRAAT B',
    'SALINJA',
    'SALINJA ABAU STRAAT A',
    'SALINJA ABOU',
    'SALINJA ABOU STRAAT B',
    'SALINJA ARIBA',
    'SALINJA HARBOUR VIEW',
    'SALSBACH',
    'SALSBACH E',
    'SALSBACH NOORD E',
    'SALSBACH WEG',
    'SALSBACH WEG STRAAT A',
    'SALUMA WEG',
    'SAMANA WEG',
    'SAMI LIBER',
    'SAMI LIBER U',
    'SAMI LIBER V',
    'SAMI LIBER W',
    'SAN DOMINGO',
    'SAN FUEGO',
    'SAN FUEGO WEG',
    'SAN HERONIMO',
    'SAN JUAN',
    'SAN JUAN BAAI',
    'SAN LORENZO WEG',
    'SAN MANGO',
    'SAN MATEOWIJK',
    'SAN NICOLAS W',
    'SAN NICOLAS WEG',
    'SAN NIKOLAS',
    'SAN PEDRO',
    'SAN PEDRO V',
    'SAN RAFAEL',
    'SAN SEBASTIAAN',
    'SAN SOUCI B',
    'SAN SOUCI C',
    'SAN SOUCI D',
    'SAN SOUCI E',
    'SAN SOUCI F',
    'SAN SOUCI G',
    'SAN SOUCI H',
    'SAN SOUCI I',
    'SAN SOUCI J',
    'SAN SOUCI K',
    'SAN SOUCI V',
    'SAN WILIBRORDO',
    'SAND RIVER PARK',
    'SANMATEO WIJK B',
    'SANS SOUCI',
    'SANTA BARBARA',
    'SANTA BARBARA R',
    'SANTA BARBARA S',
    'SANTA BARBARA T',
    'SANTA BARBARA U',
    'SANTA CRUZ',
    'SANTA ELENA',
    'SANTA INESIA',
    'SANTA MARIA',
    'SANTA MARTHA',
    'SANTA MARTHA BAAI',
    'SANTA ROSA',
    'SANTA ROSA',
    'SANTA ROSA WEG',
    'SANTA TERESA',
    'SANTO DOMINGOWEG',
    'SAONA WEG',
    'SAPATE',
    'SARAMACCA STRAAT',
    'SARDINE STEEG',
    'SATURNUS STRAAT',
    'SAUCO WEG',
    'SAVONET',
    'SCALA WEG',
    'SCHALIE WEG',
    'SCHARLOO',
    'SCHARLOO WEG',
    'SCHELDE STRAAT',
    'SCHELPWIJK',
    'SCHERPENHEUVEL',
    'SCHERPENHEUVEL H',
    'SCHERPENHEUVEL I',
    'SCHERPENHEUVEL L',
    'SCHERPENHEUVEL M',
    'SCHERPENHEUVEL N',
    'SCHERPENHEUVEL O',
    'SCHEVENINGEN STRAAT',
    'SCHIPBEEK STRAAT',
    'SCHOENMAKER STRAAT',
    'SCHONEGEVEL STRAAT',
    'SCHOOL STRAAT',
    'SCHOONE STEEG',
    'SCHOONEBOOM',
    'SCHOTTEGAT WEG NOORD',
    'SCHOTTEGAT WEG OOST',
    'SCHOTTEGAT WEG WEST',
    'SCHOTTEGAT WEG ZUID',
    'SCHOUT BIJ NACHT DOORMAN WEG',
    'SCHOUT BIJ NACHT VAN STAVEREN WEG',
    'SCHOUWBURG WEG',
    'SCHRIJNWERKER STRAAT',
    'SCHRIJNWERKERSSTRAAT',
    'SCHUBAPPEL WEG',
    'SCHUTTERS WEG',
    'SEBASTOPOL STRAAT',
    'SELINDA',
    'SELINDAWEG',
    'SEMI KOK',
    'SEMIKOK V',
    'SEMINOLEN',
    'SEMINOLEN WEG',
    'SENEBLA WEG',
    'SENEKA WEG',
    'SENIA TERESA',
    "SER'I BIENTU",
    "SER'I COCORI",
    "SER'I DOMI",
    "SER'I FORTUNA",
    "SER'I HULANDA",
    "SER'I KANDELA",
    "SER'I KANDELA R",
    "SER'I KANDELA S",
    "SER'I LORA",
    "SER'I MAHUMA",
    "SER'I MANGUSA",
    "SER'I MULINA",
    "SER'I PAPAYA",
    "SER'I PAPAYA F",
    "SER'I PIETERMAAI",
    "SER'I PLASA",
    "SER'I SIBOYO",
    "SER'I SKALO",
    "SER'I ULANDA",
    'SERANO STRAAT',
    'SERGEANTEN WEG',
    'SERI DOMI E',
    'SERI KANDELA KAYA B',
    'SERI KANDELA KAYA P',
    'SERITU',
    'SERU BLANCO',
    'SERU BOKA ESTATE',
    'SERU CHIKI',
    'SERU FORTUNA',
    'SERU FORTUNA E',
    'SERU FORTUNA H',
    'SERU FORTUNA I',
    'SERU FORTUNA WEG',
    'SERU FRANCES',
    'SERU GRANDI',
    'SERU KANDELA KAYA A',
    'SERU KANDELA KAYA B',
    'SERU KANDELA KAYA C',
    'SERU KANDELA KAYA D',
    'SERU KANDELA KAYA E',
    'SERU KANDELA KAYA F',
    'SERU KANDELA KAYA G',
    'SERU KANDELA KAYA H',
    'SERU KANDELA KAYA J',
    'SERU KANDELA KAYA K',
    'SERU KANDELA KAYA L',
    'SERU KANDELA KAYA M',
    'SERU KANDELA KAYA N',
    'SERU KANDELA KAYA O',
    'SERU KANDELA KAYA Q',
    'SERU KANDELA KAYA R',
    'SERU KANDELA KAYA S',
    'SERU KANDELA KAYA T',
    'SERU KANDELA KAYA U',
    'SERU KANDELA KAYA V',
    'SERU KANDELA KAYA W',
    'SERU LORA',
    'SERU LORA WEG',
    'SERU MACHU',
    'SERU MANGASINA, KAYA',
    'SERU MILON',
    'SERU PRETU BIJ JAN LUIS',
    'SERU PRETU bij JAN LUIS F',
    'SERU PRETU BIJ WISHI',
    'SEVILLAAN STRAAT',
    'SHA CAPRILESKADE',
    'SHA I LIO CAPRILESKADE',
    'SHASTA WEG',
    'SHAWNEE WEG',
    'SHELL WEG',
    'SHETE SERU',
    'SHIMARUKU WEG',
    'SHOOT',
    'SIBERI',
    'SIERRA MADRE WEG',
    'SIMON STEVIN STRAAT',
    'SIN PENSAR',
    'SINIA THERESA',
    'SINIA THERESA A',
    'SINIA THERESA C',
    'SINIA THERESA D',
    'SINT HELENASTRAAT A',
    'SINT JACOB',
    'SINT JAGO',
    'SINT JAGO WEG',
    'SINT JAGOBERG',
    'SINT JANSBERG',
    'SINT JORIS',
    'SINT JORIS BAAI',
    'SINT JOZEFSDAL',
    'SINT MICHIELS BAAI',
    'SIOUX WEG',
    'SIRIUS WEG',
    'SISAL STRAAT',
    'SITTARD STRAAT',
    'SLANGENBAAI',
    'SLOKE WEG',
    'SMALLE STEEG',
    'SNIP WEG',
    'SOCRATES STRAAT',
    'SOENDA STEEG',
    'SOEUR GERARDA WEG',
    'SOLDATEN WEG',
    'SOLEDAD BIJ THORENQUEST E',
    'SOLEDAD BIJ THORENQUEST G',
    'SOLEDAT BIJ CHANGO',
    'SOLEDAT BIJ FLIP',
    'SOLEDAT BIJ JAN BOOS',
    'SOLEDAT BIJ THORENQUEST',
    'SOMBRERO WEG',
    'SOMMELSDIJK STRAAT',
    'SORSACA WEG',
    'SORSAKA',
    'SOSIEGU BIJ MONTANJA',
    'SOSIEGU BIJ RANCHO',
    'SOSIEGU BIJ WATERLOO',
    'SOSIEGU BIJ WATERLOO K',
    'SOSIEGU BIJ WATERLOO L',
    'SOSIEGU BIJ WATERLOO O',
    'SOTO',
    'SOTO E',
    'SOTO F',
    'SOTO G',
    'SOTO I',
    'SOTO J',
    'SOTO K',
    'SOTO L',
    'SOTO M',
    'SOUAX',
    'SOUAX ARIBA',
    'SOUAX OOST',
    'SOUAX T',
    'SOUAX U',
    'SOUAX V',
    'SOUAX W',
    'SOUAX WEST',
    'SOUAX WEST KAYA AA',
    'SOUAX WEST KAYA C',
    'SOUAX WEST KAYA EE',
    'SOUAX WEST KAYA O',
    'SOUAX- WEST Y',
    'SOUAX-WEST KAYA I',
    'SOUAX-WEST KAYA J',
    'SPAANSCHE STEEG',
    'SPAANSE BAAI',
    'SPAANSE PUT',
    'SPAANSE WATER',
    'SPAANSE WATER',
    'SPAARNE STRAAT',
    'SPARREN WEG',
    'SPORT LAAN',
    'ST ANNA WEG',
    'ST BARTHS WEG',
    'ST CROIX WEG',
    'ST EUSTATIUS STEEG',
    'ST JANSBERG M',
    'ST JOHNS WEG',
    'ST JORISBAAI',
    'ST KITTS WEG',
    'ST LAWRENCE WEG',
    'ST LUCIA WEG',
    'ST MARTIN STEEG',
    'ST MARTINUS STRAAT',
    'ST MICHIELS WEG',
    'ST THOMAS',
    'ST THOMAS WEG',
    'ST VINCENT WEG',
    'ST WILIBRORDUS',
    'ST. ANNAWEG',
    'ST. BARTHSWEG',
    'ST. CROIXWEG',
    'ST. JACOB A',
    'ST. JACOB B',
    'ST. JACOB C',
    'ST. JACOB E',
    'ST. JAGO B',
    'ST. JAGO C',
    'ST. JAGO D',
    'ST. JAGO E',
    'ST. JANSBERG N',
    'ST. JOHNSWEG',
    'ST. KITTSWEG',
    'ST. LAWRENCEWEG',
    'ST. LUCIAWEG',
    'ST. MARTINSTEEG',
    'ST. MICHIELSWEG',
    'ST. VINCENTWEG',
    'ST. WILLIBRODUS D',
    'ST. WILLIBRODUS E',
    'ST. WILLIBRODUS F',
    'ST. WILLIBRODUS G',
    'ST. WILLIBRODUS H',
    'ST. WILLIBRODUS I',
    'ST. WILLIBRODUS J',
    'STA BARBARA',
    'STA CATHARINA',
    'STA CRUZ D',
    'STA HELENA STRAAT',
    'STA KRUS BAAI',
    'STA ROSA',
    'STA ROSA WEG',
    'STA. CATHARINA O',
    'STA. MARIA A',
    'STA. MARIA B',
    'STA. MARIA C',
    'STA. ROSA H',
    'STAKAMAHACHI WEG',
    'STANDARD WEG',
    'STEEN KORAAL KAYA B',
    'STEEN KORAAL KAYA C',
    'STEEN KORAAL KAYA E',
    'STEEN KORAAL KAYA F',
    'STEEN KORAAL KAYA G',
    'STEEN KORAAL KAYA I',
    'STEEN KORAAL KAYA J',
    'STEENGROEVE STRAAT',
    'STEENKOOL WEG',
    'STEENMIJN',
    'STEENMIJN WEG',
    'STEENRIJK',
    'STEENRIJK PLEIN',
    'STEENRIJK STRAAT',
    'STEENWIJK',
    'STENEN KORAAL',
    'STENEN KORAAL F',
    'STENEN KORAAL G',
    'STENEN KORAAL H',
    'STENEN KORAAL KAYA A',
    'STENEN KORAAL KAYA B',
    'STENEN KORAAL KAYA C',
    'STENEN KORAAL KAYA D',
    'STENEN KORAAL KAYA E',
    'STENEN KORAAL KAYA F',
    'STENEN KORAAL KAYA G',
    'STENEN KORAAL KAYA H',
    'STENEN KORAAL KAYA J',
    'STENEN KORAAL KAYA K',
    'STENEN KORAAL KAYA L',
    'STENEN KORAAL KAYA M',
    'STENEN KORAAL KAYA N',
    'STENEN KORAAL KAYA O',
    'STENEN KORAAL KAYA P',
    'STENEN KORAAL KAYA Q',
    'STENEN KORAAL KAYA R',
    'STENEN KORAAL KAYA S',
    'STENEN KORAAL KAYA T',
    'STENEN KORAAL KAYA U',
    'STENEN KORAAL KAYA V',
    'STENEN KORAAL KAYA W',
    'STENEN KORAAL L',
    'STENEN KORAAL M',
    'STO. DOMINGOWEG',
    'STOLBERG LAAN',
    'STOPPEL WEG',
    'STRIEBEEK',
    'STROO STEEG',
    'STUYVESANT STRAAT',
    'SUCASA',
    'SUCASA N',
    'SUCASA O',
    'SUCASA P',
    'SUCASA Q',
    'SUCASA R',
    'SUCASA S',
    'SUCU WEG',
    'SUDETEN WEG',
    'SUFFISANT',
    'SUFFISANT B',
    'SUFFISANT C',
    'SUFFISANT G',
    'SUFFISANT H',
    'SUFFISANT WEG',
    'SUFFISANTDORP',
    'SUIKERTUINTJE WEG',
    'SUJEEWASTRAAT',
    'SUKASA',
    'SUMATRA STRAAT',
    'SUMBU',
    'SUMBU A',
    'SUMBU H',
    'SUMBU I',
    'SUMBU J',
    'SUN VALLEY',
    'SUNSET HEIGHT',
    'SUPAI WEG',
    'SURINAME STEEG',
    'SWAMMERDAM STRAAT',
    'SWAN WEG',
    'TAFEL STEEG',
    'TAMANACO STRAAT',
    'TAMARIJN WEG',
    'TAMPANJA WEG',
    'TANCHI WEG',
    'TANOS WEG',
    'TEBENK WEG',
    'TELAMON STRAAT',
    'TERA CORA X',
    'TERA KORA',
    'TERA PRETU',
    'TERA PRETU A',
    'THALIA WEG',
    'THE BOTTOM WEG',
    'THEATER STRAAT',
    'THERESA',
    'THORENQUEST',
    'THORENQUEST E',
    'THORENQUEST F',
    'THORENQUEST G',
    'TIA JUANA WEG',
    'TIEL STRAAT',
    'TIMMERMANS WEG',
    'TIMOR WEG',
    'TIMOTEAN WEG',
    'TIN WEG',
    'TIN WEG STRAAT A',
    'TINTAMARRE WEG',
    'TITAN STRAAT',
    'TJERK HIDDES LAAN',
    'TOBAGO WEG',
    'TOCANTINO WEG',
    'TOLTEKEN WEG',
    'TOMATI WEG',
    'TONI KUNCHI',
    'TONINO STRAAT',
    'TOPAAS WEG',
    'TOPEKA WEG',
    'TORTOLA WEG',
    'TORTUGA WEG',
    'TOTOLIKA WEG',
    'TOTONAKEN WEG',
    'TRAI SERU',
    'TRAI WEG',
    'TRAP STEEG',
    'TRIANGEL WEG',
    'TRINIDAD WEG',
    'TRITON STRAAT',
    'TROEPIAAL WEG',
    'TROMMELSTOK WEG',
    'TROMP STRAAT',
    'TROMPET STEEG',
    'TROMPETBLOEM WEG',
    'TUCACAS',
    'TULPENBOOM STRAAT',
    'TUMBA STRAAT',
    'TUPIS WEG',
    'TURKS WEG',
    'TURNEFFE WEG',
    'UMATILLA WEG',
    'UN BOKA',
    'URANUS STRAAT',
    'URANUSWEG',
    'URDAL',
    'URDAL WEG',
    'URUGUAY WEG',
    'UTE WEG',
    'UTILA WEG',
    'VAANDRIGWEG',
    'VADER CIJNTJE',
    'VAERSENBAAI',
    'VALENTIJN',
    'VALENTIJNSBAAI',
    'VALKEN WEG',
    'VAN BEEK STRAAT',
    'VAN BEUNINGEN STRAAT',
    'VAN BRAKEL LAAN',
    'VAN DE BRANDHOF STRAAT',
    'VAN DE ZAAN LAAN',
    'VAN DER STOK STRAAT',
    'VAN DIJK STEEG',
    'VAN ENGELEN',
    'VAN ENGELEN WEG',
    'VAN EYCK VAN  VOORTHUYZEN WEG',
    'VAN EYCK VAN &nbsp;VOORTHUYZEN WEG',
    'VAN GALEN LAAN',
    'VAN GHENT LAAN',
    'VAN GOGH STRAAT',
    'VAN HEERD STRAAT',
    'VAN KINGSBERG LAAN',
    'VAN KINSBERGLAAN',
    'VAN KRIMPEN LAAN',
    'VAN LEEUWENHOEK STRAAT',
    'VAN LENNEP STRAAT',
    'VAN NES LAAN',
    'VAN RADER STRAAT',
    'VAN SPEYK STRAAT',
    'VAN SWIETEN STRAAT',
    'VAN WALBEECK STEEG',
    'VECHT STRAAT',
    'VEERIS',
    'VEERIS &nbsp;F',
    'VEERIS A',
    'VEERIS AA',
    'VEERIS C',
    'VEERIS E',
    'VEERIS G',
    'VEERIS WEG',
    'VELD WEG',
    'VELP STRAAT',
    'VENEZUELA WEG',
    'VENLO STRAAT',
    'VENUS STRAAT',
    'VERGENOEGEN BIJ BRIEVENGAT',
    'VERGENOEGEN BIJ VAN ENGELEN',
    'VERGENOEGING Z',
    'VERS J',
    'VERS K',
    'VERS L',
    'VERS M',
    'VERS O',
    'VERSAILLES WEG',
    'VESUVIUS STRAAT',
    'VETTER',
    'VIANEN',
    'VILAPARK ZUURZAK',
    'VILLA PARK BARIKA',
    'VILLA PARK GIROUETTE',
    'VIRGINIA WEG',
    'VISCH STEEG',
    'VISTA ROYAL',
    'VISTA ROYAL V',
    'VISTA ROYAL X',
    'VLIERSTEEG',
    'VLIET STRAAT',
    'VLISSINGEN STRAAT',
    'VOGEZEN WEG',
    'VOLKSBOND STRAAT',
    'VOLKSWONING WESTPUNT',
    'VOLTA STRAAT',
    'VONDEL STRAAT',
    'VREDEBERG BIJ JAN SOFAT',
    'VREDEBERG BIJ STA MARIA',
    'VREDEBERG PLEIN',
    'VREDELUST',
    'VREDENBERG BIJ KINTJAN',
    'VRIJE ZONE',
    'VRIJGEZELLEN BAAI',
    'VUURLAND WEG',
    'W. KERCKRINCK STRAAT',
    'W.I. COMPAGNIESTRAAT',
    'WAAIGAT PLEIN',
    'WAAIGAT STEEG',
    'WAAL STRAAT',
    'WACAO A',
    'WAGEN STEEG',
    'WAGENINGEN PLEIN',
    'WAGENINGEN STRAAT',
    'WAGNER STRAAT',
    'WAKAWA',
    'WALCHEREN STRAAT',
    'WANAPA',
    'WANAPA WEG',
    'WARAHAMA WEG',
    'WARAWARA WEG',
    'WARMOES WEG',
    'WASTUIN',
    'WATAKELI WEG',
    'WATAMULA',
    'WATER STEEG',
    'WATERFORT STRAAT',
    'WATERLOO',
    'WATERLOO R',
    'WATLING WEG',
    'WAWU BIJ LUDUNU V',
    'WAWU BIJ LUNDUNU S',
    'WAYACA STRAAT',
    'WECHI',
    'WECOEWA WEG',
    'WEG NAAR BAPOR KIBRA',
    'WEG NAAR BATIPANJA',
    'WEG NAAR BULLENBAAI',
    'WEG NAAR BULLENBAAI',
    'WEG NAAR FUIK',
    'WEG NAAR HATO',
    'WEG NAAR JULIANA DORP',
    'WEG NAAR LAGUN',
    'WEG NAAR MONTANJA',
    'WEG NAAR MONTANJA BIJ JAN BOOS',
    'WEG NAAR OUDE BULLENBAAI',
    'WEG NAAR PLAYA CANOA',
    'WEG NAAR POS SALU',
    'WEG NAAR RETONDE ZEGU',
    'WEG NAAR ROOI SANTU',
    'WEG NAAR ROOI SANTU',
    'WEG NAAR SOTO',
    'WEG NAAR STA CATHARINA',
    'WEG NAAR STA CRUS',
    'WEG NAAR WELGELEGEN',
    'WEG NAAR WESTPUNT',
    'WEG NAAR WESTPUNT BIJ BARBER',
    'WEG NAAR WESTPUNT BIJ DANIEL',
    'WEG NAAR WESTPUNT BIJ GATU',
    'WEG NAAR WESTPUNT BIJ GROTE BERG',
    'WEG NAAR WESTPUNT BIJ JUAN DOMINGO',
    'WEG NAAR WESTPUNT BIJ PAPAYA',
    'WEG NAAR WESTPUNT BIJ SOUAX',
    'WEG NAAR WESTPUNT BIJ TERA CORA',
    'WEG NAAR WESTPUNT BIJ WACAWA',
    'WEG NAAR WESTPUNT BIJ WESTPUNT',
    'WEIS',
    'WEIS AFO',
    'WELATINA',
    'WELATINA WEG',
    'WELBEDACHT',
    'WELENSALI WEG',
    'WELGELEGEN BIJ HABAAI',
    'WELGELEGEN BIJ MONTANJA',
    'WERF DE WILDE',
    'WERF STRAAT',
    'WEST GROOT ST JORIS',
    'WEST INDISCHE COMPAGNIE STRAAT',
    'WEST KAS GRANDI',
    'WEST RONDE KLIP',
    'WEST STEEG',
    'WESTKAPELLE STRAAT',
    'WESTPUNT',
    'WESTPUNT AA',
    'WESTPUNT AB',
    'WESTPUNT B',
    'WESTPUNT C',
    'WESTPUNT D',
    'WESTPUNT E',
    'WESTPUNT F',
    'WESTPUNT G',
    'WESTPUNT H',
    'WESTPUNT I',
    'WESTPUNT J',
    'WESTPUNT K',
    'WESTPUNT L',
    'WESTPUNT M',
    'WESTPUNT N',
    'WESTPUNT O',
    'WESTPUNT P',
    'WESTPUNT Q',
    'WESTPUNT R',
    'WESTPUNT S',
    'WESTPUNT T',
    'WETO',
    'WETO G',
    'WETO J',
    'WETO P',
    'WIEL JANSSENWEG',
    'WILHELMINA BRUG',
    'WILHELMINA LAAN',
    'WILHELMINA PLEIN',
    'WILLEM DE ZWIJGER LAAN',
    'WILLEM HOYER STRAAT',
    'WILLEM KROON STRAAT',
    'WILLEM STRAAT',
    'WILLIAM LEE STRAAT',
    'WIND STRAAT',
    'WINKEL STEEG',
    'WINNITOU WEG',
    'WINSTON CHURCHILL WEG',
    'WINTERSWIJK STRAAT',
    'WIRI WEG',
    'WISHI',
    'WISHI AA',
    'WISHI AB',
    'WISHI AC',
    'WISHI AD',
    'WISHI AE',
    'WISHI AI',
    'WISHI AK',
    'WISHI L',
    'WISHI T',
    'WISHI U',
    'WISHI V',
    'WISHI W',
    'WISHI X',
    'WISHI Y',
    'WISHI Z',
    'WITTE DE WITH LAAN',
    'WITTE STEEG',
    'WITTE WEG',
    'WOLK STRAAT',
    'WOUTERPLEIN',
    'WSETPUNT BAAI',
    'YACOMEL',
    'YATOBACO WEG',
    'YOTIN KORTA',
    'YSSEL STRAAT',
    'YSSELMEER STRAAT',
    'YUCATAN WEG',
    'YUCONWEG',
    'YUKON WEG',
    'ZAANTJE STEEG',
    'ZADELBOOM STRAAT',
    'ZAPATEER A',
    'ZAPATEER B',
    'ZAPATEER C',
    'ZAPATEER D',
    'ZAPATEER E',
    'ZAPATEER KAYA A',
    'ZAPATEER KAYA B',
    'ZAPATEER KAYA C',
    'ZAPOTEKEN WEG',
    'ZEE EN LANDZICHT',
    'ZEE STEEG',
    'ZEELANDIA',
    'ZEELANDIA BIJ VETTER',
    'ZEGU',
    'ZEGU Y',
    'ZEGU Z',
    'ZEVENBERGEN',
    'ZINK WEG',
    'ZORGEN DAL',
    'ZORGVLIET A',
    'ZORGVLIET BIJ ST WILLIBRODUS',
    'ZORGVLIET BIJ WESTPUNT',
    'ZOUT STEEG',
    'ZUID ABRAHAMSZ',
    'ZUID BONAM',
    'ZUID CHOLOMA',
    'ZUID POPO',
    'ZUID SANTA ROSA',
    'ZUID ZAPATEER',
    'ZUIDERZEE STRAAT',
    'ZUIKERTUINTJE',
    'ZUIKERTUINTJE WEG',
    'ZUSTERS STRAAT',
    'ZUSTERSTRAAT',
    'ZUTPHEN STRAAT',
    'ZUURZAK',
    'ZWAAN',
    'ZWALUW WEG',
    'ZWARTE STEEG',
    'ZWAVEL WEG'
]


export default AddressSelect;