import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import imageCompression from 'browser-image-compression';
import { useForm, Controller } from "react-hook-form"
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AddressSelect, CountrySelect, StatusSelect } from './components'
import { DropzoneArea } from 'material-ui-dropzone'
import {
    useMediaQuery,
    Grid,
    Typography,
    TextField,
    Button,
    Divider,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
    Select,
    MenuItem,
    CircularProgress
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers';
import * as yup from "yup";
import { useKeycloak } from '@react-keycloak/web';
import { useMutation, useQuery } from "react-query";
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import DateDropdown from 'components/DateDropdown';

const useStyles = makeStyles(theme => ({
    root: {},
    title: {
        fontWeight: 900,
    },
    inputTitle: {
        fontWeight: 700,
        marginBottom: theme.spacing(1),
    },
    select: {
        width: '100%',
        padding: '14px 12px'
    },
    dropzoneRoot: {
        minHeight: 100
    },
    dropzoneText: {
        fontSize: 16,
        marginTop: 14,
        marginBottom: 14
    },
    dropzoneIcon: {
        height: 40
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
}));


const General = props => {
    const { className, ...rest } = props;
    const history = useHistory()
    const [keycloak] = useKeycloak()
    const { data: exists, isLoading: isExisitsLoading } = useQuery('exists', async () => {
        const result = await fetch(`${process.env.REACT_APP_API}/api/request/user?id=${keycloak.idTokenParsed.sub}&type=1`)
        const data = await result.json()
        return data;
    })

    const [mutateRequest, { isLoading, isSuccess, isError, error }] = useMutation(async (data) => {
        const result = await fetch(`${process.env.REACT_APP_API}/api/request`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        if (result.ok) {
            return result.json()
        } else {
            throw `Oops algu a bai robes: ${result.statusText}`;
        }

    }, {
        throwOnError: true
    })

    const [images, setImages] = useState([]);


    const schema = yup.object().shape({
        firstName: yup.string().required('Mester yena nòmber'),
        lastName: yup.string().required('Mester yena fam'),
        registeredAddress: yup.string().nullable().required('Mester yena adrès na kranshi'),
        registeredAddressNumber: yup.string().required('Mester yena number di adrès na kranshi'),
        currentAddress: yup.string().nullable().required('Mester yena adrès aktual'),
        currentAddressNumber: yup.string().required('Mester yena number di adrès aktual'),
        hasDutchNationality: yup.bool().nullable().required('Mester yena nashonalidat'),
        proofOfResident: yup.array().when('hasDutchNationality', {
            is: (val) => val && val === false,
            then: yup.array().required('Mester ‘upload’ bo pèrmit di estadia'),
        }),
        dateOfBirth: yup.string().required('Mester yena fecha di nasementu').test('age', 'Bo mester ta mas ku 18 aña', val => {
            return (
                (new Date(new Date(val).getFullYear() + 18, new Date(val).getMonth(), new Date(val).getDate()))
                < (new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))

            )
        }),
        placeOfBirth: yup.string().required('Mester yena pais di nasementu'),
        gender: yup.string().required('Mester yena sekso'),
        phone1: yup.number().test('len', 'Mester ta minimó 7 number', val => !val || (val && val.toString().length >= 7)).moreThan(0, 'Mester yena number di selular'),
        // phone2: yup.number().nullable().min(7, 'Mester yena minimó 7 number'),
        // whatsapp: yup.number().nullable().min(7, 'Mester yena minimó 7 number'),
        email: yup.string().email('Mester ta un “e-mail”').required('Mester yena “e-mail”'),
        confirmEmail: yup.string().oneOf([yup.ref('email'), null], '“E-mail” no ta meskos'),
        identificationNumber: yup.number().test('len', 'Mester ta 10 number', val => !val || (val && val.toString().length === 10)).moreThan(0, 'Mester yena number di identifikashon'),
        identificationType: yup.string().required('Mester skohe e tipo di identifikashon'),
        expiryDate: yup.string().required('Mester yena fecha di vensementu'),
        maritalStatus: yup.object().nullable().shape({
            value: yup.string().required()
        }).required('Mester yena estado sivil'),
        lastNamePartner: yup.string().when('maritalStatus', {
            is: (val) => val && (['married', 'registeredPartnership', 'widow', 'divorcedPartnership'].includes(val.value) || val.value === 'hasRelationship'),
            then: yup.string().required('Mester yena fam di kasá'),
        }),
        firstNamePartner: yup.string().when('maritalStatus', {
            is: (val) => val && (['married', 'registeredPartnership', 'widow', 'divorcedPartnership'].includes(val.value) || val.value === 'hasRelationship'),
            then: yup.string().required('Mester yena nòmber di kasá'),
        }),
        identificationNumberPartner: yup.number().when('maritalStatus', {
            is: (val) => val && (['married', 'registeredPartnership', 'widow', 'divorcedPartnership'].includes(val.value) || val.value === 'hasRelationship'),
            then: yup.number().moreThan(0, 'Mester yena number di identifikashon di kasá').test('len', 'Mester ta 10 number', val => !val || (val && val.toString().length === 10)),
        }),
        proofOfPartnerIncome: yup.array().when('maritalStatus', {
            is: (val) => val && val.value === 'married',
            then: yup.array().required('Mester ‘upload’ prueba di entrada di bo kasá"'),
        }),
        proofOfMarriage: yup.array().when('maritalStatus', {
            is: (val) => val && val.value === 'married',
            then: yup.array().required('Mester ‘upload’ prueba di matrimonio, familieboek'),
        }),
        proofOfDivorce: yup.array().when('maritalStatus', {
            is: (val) => val && val.value === 'divorced',
            then: yup.array().required('Mester ‘upload’ prueba di divorsio'),
        }),
        proofOfVerdict: yup.array().when('maritalStatus', {
            is: (val) => val && val.value === 'divorced',
            then: yup.array().required('Mester ‘upload’ prueba di veredikto'),
        }),
        hasChildren: yup.bool().nullable().required('Mester skohe si bo tin yu'),
        // ownChildren: yup.array().when('hasChildren', {
        //     is: (val) => val && val === true,
        //     then: yup.array().required('Mester ‘upload’ akto di nasementu di bo yu(nan)/buki di famia'),
        // })yup.number().min(1, 'Mester skohe kuantu ju'),
        proofOfChildren: yup.array().when('ownChildren', {
            is: (val) => val && val > 0,
            then: yup.array().required('Mester ‘upload’ akto di nasementu di bo yu(nan)/buki di famia'),
        }),
        hasIncome: yup.bool().nullable().required('Mester skohe si bo tin entrada aktualmente'),
        work: yup.string().when('hasIncome', {
            is: (val) => val && val === true,
            then: yup.string().required('Mester yena kon a yega na e entrada'),
        }),
        contractee: yup.string().when('work', {
            is: (val) => val && val === 'freelance',
            then: yup.string().required('Mester skohe pa ken')
        }),
        employerCompanyName: yup.string().when('contractee', {
            is: val => val && val === 'company',
            then: yup.string().required('Mester yena nòmber di kompania')
        }).when('work', {
            is: val => val && val === 'employee',
            then: yup.string().required('Mester yena nòmber di kompania')
        }),
        employerName: yup.string().when('contractee', {
            is: val => val && ['company', 'person'].includes(val),
            then: yup.string().required('Mester yena nòmber di e dunadó di trabou')
        }).when('work', {
            is: val => val && val === 'employee',
            then: yup.string().required('Mester yena nòmber di e dunadó di trabou')
        }),
        employerAddress: yup.string().when('contractee', {
            is: val => val && ['company', 'person'].includes(val),
            then: yup.string().required('Mester yena adrès di e trabou')
        }).when('work', {
            is: val => val && val === 'employee',
            then: yup.string().required('Mester yena adrès di e trabou')
        }),
        employerJobType: yup.string().when('contractee', {
            is: val => val && ['company', 'person'].includes(val),
            then: yup.string().required('Mester yena tipo di djòp')
        }).when('work', {
            is: val => val && ['employee', 'freelance', 'selfEmployed'].includes(val),
            then: yup.string().required('Mester yena tipo di djòp')
        }),
        employerSalary: yup.string().when('contractee', {
            is: val => val && ['company', 'person'].includes(val),
            then: yup.string().required('Mester yena e kantidat di sèn risibí')
        }).when('work', {
            is: val => val && ['employee', 'freelance', 'selfEmployed'].includes(val),
            then: yup.string().required('Mester yena e kantidat di sèn risibí')
        }),
        employerPayFrequency: yup.string().when('contractee', {
            is: val => val && ['company', 'person'].includes(val),
            then: yup.string().required('Mester yena ku ki frekuensia a risibí e sèn aki')
        }).when('work', {
            is: val => val && ['employee', 'freelance', 'selfEmployed'].includes(val),
            then: yup.string().required('Mester yena ku ki frekuensia a risibí e sèn aki')
        }),
        reason: yup.string().required('Mester yena motibu di petishon'),
        activelyJobSeeking: yup.bool().nullable().required('Mester skohe si bo ta buska trabou aktivamente'),
        jobSeekingMethod: yup.string().when('activelyJobSeeking', {
            is: val => val && val === true,
            then: yup.string().required('Mester yena metodo di buska trabou')
        }),
        reasonNoJobSeeking: yup.string().when('activelyJobSeeking', {
            is: val => (val !== null) && val === false,
            then: yup.string().required('Mester yena e motibu dikon no')
        }),
        reasonCannotWork: yup.string().when('reason', {
            is: val => val && val === 'cannotWork',
            then: yup.string().required('Mester yena e motibu dikon no por traha')
        }),
        lastWork: yup.string().when('reason', {
            is: val => val && val === 'lostJob',
            then: yup.string().required('Mester yena e lastu trabou')
        }),
        proofOfCannotWork: yup.array().when('reason', {
            is: (val) => val && val === 'cannotWork',
            then: yup.array().required('Mester ‘upload’ prueba ku bo no por traha'),
        }),
        // reason: '',
        otherReason: yup.string().when('reason', {
            is: val => val !== null,
            then: yup.string().required('Mester ampliá bo motibu di petishon')
        }),
        lastEmployerCompanyName: yup.string().when('lastWork', {
            is: val => val && val === 'company',
            then: yup.string().required('Mester yena nòmber di kompania')
        }),
        lastEmployerName: yup.string().when('lastWork', {
            is: val => val && ['company', 'person'].includes(val),
            then: yup.string().required('Mester yena nòmber di e dunadó di trabou')
        }),
        lastEmployerAddress: yup.string().when('lastWork', {
            is: val => val && ['company', 'person'].includes(val),
            then: yup.string().required('Mester yena adrès di e trabou')
        }),
        lastEmployerWorkType: yup.string().when('lastWork', {
            is: val => val && ['company', 'person'].includes(val),
            then: yup.string().required('Mester yena e tipo di trabou')
        }),
        lastEmployerTimeAgo: yup.string().when('lastWork', {
            is: val => val && ['company', 'person'].includes(val),
            then: yup.string().required('Mester yena e kantidat di tempu')
        }),
        // ProofOfIncomeLastEmployer: [],
        lastEmployerSalary: yup.string().when('lastWork', {
            is: val => val && ['company', 'person'].includes(val),
            then: yup.string().required('Mester yena kuantu bo a gana')
        }),
        lastEmployerPayFrequency: yup.string().when('lastWork', {
            is: val => val && ['company', 'person'].includes(val),
            then: yup.string().required('Mester yena ku ki frekuensia a risibí e sèn aki')
        }),
        proofOfContract: yup.array().when('hasContract', {
            is: (val) => val && (val === true || val === 'true'),
            then: yup.array().required('Mester ‘upload’ e kòntrakt'),
        }),
        // proofOfJobSeeking: [],
        // reasonNoJobSeeking: '',
        hasVehicle: yup.bool().nullable().required('Mester skohe si bo tin kas ta vehiculo'),
        vehicle: yup.string().when('hasVehicle', {
            is: val => val && val === true,
            then: yup.string().required('Mester yena ki tipo di vehikulo')
        }),
        numberPlate: yup.string().when('hasVehicle', {
            is: val => val && val === true,
            then: yup.string().required('Mester yena e plachi number')
        }),
        hasBoat: yup.bool().nullable().required('Mester skohe si bo tin boto'),
        boatInformation: yup.string().when('hasBoat', {
            is: val => val && val === true,
            then: yup.string().required('Mester yena datos di registrashon di e boto')
        }),
        hasRentedHouse: yup.bool().nullable().required('Mester skohe si bo tin kas ta hür'),
        hasBankAccount: yup.bool().nullable().required('Mester skohe si bo tin kuenta di banko'),
        bankAccountType: yup.string().when('hasBankAccount', {
            is: val => val && val === true,
            then: yup.string().required('Mester skohe ki tipo di kuenta di banko')
        }),
        currentAccountStatements: yup.array().when('bankAccountType', {
            is: val => val && (val === 'current' || val === 'both'),
            then: yup.array().required('Mester ‘upload’ último dos statement di bo kuenta di koriente')
        }),
        savingsAccountStatements: yup.array().when('bankAccountType', {
            is: val => val && (val === 'savings' || val === 'both'),
            then: yup.array().required('Mester ‘upload’ último dos statement di bo kuenta di spar')
        }),
        hasMoreSourceOfIncome: yup.bool().nullable().required('Mester skohe si bo tin mas fuente di entrada'),
        moreSourceOfIncome: yup.string().when('hasMoreSourceOfIncome', {
            is: val => val && val === true,
            then: yup.string().required('Mester spesifiká esaki')
        }),
        rentalMonthlyPrice: yup.string().when('hasRentedHouse', {
            is: val => val && val === true,
            then: yup.string().required('Mester yena e montante pa luna')
        }),
        hasOwnHouse: yup.bool().nullable().required('Mester skohe si bo ta biba den bo mes kas'),
        payingMortgage: yup.bool().nullable().when('hasOwnHouse', {
            is: val => val && val === true,
            then: yup.bool().nullable().required('Mester skohe si bo ta pagando hipotek'),
        }),
        reasonNotPayingMortgage: yup.string().when('payingMortgage', {
            is: val => val !== null && val === false,
            then: yup.string().required('Mester yena dikon bo no ta pagando hipotek')
        }),
        notOwnHouse: yup.string().nullable().when('hasOwnHouse', {
            is: val => val !== null && val === false,
            then: yup.string().nullable().required('Mester skohe si bo ta biba den bo mes kas'),
        }),
        houseAddress: yup.string().when('hasOwnHouse', {
            is: val => val && val === true,
            then: yup.string().required('Mester yena e adres')
        }).when('hasOwnHouse', {
            is: val => (val !== null) && val === false,
            then: yup.string().required('Mester yena e adres')
        }),
        houseMortgageDebt: yup.string().when('payingMortgage', {
            is: val => val && val === true,
            then: yup.string().required('Mester yena e montante')
        }),
        houseRentalPrice: yup.string().when('notOwnHouse', {
            is: val => val && ['rent', 'fkp'].includes(val),
            then: yup.string().required('Mester yena kuantu sèn bo ta paga na hür')
        }),
        houseContribution: yup.string().when('notOwnHouse', {
            is: val => val && val === 'liveIn',
            then: yup.string().required('Mester yena kuantu sèn bo ta paga na kontribushon')
        }),
        liveInDescription: yup.string().when('notOwnHouse', {
            is: val => val && val === 'liveIn',
            then: yup.string().required('Mester spesifiká na unda bo ta kedando')
        }),
        // houseResidents: [],
        proofOfRentalContract: yup.array().when('notOwnHouse', {
            is: val => val && ['rent', 'fkp'].includes(val),
            then: yup.array().required('Mester ‘upload’ kòntrakt di hur of apoderashon')
        }),
        otherHousing: yup.string().when('notOwnHouse', {
            is: val => val && val === 'other',
            then: yup.string().required('Mester otro')
        }),
        hasDependents: yup.bool().nullable().required('Mester skohe si tin mas persona ta depende di bo finansieramente'),
        hasSignupFkp: yup.bool().nullable().required('Mester skohe si bo ta inskribí na FKP pa bo risibí un kas'),
        signupFkpYear: yup.number().nullable().test('len', 'Mester ta 4 number', val => !val || (val && val.toString().length === 4)).moreThan(0, 'Mester yena for di ki aña'),
        fkpPoints: yup.number().nullable().moreThan(0, 'Mester yena e kantidat di punto'),
        dependents: yup.array().when('hasDependents', {
            is: val => val === true,
            then: yup.array().required('Mester yena ken mas ta biba den kas kubo')
        }),
        education: yup.string().required('Mester skohe nivel di edukashon'),
        hasCertificate: yup.bool().nullable().required('Mester skohe si bo tin diploma'),
        certificateYear: yup.number().nullable().when('hasCertificate', {
            is: val => val && val === true,
            then: yup.number().test('len', 'Mester ta 4 number', val => val && val.toString().length === 4).moreThan(0, 'Mester yena aña ku bo a risibi bo diploma')
        }),
        hasOtherCertificate: yup.bool().nullable().required('Mester skohe si bo a risibí sertifikado di algun kurso/workshòp ku b\'a partisipá na dje'),
        otherCertificateDescription: yup.string().when('hasOtherCertificate', {
            is: val => val === true,
            then: yup.string().required('Mester indiká sertifikado di kua kurso/workshòp')
        }),
        otherCertificateYear: yup.number().when('hasOtherCertificate', {
            is: val => val === true,
            then: yup.number().test('len', 'Mester ta 4 number', val => val && val.toString().length === 4).moreThan(0, 'Mester yena aña ku bo a risibi bo diploma')
        }),
        hasCertificateWorkExperience: yup.bool().nullable().required('Mester skohe si bo a yega di traha den e sektor ku bo a studia aden'),
        certificateWorkExperienceCompany: yup.string().when('hasCertificateWorkExperience', {
            is: val => val === true,
            then: yup.string().required('Mester yena na kua kompania')
        }),
        mobility: yup.string().required('Mester yena kon bo mobilidat ta'),
        visibility: yup.string().required('Mester yena kon bo bista ta'),
        hearing: yup.string().required('Mester yena kon bo oido ta'),
        speakingAbility: yup.string().required('Mester yena kon bo abla ta'),
        hasAdiction: yup.bool().nullable().required('Mester skohe si bo ta sufri di un òf mas adikshon'),
        hasAdictionTreatment: yup.bool().nullable().when('hasAdiction', {
            is: val => val && val === true,
            then: yup.bool().nullable().required('Mester skohe si Bo ta hañando yudansa di un instansia')
        }),
        adictionTreatmentCenter: yup.string().when('hasAdiction', {
            is: val => val && val === true,
            then: yup.string().required('Mester indiká kua instansia')
        }),
        hasDiseases: yup.bool().nullable().required('Mester skohe si bo ta sufri di algun malesa'),
        diseases: yup.array().when('hasDiseases', {
            is: val => val && val === true,
            then: yup.array().required('Mester skohe un di e opshonan')
        }),
        // equipments: [],
        // treatmentCenters: [],
        otherTreatmentCenter: yup.string().when('treatmentCenters', {
            is: val => val.includes('Otro'),
            then: yup.string().required('Mester yen otro instansia')
        }),
        hasPsychologicalLimitation: yup.bool().nullable().required('Mester skohe si bo tin problema sígiko'),
        hasPsychologicalLimitationTreatment: yup.bool().nullable().when('hasPsychologicalLimitation', {
            is: val => val && val === true,
            then: yup.bool().nullable().required('Mester skohe si bo ta haña yudansa di un instansia')
        }),
        psychologicalLimitationCenter: yup.string().when('hasPsychologicalLimitationTreatment', {
            is: val => val && val === true,
            then: yup.string().nullable().required('Mester indiká kua instansia')
        }),
        hasPsychologicalLimitationDiagnostic: yup.bool().nullable().when('hasPsychologicalLimitation', {
            is: val => val && val === true,
            then: yup.bool().nullable().required('Mester skohe si tin un diagnóstiko?')
        }),
        psychologicalLimitationDiagnostician: yup.string().when('hasPsychologicalLimitationDiagnostic', {
            is: val => val && val === true,
            then: yup.string().required('Mester yena ken a hasi e diagnóstiko aki')
        }),
        psychologicalLimitationDiagnosticDate: yup.string().when('hasPsychologicalLimitationDiagnostic', {
            is: val => val && val === true,
            then: yup.string().required('Mester yena fecha di e diagnóstiko aki')
        }),
        hasPsychologicalLimitationDiagnosticReport: yup.bool().nullable().when('hasPsychologicalLimitationDiagnostic', {
            is: val => val && val === true,
            then: yup.bool().nullable().required('Mester yena si tin un rapòrt di e diagnóstiko')
        }),
        proofOfPsychologicalLimitationDiagnosticReport: yup.array().when('hasPsychologicalLimitationDiagnosticReport', {
            is: val => val && val === true,
            then: yup.array().required('Mester ‘upload’ karta di diagnóstiko di bo médiko')
        }),
        hasMentalDisorder: yup.bool().nullable().required('Mester skohe si bo tin retardashon mental'),
        hasMentalDisorderTreatment: yup.bool().nullable().when('hasMentalDisorder', {
            is: val => val && val === true,
            then: yup.bool().nullable().required('Mester skohe si bo ta haña yudansa di un instansia')
        }),
        mentalDisorderTreatmentCenter: yup.string().when('hasMentalDisorderTreatment', {
            is: val => val && val === true,
            then: yup.string().required('Mester indiká kua instansia?')
        }),
        hasMentalDisorderDiagnostic: yup.bool().nullable().when('hasMentalDisorder', {
            is: val => val && val === true,
            then: yup.bool().nullable().required('Mester skohe si tin un diagnóstiko?')
        }),
        mentalDisorderDiagnostician: yup.string().when('hasMentalDisorderDiagnostic', {
            is: val => val && val === true,
            then: yup.string().required('Mester yena ken a hasi e diagnóstiko aki')
        }),
        mentalDisorderDiagnosticDate: yup.string().when('hasMentalDisorderDiagnostic', {
            is: val => val && val === true,
            then: yup.string().required('Mester yena fecha di e diagnóstiko aki')
        }),
        hasMentalDisorderDiagnosticReport: yup.bool().nullable().when('hasMentalDisorderDiagnostic', {
            is: val => val && val === true,
            then: yup.bool().nullable().required('Mester yena si tin un rapòrt di e diagnóstiko')
        }),
        proofOfMentalDisorderDiagnosticReport: yup.array().when('hasMentalDisorderDiagnosticReport', {
            is: val => val && val === true,
            then: yup.array().required('Mester ‘upload’ karta di diagnóstiko di bo médiko')
        }),
        hasPsychologicalLimitationChild: yup.bool().nullable().required('Mester skohe si bo tin yu ku limitashon mental'),
        insurance: yup.string().required('Mester skohe tipo di seguro bo tin'),
        hasMedicalTreatment: yup.bool().nullable().required('Mester skohe si bo ta bou di tratamentu di un médiko òf paramédiko'),
        medicalTreatment: yup.string().when('hasMedicalTreatment', {
            is: val => val && val === true,
            then: yup.string().required('Mester skohe ki tipo di médiko òf paramédiko')
        }),
        medicalPractitionerName: yup.string().when('hasMedicalTreatment', {
            is: val => val === true,
            then: yup.string().required('Mester yena nomber di médiko òf paramédiko?')
        }),
        proofOfMedicalTreatment: yup.array().when('medicalTreatment', {
            is: (val) => val && ["specialist", "psychologist", "psychiatrist"].includes(watch('medicalTreatment')),
            then: yup.array().required('Mester ‘upload’ e karta di bo spesialista'),
        }),
        useMedicalSupplies: yup.bool().nullable().required('Mester skohe si bo ta usa medikamentu'),
        medicalSupplies: yup.string().when('useMedicalSupplies', {
            is: val => val === true,
            then: yup.string().required('Mester yena ki tipo di medikamentu')
        }),
        hasWelfare: yup.bool().nullable().required('Mester skohe si bo ta risibí yudansa sosial pa motibu di bo estado di salú'),
        welfare: yup.string().when('hasWelfare', {
            is: val => val === true,
            then: yup.string().required('Mester yena ki tipo di yudansa')
        }),
        hasFuneralInsurance: yup.bool().nullable().required('Mester skohe si bo tin seguro di entiero'),
        funeralInsurance: yup.string().when('hasFuneralInsurance', {
            is: val => val === true,
            then: yup.string().required('Mester indiká na kua kompania')
        }),
        confirmation: yup.bool().oneOf([true], 'Mester konfirmá ku a yena mas kompleto posibel i segun bèrdat')
    });
    const { control, handleSubmit, errors, watch, getValues, setValue, reset, setError, clearErrors, register } = useForm({
        defaultValues: {
            userId: '',
            firstName: '',
            lastName: '',
            registeredAddress: '',
            registeredAddressNumber: '',
            currentAddress: '',
            currentAddressNumber: '',
            placeOfBirth: 'Curacao',
            hasDutchNationality: null,
            proofOfResident: [],
            dateOfBirth: null,
            gender: '',
            maritalStatus: null,
            phone1: 0,
            phone2: 0,
            whatsapp: 5999,
            email: '',
            confirmEmail: '',
            identificationNumber: 0,
            identificationType: '',
            expiryDate: null,
            firstNamePartner: '',
            lastNamePartner: '',
            identificationNumberPartner: 0,
            proofOfPartnerIncome: [],
            proofOfMarriage: [],
            proofOfDivorce: [],
            proofOfVerdict: [],
            proofOfDeath: [],
            hasRelationship: null,
            livingTogether: null,
            livingTogetherAddress: '',
            livingTogetherAddressNumber: '',
            hasChildren: null,
            proofOfChildren: [],
            ownChildren: 0,
            notOwnChildren: 0,
            hasIncome: null,
            work: '',
            contractee: '',
            employerCompanyName: '',
            employerName: '',
            employerAddress: '',
            employerJobType: '',
            employerSalary: '',
            employerPayFrequency: '',
            reasonCannotWork: '',
            proofOfCannotWork: [],
            reason: '',
            otherReason: '',
            lastWork: '',
            lastEmployerCompanyName: '',
            lastEmployerName: '',
            lastEmployerAddress: '',
            lastEmployerWorkType: '',
            lastEmployerTimeAgo: '',
            proofOfIncomeLastEmployer: [],
            lastEmployerSalary: '',
            lastEmployerPayFrequency: '',
            activelyJobSeeking: null,
            jobSeekingMethod: '',
            proofOfJobSeeking: [],
            reasonNoJobSeeking: '',
            hasContract: null,
            proofOfContract: [],
            hasVehicle: null,
            vehicle: '',
            numberPlate: '',
            hasBoat: null,
            boatInformation: '',
            hasRentedHouse: null,
            hasBankAccount: null,
            bankAccountType: '',
            currentAccountStatements: [],
            savingsAccountStatements: [],
            hasMoreSourceOfIncome: null,
            moreSourceOfIncome: '',
            rentalMonthlyPrice: '',
            hasOwnHouse: null,
            notOwnHouse: '',
            houseAddress: '',
            payingMortgage: null,
            reasonNotPayingMortgage: '',
            houseMortgageDebt: '',
            houseRentalPrice: '',
            houseContribution: '',
            liveInDescription: '',
            houseResidents: [],
            proofOfRentalContract: [],
            proofOfRentalPayment: [],
            otherHousing: '',
            hasDependents: null,
            hasSignupFkp: null,
            signupFkpYear: 0,
            fkpPoints: 0,
            dependents: [],
            education: '',
            hasCertificate: null,
            certificateYear: 0,
            hasOtherCertificate: null,
            otherCertificateDescription: '',
            otherCertificateYear: '',
            hasCertificateWorkExperience: null,
            certificateWorkExperienceCompany: '',
            mobility: '',
            visibility: '',
            hearing: '',
            speakingAbility: '',
            hasAdiction: null,
            hasAdictionTreatment: null,
            adictionTreatmentCenter: '',
            hasDiseases: null,
            diseases: [],
            equipments: [],
            treatmentCenters: [],
            otherTreatmentCenter: '',
            hasPsychologicalLimitation: null,
            hasPsychologicalLimitationTreatment: null,
            psychologicalLimitationCenter: '',
            hasPsychologicalLimitationDiagnostic: null,
            psychologicalLimitationDiagnostician: '',
            psychologicalLimitationDiagnosticDate: null,
            hasPsychologicalLimitationDiagnosticReport: null,
            proofOfPsychologicalLimitationDiagnosticReport: [],
            hasMentalDisorder: null,
            hasMentalDisorderTreatment: null,
            hasMentalDisorderDiagnostic: null,
            mentalDisorderDiagnostician: '',
            mentalDisorderTreatmentCenter: '',
            mentalDisorderDiagnosticDate: null,
            hasMentalDisorderDiagnosticReport: null,
            proofOfMentalDisorderDiagnosticReport: [],
            hasPsychologicalLimitationChild: null,
            insurance: '',
            hasMedicalTreatment: null,
            medicalTreatment: '',
            medicalPractitionerName: '',
            otherMedicalTreatment: '',
            proofOfMedicalTreatment: [],
            useMedicalSupplies: null,
            medicalSupplies: '',
            hasWelfare: null,
            welfare: '',
            hasFuneralInsurance: null,
            funeralInsurance: '',
            confirmation: false
        },
        resolver: yupResolver(schema),
    });

    const onDeleteHandler = (file, category) => {
        setImages(images.filter(image => (image.name !== file.name && image.categoryId !== category)))
    }
    const onDropHandler = (files, category) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true
        }

        files.forEach(file => {
            let img = {}
            if (file.type === 'application/pdf') {
                const reader = new FileReader();
                reader.onload = (event) => {
                    img.base64 = event.target.result.split(',')[1];
                    img.type = event.target.result.split(',')[0]
                };
                img.name = file.name;
                img.categoryId = category;
                setImages([...images, img])
                return reader.readAsDataURL(file);
            } else {
                imageCompression(file, options).then((compressedFile) => {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        img.base64 = event.target.result.split(',')[1];
                        img.type = event.target.result.split(',')[0]
                    };
                    img.name = compressedFile.name;
                    img.categoryId = category;

                    setImages([...images, img])
                    return reader.readAsDataURL(compressedFile);
                })
            }

        });
    }

    const onSubmit = (data) => {
        const newData = {
            ...data,
            images,
            maritalStatus: data.maritalStatus.value,
            userId: keycloak.idTokenParsed.sub,
            type: 1,
            createdBy: 'internet',
            created: new Date().toISOString().substr(0, 10),
            updatedBy: 'internet',
            updated: new Date().toISOString().substr(0, 10)
        }
        mutateRequest(newData)

    }

    const handleBirthdayChange = (date) => {
        setValue('identificationNumber', date.split('-').join(''))
        const year = date.split('-')[0]
        const month = date.split('-')[1]
        const day = date.split('-')[2]
        setValue('dateOfBirth', new Date(+year, +month - 1, +day).toISOString().substr(0, 10))
        if ((new Date(+year + 18, +month - 1, +day))
            > (new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))) {
            setError('dateOfBirth', { type: 'date', message: 'Bo mester ta mas ku 18 aña', })
        } else {
            clearErrors('dateOfBirth')
        }
    }

    const handleExpiryDate = (date) => {
        const year = date.split('-')[0]
        const month = date.split('-')[1]
        const day = date.split('-')[2]
        setValue('expiryDate', new Date(+year, +month - 1, +day).toISOString().substr(0, 10))
        clearErrors('expiryDate')
    }

    const handlePsychologicalLimitationDiagnosticDate = (date) => {
        const year = date.split('-')[0]
        const month = date.split('-')[1]
        const day = date.split('-')[2]
        setValue('psychologicalLimitationDiagnosticDate', new Date(+year, +month - 1, +day).toISOString().substr(0, 10))
    }

    const handleMentalDisorderDiagnosticDate = (date) => {
        const year = date.split('-')[0]
        const month = date.split('-')[1]
        const day = date.split('-')[2]
        setValue('mentalDisorderDiagnosticDate', new Date(+year, +month - 1, +day).toISOString().substr(0, 10))
    }

    const handleDependentsSelect = (checkedName) => {
        const newNames = getValues('dependents')?.includes(checkedName)
            ? getValues('dependents')?.filter(name => name !== checkedName)
            : [...(getValues('dependents') ?? []), checkedName];

        setValue('dependents', newNames);
        return newNames;
    }

    const handleHouseResidentsSelect = (checkedName) => {
        const newNames = getValues('houseResidents')?.includes(checkedName)
            ? getValues('houseResidents')?.filter(name => name !== checkedName)
            : [...(getValues('houseResidents') ?? []), checkedName];

        setValue('houseResidents', newNames);
        return newNames;
    }

    const handleDiseasesSelect = (checkedName) => {
        const newNames = getValues('diseases')?.includes(checkedName)
            ? getValues('diseases')?.filter(name => name !== checkedName)
            : [...(getValues('diseases') ?? []), checkedName];

        setValue('diseases', newNames);
        return newNames;
    }

    const handleEquipmentsSelect = (checkedName) => {
        const newNames = getValues('equipments')?.includes(checkedName)
            ? getValues('equipments')?.filter(name => name !== checkedName)
            : [...(getValues('equipments') ?? []), checkedName];

        setValue('equipments', newNames);
        return newNames;
    }

    const handleTreatmentCentersSelect = (checkedName) => {
        const newNames = getValues('treatmentCenters')?.includes(checkedName)
            ? getValues('treatmentCenters')?.filter(name => name !== checkedName)
            : [...(getValues('treatmentCenters') ?? []), checkedName];

        setValue('treatmentCenters', newNames);
        return newNames;
    }
    const classes = useStyles();

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    useEffect(() => {
        if (isSuccess) {
            history.push('/success')
        }
    }, [isSuccess, history])

    const handleWhatsappNumber = (e) => {
        const whatsappNum = '5999' + e.target.value.substr('5999'.length)
        setValue("whatsapp", +whatsappNum);
        return +whatsappNum;
    }

    const handleAutoFillDate = (e) => {
        const id = fixFormatDate() + e.target.value.substr(fixFormatDate().length)
        setValue("identificationNumber", id);
        return id;
    }
    const fixFormatDate = () => {
        const tempMonth = (Number(getValues('monthOfBirth')) + 1).toString()
        const year = getValues('yearOfBirth') || ''
        const month = getValues('monthOfBirth') ? tempMonth.length < 2 ? '0' + tempMonth : tempMonth : ''
        const day = getValues('dayOfBirth') ? getValues('dayOfBirth').length < 2 ? '0' + getValues('dayOfBirth') : getValues('dayOfBirth') : ''

        return year + month + day;
    }

    return (
        isLoading || isExisitsLoading ? <CircularProgress color="primary" size={180} /> :
            isError ? <Alert severity='error' style={{ width: '100%' }}>{error}</Alert> :
                exists.length > 0 ? <Alert severity='warning' style={{ width: '100%' }}>Bo tin un petishon kaba</Alert> :
                    <div className={clsx(classes.root, className)} {...rest}>
                        <Grid container spacing={isMd ? 4 : 2}>

                            <Grid item xs={12}>
                                <Typography variant="h6" color="primary" className={classes.title}>
                                    Informashon General
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    1. Fam
                                </Typography>
                                <Controller
                                    as={TextField}
                                    id="lastName"
                                    name="lastName"
                                    label="Fam"
                                    size="medium"
                                    required
                                    variant="outlined"
                                    fullWidth
                                    control={control}
                                    error={errors.lastName ? true : false}

                                />
                                {errors.lastName && <p style={{ color: '#bf1650' }}>{errors.lastName.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    2. Nòmber
                                </Typography>
                                <Controller
                                    as={TextField}
                                    id="firstName"
                                    name="firstName"
                                    label="Nòmber"
                                    variant="outlined"
                                    size="medium"
                                    required
                                    fullWidth
                                    control={control}
                                    error={errors.firstName ? true : false}
                                />
                                {errors.firstName && <p style={{ color: '#bf1650' }}>{errors.firstName.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    3. Adrès kaminda ta registrá ofisialmente na Kranshi
                                </Typography>
                                <AddressSelect
                                    name="registeredAddress"
                                    label="Adrès kaminda ta registrá ofisialmente na Kranshi"
                                    control={control}
                                    required={true}
                                    placeholder="Si bo no por haña bo adrès, por fabor skibi'é."
                                    error={errors.registeredAddress ? true : false}
                                />
                                {errors.registeredAddress && <p style={{ color: '#bf1650' }}>{errors.registeredAddress.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    4. Number di adrès na Kranshi
                                </Typography>
                                <Controller
                                    as={TextField}
                                    id="registeredAddressNumber"
                                    name="registeredAddressNumber"
                                    label="Number di adrès na Kranshi"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    error={errors.registeredAddressNumber ? true : false}
                                    control={control}
                                />
                                {errors.registeredAddressNumber && <p style={{ color: '#bf1650' }}>{errors.registeredAddressNumber.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    5. Adrès kaminda bo ta biba aktualmente
                                </Typography>
                                <AddressSelect
                                    name="currentAddress"
                                    label="Adrès kaminda bo ta biba aktualmente"
                                    control={control}
                                    required={true}
                                    placeholder="Si bo no por haña bo adrès, por fabor skibi'é"
                                    error={errors.currentAddress ? true : false}
                                />
                                {errors.currentAddress && <p style={{ color: '#bf1650' }}>{errors.currentAddress.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    6. Number di adrès aktual
                                </Typography>
                                <Controller
                                    as={TextField}
                                    id="currentAddressNumber"
                                    name="currentAddressNumber"
                                    label="Number di adrès aktual"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    error={errors.currentAddressNumber ? true : false}
                                    control={control}
                                />
                                {errors.currentAddressNumber && <p style={{ color: '#bf1650' }}>{errors.currentAddressNumber.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    7. Pais di nasementu
                                </Typography>
                                <CountrySelect
                                    control={control}
                                    name="placeOfBirth"
                                    label="Pais di nasementu"
                                    required={true}
                                    error={errors.placeOfBirth ? true : false}
                                />
                                {errors.placeOfBirth && <p style={{ color: '#bf1650' }}>{errors.placeOfBirth.message}</p>}
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Controller
                                    control={control}
                                    name="hasDutchNationality"
                                    error={errors.hasDutchNationality ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                8. Nashonalidat Hulandes
                                            </Typography>
                                            <RadioGroup aria-label="hasDutchNationality" name="hasDutchNationality" value={JSON.parse(value)} onChange={onChange}>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasDutchNationality && <p style={{ color: '#bf1650' }}>{errors.hasDutchNationality.message}</p>}
                            </Grid>
                            {
                                watch('hasDutchNationality') !== null && !JSON.parse(watch('hasDutchNationality')) &&
                                <Grid item xs={6} sm={3}>
                                    <Typography
                                        variant="subtitle1"
                                        color="textPrimary"
                                        className={classes.inputTitle}
                                    >
                                        ‘Upload’ bo pèrmit di estadia
                                    </Typography>
                                    <Controller
                                        classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                        as={DropzoneArea}
                                        name="proofOfResident"
                                        dropzoneText="‘Upload’ bo pèrmit di estadia (image/* òf .pdf)"
                                        control={control}
                                        error={errors.proofOfResident ? true : false}
                                        onDrop={(files) => onDropHandler(files, 1)}
                                        onDelete={(file) => onDeleteHandler(file, 1)}
                                        filesLimit={1}
                                        acceptedFiles={['image/*', '.pdf']}
                                        showPreviews={true}
                                        showPreviewsInDropzone={false}
                                        useChipsForPreview
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        previewChipProps={{ classes: { root: classes.previewChip } }}
                                        previewText="Selected files"
                                    />
                                    {errors.proofOfResident && <p style={{ color: '#bf1650' }}>{errors.proofOfResident.message}</p>}
                                </Grid>
                            }
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    9. Fecha di nasementu
                                </Typography>
                                <DateDropdown
                                    name='dateOfBirth'
                                    startYear={new Date().getFullYear() - 100}
                                    endYear={new Date().getFullYear()}
                                    reverse
                                    register={register}
                                    setDateValue={handleBirthdayChange} />
                                {errors.dateOfBirth && <p style={{ color: '#bf1650' }}>{errors.dateOfBirth.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    10. Number di identifikashon
                                </Typography>
                                <Controller
                                    id="identificationNumber"
                                    name="identificationNumber"
                                    error={errors.identificationNumber ? true : false}
                                    control={control}
                                    render={
                                        ({ value, onChange, onBlur }) => (
                                            <TextField
                                                label="Number di identifikashon"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                type='number'
                                                onBlur={onBlur}
                                                value={value || ''}
                                                onChange={e => onChange(handleAutoFillDate(e))}
                                            />
                                        )
                                    }

                                />
                                {errors.identificationNumber && <p style={{ color: '#bf1650' }}>{errors.identificationNumber.message}</p>}
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="gender"
                                    error={errors.gender ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                11. Sekso
                                            </Typography>
                                            <RadioGroup aria-label="gender" name="gender" value={value} onChange={onChange} row>
                                                <FormControlLabel value="female" control={<Radio />} label="Femenino" />
                                                <FormControlLabel value="male" control={<Radio />} label="Maskulino" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.gender && <p style={{ color: '#bf1650' }}>{errors.gender.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    control={control}
                                    name="identificationType"
                                    error={errors.identificationType ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                12. Ki tipo di identifikashon lo bo bai usa, pa identifiká bo mes na kas di bario?
                                            </Typography>
                                            <RadioGroup aria-label="identificationType" name="identificationType" value={value} onChange={onChange} row>
                                                <FormControlLabel value="driversLicense" control={<Radio />} label="Reibeweis" />
                                                <FormControlLabel value="ID" control={<Radio />} label="Sédula" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.identificationType && <p style={{ color: '#bf1650' }}>{errors.identificationType.message}</p>}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    13. Fecha di vensementu di e {watch('identificationType') === 'driversLicense' ? 'reibeweis' : 'sédula'}
                                </Typography>
                                <DateDropdown
                                    name='expiryDate'
                                    startYear={new Date().getFullYear() - 10}
                                    endYear={new Date().getFullYear() + 10}
                                    reverse
                                    register={register}
                                    setDateValue={handleExpiryDate} />
                                {errors.expiryDate && <p style={{ color: '#bf1650' }}>{errors.expiryDate.message}</p>}

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    14. Number di telefòn di selular
                                </Typography>
                                <Controller
                                    id="phone1"
                                    name="phone1"
                                    control={control}
                                    render={
                                        ({ value, onChange, onBlur }) => (
                                            <TextField
                                                label="Number di telefòn di selular"
                                                variant="outlined"
                                                type='number'
                                                required
                                                fullWidth
                                                value={value || ''}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={errors.phone1 ? true : false}
                                            />
                                        )
                                    }

                                />
                                {errors.phone1 && <p style={{ color: '#bf1650' }}>{errors.phone1.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    Number di telefòn di whatsapp
                                </Typography>
                                <Controller
                                    control={control}
                                    id="whatsapp"
                                    name="whatsapp"
                                    render={({ value, onChange, onBlur }) => (
                                        <TextField
                                            label="Number di telefòn di whatsapp"
                                            fullWidth
                                            type='number'
                                            variant="outlined"
                                            value={value || ''}
                                            onChange={e => onChange(handleWhatsappNumber(e))}
                                            onBlur={onBlur}
                                        />
                                    )}


                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    15. Number di telefòn kas
                                </Typography>
                                <Controller
                                    id="phone2"
                                    name="phone2"
                                    control={control}
                                    render={
                                        ({ value, onChange, onBlur }) => (
                                            <TextField
                                                label="Number di telefòn kas"
                                                variant="outlined"
                                                type='number'
                                                fullWidth
                                                value={value || ''}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={errors.phone2 ? true : false}
                                            />
                                        )
                                    }

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    16. E-mail
                                </Typography>
                                <Controller
                                    as={TextField}
                                    id="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    type='email'
                                    fullWidth
                                    control={control}
                                    error={errors.email ? true : false}
                                />
                                {errors.email && <p style={{ color: '#bf1650' }}>{errors.email.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    17. Konfirmá e-mail
                                </Typography>
                                <Controller
                                    as={TextField}
                                    id="confirmEmail"
                                    name="confirmEmail"
                                    label="Konfirmá Email"
                                    variant="outlined"
                                    type='email'
                                    fullWidth
                                    control={control}
                                    error={errors.confirmEmail ? true : false}
                                />
                                {errors.confirmEmail && <p style={{ color: '#bf1650' }}>{errors.confirmEmail.message}</p>}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    18. Estado sivil
                                </Typography>
                                <StatusSelect
                                    label="Estado sivil"
                                    control={control}
                                    required={true}
                                    error={errors.maritalStatus ? true : false}
                                />
                                {errors.maritalStatus && <p style={{ color: '#bf1650' }}>{errors.maritalStatus.message}</p>}
                            </Grid>
                            {['divorced', 'divorcedPartnership'].includes(watch("maritalStatus")?.value) &&
                                (
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                ‘Upload’ prueba di divorsio {watch("maritalStatus")?.value === 'divorcedPartnership' && `di konbibensia legalisá di pareha`}
                                            </Typography>
                                            <Controller
                                                classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                                as={DropzoneArea}
                                                name="proofOfDivorce"
                                                dropzoneText={`‘Upload’ prueba di divorsio ${watch("maritalStatus")?.value === 'divorcedPartnership' && `di konbibensia legalisá di pareha`}(image/* òf .pdf)`}
                                                control={control}
                                                error={errors.proofOfDivorce ? true : false}
                                                onDrop={(files) => onDropHandler(files, 5)}
                                                onDelete={(file) => onDeleteHandler(file, 5)}
                                                filesLimit={1}
                                                acceptedFiles={['image/*', '.pdf']}
                                                showPreviews={true}
                                                showPreviewsInDropzone={false}
                                                useChipsForPreview
                                                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                previewChipProps={{ classes: { root: classes.previewChip } }}
                                                previewText="Selected files"
                                            />
                                            {errors.proofOfDivorce && <p style={{ color: '#bf1650' }}>{errors.proofOfDivorce.message}</p>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                ‘Upload’ prueba di veredikto
                                            </Typography>
                                            <Controller
                                                classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                                as={DropzoneArea}
                                                name="proofOfVerdict"
                                                dropzoneText={`‘Upload’ prueba di veredikto (image/* òf .pdf)`}
                                                control={control}
                                                error={errors.proofOfVerdict ? true : false}
                                                onDrop={(files) => onDropHandler(files, 21)}
                                                onDelete={(file) => onDeleteHandler(file, 21)}
                                                filesLimit={1}
                                                acceptedFiles={['image/*', '.pdf']}
                                                showPreviews={true}
                                                showPreviewsInDropzone={false}
                                                useChipsForPreview
                                                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                previewChipProps={{ classes: { root: classes.previewChip } }}
                                                previewText="Selected files"
                                            />
                                            {errors.proofOfVerdict && <p style={{ color: '#bf1650' }}>{errors.proofOfVerdict.message}</p>}
                                        </Grid>
                                    </>)
                            }
                            {watch("maritalStatus")?.value === 'divorced' || watch("maritalStatus")?.value === 'single' ?
                                (<Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="hasRelationship"
                                        error={errors.children ? true : false}
                                        render={({ onChange, value }) => (
                                            <>
                                                <Typography
                                                    variant="subtitle1"
                                                    color="textPrimary"
                                                    className={classes.inputTitle}
                                                >
                                                    Bo ta den un relashon aktualmente?
                                                </Typography>
                                                <RadioGroup aria-label="hasRelationship" name="hasRelationship" value={JSON.parse(value)} onChange={onChange} row>
                                                    <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                    <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                                </RadioGroup>
                                            </>
                                        )}
                                    />
                                    {errors.hasRelationship && <p style={{ color: '#bf1650' }}>{errors.hasRelationship.message}</p>}
                                </Grid>) : null
                            }

                            {
                                ['married', 'registeredPartnership', 'widow', 'divorcedPartnership'].includes(watch("maritalStatus")?.value) || JSON.parse(watch('hasRelationship')) ?
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Fam di {watch("maritalStatus")?.value === "married" ? "kasá" : watch("maritalStatus")?.value === "widow" ? "kasá ku a fayesé" : "pareha"}
                                            </Typography>
                                            <Controller
                                                as={TextField}
                                                id="lastNamePartner"
                                                name="lastNamePartner"
                                                label="Fam"
                                                size="medium"
                                                required
                                                variant="outlined"
                                                fullWidth
                                                control={control}
                                                error={errors.lastNamePartner ? true : false}
                                            />
                                            {errors.lastNamePartner && <p style={{ color: '#bf1650' }}>{errors.lastNamePartner.message}</p>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Nòmber di {watch("maritalStatus")?.value === "married" ? "kasá" : watch("maritalStatus")?.value === "widow" ? "kasá ku a fayesé" : "pareha"}
                                            </Typography>
                                            <Controller
                                                as={TextField}
                                                id="firstNamePartner"
                                                name="firstNamePartner"
                                                label="Nòmber"
                                                variant="outlined"
                                                size="medium"
                                                required
                                                fullWidth
                                                control={control}
                                                error={errors.firstNamePartner ? true : false}
                                            />
                                            {errors.firstNamePartner && <p style={{ color: '#bf1650' }}>{errors.firstNamePartner.message}</p>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Number di identifikashon riba sédula di {watch("maritalStatus")?.value === "married" ? "kasá" : watch("maritalStatus")?.value === "widow" ? "kasá ku a fayesé" : "pareha"}
                                            </Typography>
                                            <Controller
                                                id="identificationNumberPartner"
                                                name="identificationNumberPartner"
                                                control={control}
                                                render={
                                                    ({ value, onChange }) => (
                                                        <TextField
                                                            label={`Number di identifikashon riba sédula di ${watch("maritalStatus")?.value === "married" ? "kasá" : watch("maritalStatus")?.value === "widow" ? "kasá ku a fayesé" : "pareha"}`}
                                                            variant="outlined"
                                                            type='number'
                                                            fullWidth
                                                            error={errors.identificationNumberPartner ? true : false}
                                                            value={value || ''}
                                                            onChange={onChange}
                                                        />
                                                    )
                                                }

                                            />
                                            {errors.identificationNumberPartner && <p style={{ color: '#bf1650' }}>{errors.identificationNumberPartner.message}</p>}
                                        </Grid>
                                        {
                                            ['married', 'registeredPartnership', 'single', 'divorced'].includes(watch("maritalStatus")?.value) &&
                                            <Grid item xs={12} sm={6}>
                                                <Typography
                                                    variant="subtitle1"
                                                    color="textPrimary"
                                                    className={classes.inputTitle}
                                                >
                                                    ‘Upload’ prueba di entrada di bo {watch("maritalStatus")?.value === "married" ? 'kasá' : 'pareha'}
                                                </Typography>
                                                <Controller
                                                    classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                                    as={DropzoneArea}
                                                    dropzoneText={`‘Upload’ prueba di entrada di bo ${watch("maritalStatus")?.value === "married" ? 'kasá' : 'pareha'} (image/* òf .pdf)`}
                                                    name="proofOfPartnerIncome"
                                                    label="‘Upload’ prueba di entrada di bo kas"
                                                    control={control}
                                                    error={errors.proofOfPartnerIncome ? true : false}
                                                    onDrop={(files) => onDropHandler(files, 2)}
                                                    onDelete={(file) => onDeleteHandler(file, 2)}
                                                    filesLimit={1}
                                                    acceptedFiles={['image/*', '.pdf']}
                                                    showPreviews={true}
                                                    showPreviewsInDropzone={false}
                                                    useChipsForPreview
                                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                    previewChipProps={{ classes: { root: classes.previewChip } }}
                                                    previewText="Selected files"
                                                />
                                                {errors.proofOfPartnerIncome && <p style={{ color: '#bf1650' }}>{errors.proofOfPartnerIncome.message}</p>}
                                            </Grid>
                                        }
                                        {
                                            ['registeredPartnership', 'married'].includes(watch("maritalStatus")?.value) &&
                                            <Grid item xs={12} sm={6}>
                                                <Typography
                                                    variant="subtitle1"
                                                    color="textPrimary"
                                                    className={classes.inputTitle}
                                                >
                                                    {`‘Upload’ prueba di ${watch("maritalStatus")?.value === 'married' ? 'matrimonio òf buki di matrimonio' : 'konbibensia legalisá di pareha'}`}
                                                </Typography>
                                                <Controller
                                                    classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                                    as={DropzoneArea}
                                                    dropzoneText={`‘Upload’ prueba di ${watch("maritalStatus")?.value === 'married' ? 'matrimonio òf buki di matrimonio' : 'konbibensia legalisá di pareha'} (image/* òf .pdf)`}
                                                    name="proofOfMarriage"
                                                    label="Prueba di matrimonio"
                                                    control={control}
                                                    error={errors.proofOfMarriage ? true : false}
                                                    onDrop={(files) => onDropHandler(files, watch("maritalStatus")?.value === 'married' ? 3 : 4)}
                                                    onDelete={(file) => onDeleteHandler(file, watch("maritalStatus")?.value === 'married' ? 3 : 4)}
                                                    filesLimit={1}
                                                    acceptedFiles={['image/*', '.pdf']}
                                                    showPreviews={true}
                                                    showPreviewsInDropzone={false}
                                                    useChipsForPreview
                                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                    previewChipProps={{ classes: { root: classes.previewChip } }}
                                                    previewText="Selected files"
                                                />
                                                {errors.proofOfMarriage && <p style={{ color: '#bf1650' }}>{errors.proofOfMarriage.message}</p>}
                                            </Grid>
                                        }
                                        {watch("maritalStatus")?.value === 'widow' &&
                                            <Grid item xs={12} sm={6}>
                                                <Typography
                                                    variant="subtitle1"
                                                    color="textPrimary"
                                                    className={classes.inputTitle}
                                                >
                                                    ‘Upload’ e prueba di fayesementu di bo kasá
                                                </Typography>
                                                <Controller
                                                    classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                                    as={DropzoneArea}
                                                    dropzoneText="‘Upload’ e prueba di fayesementu di bo kasá(image/* òf .pdf)"
                                                    name="proofOfDeath"
                                                    label="Prueba di matrimonio"
                                                    control={control}
                                                    error={errors.proofOfDeath ? true : false}
                                                    onDrop={(files) => onDropHandler(files, 6)}
                                                    onDelete={(file) => onDeleteHandler(file, 6)}
                                                    filesLimit={1}
                                                    acceptedFiles={['image/*', '.pdf']}
                                                    showPreviews={true}
                                                    showPreviewsInDropzone={false}
                                                    useChipsForPreview
                                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                    previewChipProps={{ classes: { root: classes.previewChip } }}
                                                    previewText="Selected files"
                                                />
                                                {errors.proofOfDeath && <p style={{ color: '#bf1650' }}>{errors.proofOfDeath.message}</p>}
                                            </Grid>
                                        }

                                    </> : null
                            }
                            {
                                watch("maritalStatus")?.value === 'single' &&
                                (<Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="livingTogether"
                                        error={errors.children ? true : false}
                                        render={({ onChange, value }) => (
                                            <>
                                                <Typography
                                                    variant="subtitle1"
                                                    color="textPrimary"
                                                    className={classes.inputTitle}
                                                >
                                                    Bo ta biba huntu ku bo pareha?
                                                </Typography>
                                                <RadioGroup aria-label="livingTogether" name="livingTogether" value={JSON.parse(value)} onChange={onChange} row>
                                                    <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                    <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                                </RadioGroup>
                                            </>
                                        )}
                                    />
                                    {errors.livingTogether && <p style={{ color: '#bf1650' }}>{errors.livingTogether.message}</p>}
                                </Grid>)
                            }
                            {
                                watch('livingTogether') && JSON.parse(watch('livingTogether')) && (
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Na kua adrès boso ta biba?
                                            </Typography>
                                            <AddressSelect
                                                name="livingTogetherAddress"
                                                label="Na kua adrès boso ta biba"
                                                control={control}
                                                required={true}
                                                error={errors.livingTogetherAddress ? true : false}
                                            />
                                            {errors.livingTogetherAddress && <p style={{ color: '#bf1650' }}>{errors.livingTogetherAddress.message}</p>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Na kua number di adrès boso ta biba?
                                            </Typography>
                                            <Controller
                                                as={TextField}
                                                id="livingTogetherAddressNumber"
                                                name="livingTogetherAddressNumber"
                                                label="Na kua number di adrès boso ta biba? "
                                                variant="outlined"
                                                required
                                                fullWidth
                                                error={errors.livingTogetherAddressNumber ? true : false}
                                                control={control}
                                            />
                                            {errors.livingTogetherAddressNumber && <p style={{ color: '#bf1650' }}>{errors.livingTogetherAddressNumber.message}</p>}
                                        </Grid>
                                    </>
                                )
                            }

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="hasChildren"
                                    error={errors.children ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                19. Bo tin yu?
                                            </Typography>
                                            <RadioGroup aria-label="hasChildren" name="hasChildren" value={JSON.parse(value)} onChange={onChange} row>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasChildren && <p style={{ color: '#bf1650' }}>{errors.hasChildren.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasChildren')) ?
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Yu propio
                                            </Typography>
                                            <Controller
                                                control={control}
                                                as={
                                                    <Select>
                                                        <MenuItem value={0}>0</MenuItem>
                                                        <MenuItem value={1}>1</MenuItem>
                                                        <MenuItem value={2}>2</MenuItem>
                                                        <MenuItem value={3}>3</MenuItem>
                                                        <MenuItem value={4}>4</MenuItem>
                                                        <MenuItem value={5}>5</MenuItem>
                                                        <MenuItem value={6}>6</MenuItem>
                                                        <MenuItem value={7}>7</MenuItem>
                                                        <MenuItem value={8}>8</MenuItem>
                                                        <MenuItem value={9}>9</MenuItem>

                                                    </Select>
                                                }
                                                variant="outlined"
                                                fullWidth
                                                name="ownChildren"
                                                error={errors.ownChildren ? true : false}
                                            />
                                            {errors.ownChildren && <p style={{ color: '#bf1650' }}>{errors.ownChildren.message}</p>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                ‘Upload’ akto di nasementu di bo yu(nan)/buki di famia
                                            </Typography>
                                            <Controller
                                                classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                                as={DropzoneArea}
                                                name="proofOfChildren"
                                                dropzoneText="‘Upload’ akto di nasementu di bo yu(nan)/buki di famia (image/* òf .pdf)"
                                                control={control}
                                                error={errors.proofOfChildren ? true : false}
                                                onDrop={(files) => onDropHandler(files, 7)}
                                                onDelete={(file) => onDeleteHandler(file, 7)}
                                                filesLimit={1}
                                                acceptedFiles={['image/*', '.pdf']}
                                                showPreviews={true}
                                                showPreviewsInDropzone={false}
                                                useChipsForPreview
                                                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                previewChipProps={{ classes: { root: classes.previewChip } }}
                                                previewText="Selected files"
                                            />
                                            {errors.proofOfChildren && <p style={{ color: '#bf1650' }}>{errors.proofOfChildren.message}</p>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Yu di kriansa
                                            </Typography>
                                            <Controller
                                                control={control}
                                                as={
                                                    <Select>
                                                        <MenuItem value={0}>0</MenuItem>
                                                        <MenuItem value={1}>1</MenuItem>
                                                        <MenuItem value={2}>2</MenuItem>
                                                        <MenuItem value={3}>3</MenuItem>
                                                        <MenuItem value={4}>4</MenuItem>
                                                        <MenuItem value={5}>5</MenuItem>
                                                        <MenuItem value={6}>6</MenuItem>
                                                        <MenuItem value={7}>7</MenuItem>
                                                        <MenuItem value={8}>8</MenuItem>
                                                        <MenuItem value={9}>9</MenuItem>

                                                    </Select>
                                                }
                                                variant="outlined"
                                                fullWidth
                                                name="notOwnChildren"
                                                error={errors.notOwnChildren ? true : false}
                                            />
                                            {errors.notOwnChildren && <p style={{ color: '#bf1650' }}>{errors.notOwnChildren.message}</p>}
                                        </Grid>
                                    </> :
                                    null
                            }
                            <Grid item xs={12}>
                                <Typography variant="h6" color="primary" className={classes.title}>
                                    Entrada aktual
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="hasIncome"
                                    error={errors.children ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                20. Bo tin entrada aktualmente?
                                            </Typography>
                                            <RadioGroup aria-label="hasIncome" name="hasIncome" value={JSON.parse(value)} onChange={onChange} row>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasIncome && <p style={{ color: '#bf1650' }}>{errors.hasIncome.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasIncome')) &&
                                <>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary"
                                            className={classes.inputTitle}
                                        >
                                            Kon bo a yega na e entrada aki ?
                                        </Typography>
                                        <Controller
                                            control={control}
                                            as={
                                                <Select>
                                                    <MenuItem value="freelance">Mi ta kue djòp</MenuItem>
                                                    <MenuItem value="employee">Mi ta traha na un kompania</MenuItem>
                                                    <MenuItem value="selfEmployed">Mi ta traha riba mi mes (lora man)</MenuItem>
                                                </Select>
                                            }
                                            variant="outlined"
                                            fullWidth
                                            name="work"
                                            error={errors.work ? true : false}
                                        />
                                        {errors.work && <p style={{ color: '#bf1650' }}>{errors.work.message}</p>}
                                    </Grid>
                                    {
                                        ['freelance', 'employee'].includes(watch('work')) &&
                                        <>
                                            {
                                                watch('work') === 'freelance' ?
                                                    <Grid item xs={12}>
                                                        <Controller
                                                            control={control}
                                                            name="contractee"

                                                            render={({ onChange, value, errors }) => (
                                                                <>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        color="textPrimary"
                                                                        className={classes.inputTitle}
                                                                    >
                                                                        Mi ta kue djòp
                                                                    </Typography>
                                                                    <RadioGroup aria-label="contractee" name="contractee" value={value} onChange={onChange} row>
                                                                        <FormControlLabel value="person" control={<Radio />} label="serka un persona" />
                                                                        <FormControlLabel value="company" control={<Radio />} label="na un kompania" />
                                                                    </RadioGroup>
                                                                </>
                                                            )}
                                                        />
                                                        {errors.contractee && <p style={{ color: '#bf1650' }}>{errors.contractee.message}</p>}
                                                    </Grid> : null
                                            }
                                            {
                                                (['company', 'person'].includes(watch('contractee')) || watch('work') === 'employee') &&
                                                <>
                                                    {
                                                        (watch('contractee') === 'company' || watch('work') === 'employee') ?
                                                            <Grid item xs={12} sm={6}>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    color="textPrimary"
                                                                    className={classes.inputTitle}
                                                                >
                                                                    Nòmber di kompania
                                                                </Typography>
                                                                <Controller
                                                                    as={TextField}
                                                                    id="employerCompanyName"
                                                                    name="employerCompanyName"
                                                                    label="Nòmber di kompania"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    control={control}
                                                                    error={errors.employerCompanyName ? true : false}
                                                                />
                                                                {errors.employerCompanyName && <p style={{ color: '#bf1650' }}>{errors.employerCompanyName.message}</p>}
                                                            </Grid> : null
                                                    }
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="textPrimary"
                                                            className={classes.inputTitle}
                                                        >
                                                            Nòmber i fam di e persona  dunadó di trabou
                                                        </Typography>
                                                        <Controller
                                                            as={TextField}
                                                            id="employerName"
                                                            name="employerName"
                                                            label="Nòmber i fam di e persona dunadó di trabou"
                                                            variant="outlined"
                                                            fullWidth
                                                            control={control}
                                                            error={errors.employerName ? true : false}
                                                        />
                                                        {errors.employerName && <p style={{ color: '#bf1650' }}>{errors.employerName.message}</p>}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="textPrimary"
                                                            className={classes.inputTitle}
                                                        >
                                                            Adrès di e trabou
                                                        </Typography>
                                                        <Controller
                                                            as={TextField}
                                                            id="employerAddress"
                                                            name="employerAddress"
                                                            label="Adrès di e trabou"
                                                            variant="outlined"
                                                            fullWidth
                                                            control={control}
                                                            error={errors.employerAddress ? true : false}
                                                        />
                                                        {errors.employerAddress && <p style={{ color: '#bf1650' }}>{errors.employerAddress.message}</p>}
                                                    </Grid>

                                                </>
                                            }
                                        </>
                                    }
                                    {
                                        ['freelance', 'employee', 'selfEmployed'].includes(watch('work')) &&
                                        <>
                                            <Grid item xs={12} sm={6}>
                                                <Typography
                                                    variant="subtitle1"
                                                    color="textPrimary"
                                                    className={classes.inputTitle}
                                                >
                                                    {`Ki tipo di ${watch('work') === 'freelance' ? 'djòp bo ta kue' : 'trabou bo ta hasi'}?`}
                                                </Typography>
                                                <Controller
                                                    as={TextField}
                                                    id="employerJobType"
                                                    name="employerJobType"
                                                    label={`Ki tipo di ${watch('work') === 'freelance' ? 'djòp bo ta kue' : 'trabou bo ta hasi'}?`}
                                                    variant="outlined"
                                                    fullWidth
                                                    control={control}
                                                    error={errors.employerJobType ? true : false}
                                                />
                                                {errors.employerJobType && <p style={{ color: '#bf1650' }}>{errors.employerJobType.message}</p>}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography
                                                    variant="subtitle1"
                                                    color="textPrimary"
                                                    className={classes.inputTitle}
                                                >
                                                    Kuantu sèn bo tabata risibí pa bo trabou?
                                                </Typography>
                                                <Controller
                                                    as={TextField}
                                                    id="employerSalary"
                                                    name="employerSalary"
                                                    label="Kuantu sèn bo tabata risibí pa bo trabou?"
                                                    variant="outlined"
                                                    fullWidth
                                                    control={control}
                                                    error={errors.employerSalary ? true : false}
                                                />
                                                {errors.employerSalary && <p style={{ color: '#bf1650' }}>{errors.employerSalary.message}</p>}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Controller
                                                    control={control}
                                                    name="employerPayFrequency"
                                                    error={errors.employerPayFrequency ? true : false}
                                                    render={({ onChange, value }) => (
                                                        <>
                                                            <Typography
                                                                variant="subtitle1"
                                                                color="textPrimary"
                                                                className={classes.inputTitle}
                                                            >
                                                                Ku ki frekuensia bo ta risibí e sèn aki?
                                                            </Typography>
                                                            <RadioGroup aria-label="employerPayFrequency" name="employerPayFrequency" value={value} onChange={onChange} row>
                                                                <FormControlLabel value="daily" control={<Radio />} label="Pa dia" />
                                                                <FormControlLabel value="weekly" control={<Radio />} label="Pa siman" />
                                                                <FormControlLabel value="fortnight" control={<Radio />} label="Pa kinsena" />
                                                                <FormControlLabel value="monthly" control={<Radio />} label="Pa luna" />
                                                            </RadioGroup>
                                                        </>
                                                    )}
                                                />
                                                {errors.employerPayFrequency && <p style={{ color: '#bf1650' }}>{errors.employerPayFrequency.message}</p>}
                                            </Grid>
                                            {
                                                watch('work') === "employee" &&
                                                <>
                                                    <Grid item xs={12} sm={6}>
                                                        <Controller
                                                            control={control}
                                                            name="hasContract"
                                                            error={errors.hasContract ? true : false}
                                                            render={({ onChange, value }) => (
                                                                <>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        color="textPrimary"
                                                                        className={classes.inputTitle}
                                                                    >
                                                                        Bo tin un kòntrakt di trabou?
                                                                    </Typography>
                                                                    <RadioGroup aria-label="hasContract" name="hasContract" value={JSON.parse(value)} onChange={onChange} row>
                                                                        <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                                        <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                                                    </RadioGroup>
                                                                </>
                                                            )}
                                                        />
                                                        {errors.hasContract && <p style={{ color: '#bf1650' }}>{errors.hasContract.message}</p>}
                                                    </Grid>
                                                    {
                                                        JSON.parse(watch('hasContract')) &&
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography
                                                                variant="subtitle1"
                                                                color="textPrimary"
                                                                className={classes.inputTitle}
                                                            >
                                                                ‘Upload’ e kòntrakt
                                                            </Typography>
                                                            <Controller
                                                                classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                                                as={DropzoneArea}
                                                                name="proofOfContract"
                                                                dropzoneText="‘Upload’ e kòntrakt (image/* òf .pdf)"
                                                                control={control}
                                                                error={errors.proofOfContract ? true : false}
                                                                onDrop={(files) => onDropHandler(files, 20)}
                                                                onDelete={(file) => onDeleteHandler(file, 20)}
                                                                filesLimit={1}
                                                                acceptedFiles={['image/*', '.pdf']}
                                                                showPreviews={true}
                                                                showPreviewsInDropzone={false}
                                                                useChipsForPreview
                                                                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                                previewChipProps={{ classes: { root: classes.previewChip } }}
                                                                previewText="Selected files"
                                                            />
                                                            {errors.proofOfContract && <p style={{ color: '#bf1650' }}>{errors.proofOfContract.message}</p>}
                                                        </Grid>
                                                    }

                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                            <Grid item xs={12}>
                                <Typography variant="h6" color="primary" className={classes.title}>
                                    Motibu di Petishon
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    21. Motibu di petishon
                                </Typography>
                                <Controller
                                    control={control}
                                    as={
                                        <Select>
                                            <MenuItem value="lostJob">Pèrdè trabou</MenuItem>
                                            <MenuItem value="cannotWork">No por traha</MenuItem>
                                            <MenuItem value="other">Otro</MenuItem>
                                        </Select>
                                    }
                                    variant="outlined"
                                    fullWidth
                                    name="reason"
                                    error={errors.reason ? true : false}
                                />
                                {errors.reason && <p style={{ color: '#bf1650' }}>{errors.reason.message}</p>}
                            </Grid>
                            {
                                watch("reason") === 'lostJob' ?
                                    <>
                                        <Grid item xs={12}>
                                            <Controller
                                                control={control}
                                                name="lastWork"
                                                error={errors.lastWork ? true : false}
                                                render={({ onChange, value }) => (
                                                    <>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="textPrimary"
                                                            className={classes.inputTitle}
                                                        >
                                                            Último bia ku bo a traha tabata pa kua persona òf kompania?
                                                        </Typography>
                                                        <RadioGroup aria-label="lastWork" name="lastWork" value={value} onChange={onChange} row>
                                                            <FormControlLabel value="person" control={<Radio />} label="Persona" />
                                                            <FormControlLabel value="company" control={<Radio />} label="Kompania" />
                                                        </RadioGroup>
                                                    </>
                                                )}
                                            />
                                            {errors.lastWork && <p style={{ color: '#bf1650' }}>{errors.lastWork.message}</p>}
                                        </Grid>
                                        {
                                            ['person', 'company'].includes(watch("lastWork")) ?
                                                <>
                                                    {
                                                        watch("lastWork") === 'company' &&
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography
                                                                variant="subtitle1"
                                                                color="textPrimary"
                                                                className={classes.inputTitle}
                                                            >
                                                                Nòmber di kompania
                                                            </Typography>
                                                            <Controller
                                                                as={TextField}
                                                                id="lastEmployerCompanyName"
                                                                name="lastEmployerCompanyName"
                                                                label="Nòmber di kompania"
                                                                variant="outlined"
                                                                fullWidth
                                                                control={control}
                                                                error={errors.lastEmployerCompanyName ? true : false}
                                                            />
                                                            {errors.lastEmployerCompanyName && <p style={{ color: '#bf1650' }}>{errors.lastEmployerCompanyName.message}</p>}
                                                        </Grid>
                                                    }
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="textPrimary"
                                                            className={classes.inputTitle}
                                                        >
                                                            Nòmber i fam di èks dunadó di trabou
                                                        </Typography>
                                                        <Controller
                                                            as={TextField}
                                                            id="lastEmployerName"
                                                            name="lastEmployerName"
                                                            label="Nòmber i fam di èks dunadó di trabou"
                                                            variant="outlined"
                                                            fullWidth
                                                            control={control}
                                                            error={errors.lastEmployerName ? true : false}
                                                        />
                                                        {errors.lastEmployerName && <p style={{ color: '#bf1650' }}>{errors.lastEmployerName.message}</p>}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="textPrimary"
                                                            className={classes.inputTitle}
                                                        >
                                                            Adrès di e último lugá di trabou
                                                        </Typography>
                                                        <Controller
                                                            as={TextField}
                                                            id="lastEmployerAddress"
                                                            name="lastEmployerAddress"
                                                            label="Adrès di e último lugá di trabou"
                                                            variant="outlined"
                                                            fullWidth
                                                            control={control}
                                                            error={errors.lastEmployerAddress ? true : false}
                                                        />
                                                        {errors.lastEmployerAddress && <p style={{ color: '#bf1650' }}>{errors.lastEmployerAddress.message}</p>}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="textPrimary"
                                                            className={classes.inputTitle}
                                                        >
                                                            Ki tipo di trabou bo a traha último biaha?
                                                        </Typography>
                                                        <Controller
                                                            as={TextField}
                                                            id="lastEmployerWorkType"
                                                            name="lastEmployerWorkType"
                                                            label="Ki tipo di trabou bo a traha último biaha?"
                                                            variant="outlined"
                                                            fullWidth
                                                            control={control}
                                                            error={errors.lastEmployerWorkType ? true : false}
                                                        />
                                                        {errors.lastEmployerWorkType && <p style={{ color: '#bf1650' }}>{errors.lastEmployerWorkType.message}</p>}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="textPrimary"
                                                            className={classes.inputTitle}
                                                        >
                                                            Kuantu tempu pasá esaki tabata?
                                                        </Typography>
                                                        <Controller
                                                            as={TextField}
                                                            id="lastEmployerTimeAgo"
                                                            name="lastEmployerTimeAgo"
                                                            label="Kuantu tempu pasá esaki tabata?"
                                                            variant="outlined"
                                                            fullWidth
                                                            control={control}
                                                            error={errors.lastEmployerTimeAgo ? true : false}
                                                        />
                                                        {errors.lastEmployerTimeAgo && <p style={{ color: '#bf1650' }}>{errors.lastEmployerTimeAgo.message}</p>}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="textPrimary"
                                                            className={classes.inputTitle}
                                                        >
                                                            Si bo tin mas ku 1 aña ku bo a pèrdè bo trabou, ‘upload’ bo deklarashon di entrada.
                                                        </Typography>
                                                        <Controller
                                                            classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                                            as={DropzoneArea}
                                                            name="proofOfIncomeLastEmployer"
                                                            dropzoneText="‘Upload’ bo deklarashon di entrada (image/* òf .pdf)"
                                                            control={control}
                                                            error={errors.proofOfIncomeLastEmployer ? true : false}
                                                            onDrop={(files) => onDropHandler(files, 15)}
                                                            onDelete={(file) => onDeleteHandler(file, 15)}
                                                            filesLimit={1}
                                                            acceptedFiles={['image/*', '.pdf']}
                                                            showPreviews={true}
                                                            showPreviewsInDropzone={false}
                                                            useChipsForPreview
                                                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                            previewChipProps={{ classes: { root: classes.previewChip } }}
                                                            previewText="Selected files"
                                                        />
                                                        {errors.proofOfIncomeLastEmployer && <p style={{ color: '#bf1650' }}>{errors.proofOfIncomeLastEmployer.message}</p>}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="textPrimary"
                                                            className={classes.inputTitle}
                                                        >
                                                            Kuantu sèn bo tabata gana na bo último trabou?
                                                        </Typography>
                                                        <Controller
                                                            as={TextField}
                                                            id="lastEmployerSalary"
                                                            name="lastEmployerSalary"
                                                            label="Kuantu sèn bo tabata gana na bo último trabou?"
                                                            variant="outlined"
                                                            fullWidth
                                                            control={control}
                                                            error={errors.lastEmployerSalary ? true : false}
                                                        />
                                                        {errors.lastEmployerSalary && <p style={{ color: '#bf1650' }}>{errors.lastEmployerSalary.message}</p>}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Controller
                                                            control={control}
                                                            name="lastEmployerPayFrequency"
                                                            error={errors.lastEmployerPayFrequency ? true : false}
                                                            render={({ onChange, value }) => (
                                                                <>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        color="textPrimary"
                                                                        className={classes.inputTitle}
                                                                    >
                                                                        Ku ki frekuensia bo ta risibí e sèn aki?
                                                                    </Typography>
                                                                    <RadioGroup aria-label="lastEmployerPayFrequency" name="lastEmployerPayFrequency" value={value} onChange={onChange} row>
                                                                        <FormControlLabel value="daily" control={<Radio />} label="Pa dia" />
                                                                        <FormControlLabel value="weekly" control={<Radio />} label="Pa siman" />
                                                                        <FormControlLabel value="fortnight" control={<Radio />} label="Pa kinsena" />
                                                                        <FormControlLabel value="monthly" control={<Radio />} label="Pa luna" />
                                                                    </RadioGroup>
                                                                </>
                                                            )}
                                                        />
                                                        {errors.lastEmployerPayFrequency && <p style={{ color: '#bf1650' }}>{errors.lastEmployerPayFrequency.message}</p>}
                                                    </Grid>
                                                </>

                                                :
                                                null
                                        }
                                    </>

                                    : watch("reason") === 'cannotWork' &&
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Kiko ta e motibu ku bo no por traha?
                                            </Typography>
                                            <Controller
                                                as={TextField}
                                                id="reasonCannotWork"
                                                name="reasonCannotWork"
                                                label="Kiko ta e motibu ku bo no por traha?"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                minRows={4}
                                                control={control}
                                                error={errors.reasonCannotWork ? true : false}
                                            />
                                            {errors.reasonCannotWork && <p style={{ color: '#bf1650' }}>{errors.reasonCannotWork.message}</p>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                ‘Upload’ prueba ku bo no por traha
                                            </Typography>
                                            <Controller
                                                classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                                as={DropzoneArea}
                                                name="proofOfCannotWork"
                                                dropzoneText="‘Upload’ prueba ku bo no por traha (image/* òf .pdf)"
                                                control={control}
                                                error={errors.proofOfCannotWork ? true : false}
                                                onDrop={(files) => onDropHandler(files, 9)}
                                                onDelete={(file) => onDeleteHandler(file, 9)}
                                                filesLimit={1}
                                                acceptedFiles={['image/*', '.pdf']}
                                                showPreviews={true}
                                                showPreviewsInDropzone={false}
                                                useChipsForPreview
                                                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                previewChipProps={{ classes: { root: classes.previewChip } }}
                                                previewText="Selected files"
                                            />
                                            {errors.proofOfCannotWork && <p style={{ color: '#bf1650' }}>{errors.proofOfCannotWork.message}</p>}
                                        </Grid>
                                    </>
                            }
                            {
                                ['cannotWork', 'other', 'lostJob'].includes(watch('reason')) &&
                                <Grid item xs={12}>
                                    <Typography
                                        variant="subtitle1"
                                        color="textPrimary"
                                        className={classes.inputTitle}
                                    >
                                        Por fabor amplia bo motibu di petishon
                                    </Typography>
                                    <Controller
                                        as={TextField}
                                        id="otherReason"
                                        name="otherReason"
                                        label="Por fabor amplia bo motibu di petishon"
                                        variant="outlined"
                                        multiline
                                        minRows={2}
                                        fullWidth
                                        control={control}
                                        error={errors.otherReason ? true : false}
                                    />
                                    {errors.otherReason && <p style={{ color: '#bf1650' }}>{errors.otherReason.message}</p>}
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="activelyJobSeeking"
                                    error={errors.activelyJobSeeking ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                22. Bo ta buska trabou aktivamente?
                                            </Typography>
                                            <RadioGroup aria-label="activelyJobSeeking" name="activelyJobSeeking" value={JSON.parse(value)} onChange={onChange} row>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.activelyJobSeeking && <p style={{ color: '#bf1650' }}>{errors.activelyJobSeeking.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('activelyJobSeeking')) ?
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Kon bo ta solisitá?
                                            </Typography>
                                            <Controller
                                                as={TextField}
                                                id="jobSeekingMethod"
                                                name="jobSeekingMethod"
                                                label="Kon bo ta solisitá?"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                minRows={4}
                                                control={control}
                                                error={errors.jobSeekingMethod ? true : false}
                                            />
                                            {errors.jobSeekingMethod && <p style={{ color: '#bf1650' }}>{errors.jobSeekingMethod.message}</p>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                ‘Upload’ bo karta(nan) di solisitut
                                            </Typography>
                                            <Controller
                                                classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                                as={DropzoneArea}
                                                name="proofOfJobSeeking"
                                                dropzoneText="‘Upload’ bo karta(nan) di solisitut (image/* òf .pdf)"
                                                control={control}
                                                error={errors.proofOfJobSeeking ? true : false}
                                                onDrop={(files) => onDropHandler(files, 8)}
                                                onDelete={(file) => onDeleteHandler(file, 8)}
                                                acceptedFiles={['image/*', '.pdf']}
                                                showPreviews={true}
                                                showPreviewsInDropzone={false}
                                                useChipsForPreview
                                                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                previewChipProps={{ classes: { root: classes.previewChip } }}
                                                previewText="Selected files"
                                            />
                                            {errors.proofOfJobSeeking && <p style={{ color: '#bf1650' }}>{errors.proofOfJobSeeking.message}</p>}
                                        </Grid>
                                    </>
                                    : watch('activelyJobSeeking') !== null ?
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Dikon nò?
                                            </Typography>
                                            <Controller
                                                as={TextField}
                                                id="reasonNoJobSeeking"
                                                name="reasonNoJobSeeking"
                                                label="Dikon nò?"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                minRows={2}
                                                control={control}
                                                error={errors.reasonNoJobSeeking ? true : false}
                                            />
                                            {errors.reasonNoJobSeeking && <p style={{ color: '#bf1650' }}>{errors.reasonNoJobSeeking.message}</p>}
                                        </Grid>
                                        : null
                            }
                            <Grid item xs={12}>
                                <Typography variant="h6" color="primary" className={classes.title}>
                                    Propiedat
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
                                    23. Kiko ta bo propiedatnan
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="hasVehicle"
                                    error={errors.hasVehicle ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Bo tin vehíkulo?
                                            </Typography>
                                            <RadioGroup aria-label="hasVehicle" name="hasVehicle" value={JSON.parse(value)} onChange={onChange} row>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasVehicle && <p style={{ color: '#bf1650' }}>{errors.hasVehicle.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasVehicle')) &&
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary"
                                            className={classes.inputTitle}
                                        >
                                            Kiko ta e tipo di vehíkulo
                                        </Typography>
                                        <Controller
                                            as={TextField}
                                            id="vehicle"
                                            name="vehicle"
                                            label="Kiko ta e tipo di vehíkulo"
                                            variant="outlined"
                                            fullWidth
                                            control={control}
                                            error={errors.vehicle ? true : false}
                                        />
                                        {errors.vehicle && <p style={{ color: '#bf1650' }}>{errors.vehicle.message}</p>}
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary"
                                            className={classes.inputTitle}
                                        >
                                            Kiko ta e plachi number?
                                        </Typography>
                                        <Controller
                                            as={TextField}
                                            id="numberPlate"
                                            name="numberPlate"
                                            label="Kiko ta e plachi number?"
                                            variant="outlined"
                                            fullWidth
                                            control={control}
                                            error={errors.numberPlate ? true : false}
                                        />
                                        {errors.numberPlate && <p style={{ color: '#bf1650' }}>{errors.numberPlate.message}</p>}
                                    </Grid>
                                </>
                            }

                            <Grid item xs={12} sm={JSON.parse(watch('hasBoat')) ? 6 : false}>
                                <Controller
                                    control={control}
                                    name="hasBoat"
                                    error={errors.hasBoat ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Bo tin boto
                                            </Typography>
                                            <RadioGroup aria-label="hasBoat" name="hasBoat" value={JSON.parse(value)} onChange={onChange} row>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasBoat && <p style={{ color: '#bf1650' }}>{errors.hasBoat.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasBoat')) ?
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary"
                                            className={classes.inputTitle}
                                        >
                                            Kiko ta su datos di registrashon?
                                        </Typography>
                                        <Controller
                                            as={TextField}
                                            id="boatInformation"
                                            name="boatInformation"
                                            label="Kiko ta su datos di registrashon?"
                                            variant="outlined"
                                            fullWidth
                                            control={control}
                                            error={errors.boatInformation ? true : false}
                                        />
                                        {errors.boatInformation && <p style={{ color: '#bf1650' }}>{errors.boatInformation.message}</p>}
                                    </Grid> : null
                            }
                            <Grid item xs={12} sm={JSON.parse(watch('hasRentedHouse')) ? 6 : false}>
                                <Controller
                                    control={control}
                                    name="hasRentedHouse"
                                    error={errors.hasRentedHouse ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Bo tin kas ta hür
                                            </Typography>
                                            <RadioGroup aria-label="hasRentedHouse" name="hasRentedHouse" value={JSON.parse(value)} onChange={onChange} row>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasRentedHouse && <p style={{ color: '#bf1650' }}>{errors.hasRentedHouse.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasRentedHouse')) ?
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary"
                                            className={classes.inputTitle}
                                        >
                                            Kuantu esaki ta generá pa luna?
                                        </Typography>
                                        <Controller
                                            as={TextField}
                                            id="rentalMonthlyPrice"
                                            name="rentalMonthlyPrice"
                                            label="Kuantu esaki ta generá pa luna?"
                                            variant="outlined"
                                            fullWidth
                                            control={control}
                                            error={errors.rentalMonthlyPrice ? true : false}
                                        />
                                        {errors.rentalMonthlyPrice && <p style={{ color: '#bf1650' }}>{errors.rentalMonthlyPrice.message}</p>}
                                    </Grid> :
                                    null
                            }
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="hasBankAccount"
                                    error={errors.hasBankAccount ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Bo tin kuenta di banko?
                                            </Typography>
                                            <RadioGroup aria-label="hasBankAccount" name="hasBankAccount" value={JSON.parse(value)} onChange={onChange} row>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasBankAccount && <p style={{ color: '#bf1650' }}>{errors.hasBankAccount.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasBankAccount')) &&
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="bankAccountType"
                                        error={errors.bankAccountType ? true : false}
                                        render={({ onChange, value }) => (
                                            <>
                                                <Typography
                                                    variant="subtitle1"
                                                    color="textPrimary"
                                                    className={classes.inputTitle}
                                                >
                                                    Ki tipo di kuenta di banko bo tin?
                                                </Typography>
                                                <RadioGroup aria-label="bankAccountType" name="bankAccountType" value={value} onChange={onChange} row>
                                                    <FormControlLabel value="current" control={<Radio />} label="kuenta di koriente" />
                                                    <FormControlLabel value="savings" control={<Radio />} label="kuenta di spar" />
                                                    <FormControlLabel value="both" control={<Radio />} label="tur dos" />
                                                </RadioGroup>
                                            </>
                                        )}
                                    />
                                    {errors.bankAccountType && <p style={{ color: '#bf1650' }}>{errors.bankAccountType.message}</p>}
                                </Grid>
                            }
                            {
                                (watch('bankAccountType') === 'current' || watch('bankAccountType') === 'both') &&
                                <Grid item xs={12} sm={(watch('bankAccountType') === 'savings' || watch('bankAccountType') === 'both') ? 6 : false}>
                                    <Typography
                                        variant="subtitle1"
                                        color="textPrimary"
                                        className={classes.inputTitle}
                                    >
                                        Kuenta koriente di bo banko
                                    </Typography>
                                    <Controller
                                        classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                        as={DropzoneArea}
                                        name="currentAccountStatements"
                                        dropzoneText="‘Upload’ último dos statement di bo kuenta koriente (image/* òf .pdf)"
                                        control={control}
                                        error={errors.currentAccountStatements ? true : false}
                                        onDrop={(files) => onDropHandler(files, 10)}
                                        onDelete={(file) => onDeleteHandler(file, 10)}
                                        //filesLimit={1}
                                        acceptedFiles={['image/*', '.pdf']}
                                        showPreviews={true}
                                        showPreviewsInDropzone={false}
                                        useChipsForPreview
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        previewChipProps={{ classes: { root: classes.previewChip } }}
                                        previewText="Selected files"
                                    />
                                    {errors.currentAccountStatements && <p style={{ color: '#bf1650' }}>{errors.currentAccountStatements.message}</p>}
                                </Grid>
                            }
                            {
                                (watch('bankAccountType') === 'savings' || watch('bankAccountType') === 'both') &&
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant="subtitle1"
                                        color="textPrimary"
                                        className={classes.inputTitle}
                                    >
                                        Kuenta di spar di bo banko
                                    </Typography>
                                    <Controller
                                        classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                        as={DropzoneArea}
                                        name="savingsAccountStatements"
                                        dropzoneText="‘Upload’ último dos statement di bo kuenta di spar(image/* òf .pdf)"
                                        control={control}
                                        error={errors.savingsAccountStatements ? true : false}
                                        onDrop={(files) => onDropHandler(files, 11)}
                                        onDelete={(file) => onDeleteHandler(file, 11)}
                                        acceptedFiles={['image/*', '.pdf']}
                                        showPreviews={true}
                                        showPreviewsInDropzone={false}
                                        useChipsForPreview
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        previewChipProps={{ classes: { root: classes.previewChip } }}
                                        previewText="Selected files"
                                    />
                                    {errors.savingsAccountStatements && <p style={{ color: '#bf1650' }}>{errors.savingsAccountStatements.message}</p>}
                                </Grid>
                            }

                            <Grid item xs={12} sm={JSON.parse(watch('hasMoreSourceOfIncome')) ? 6 : false}>
                                <Controller
                                    control={control}
                                    name="hasMoreSourceOfIncome"
                                    error={errors.hasMoreSourceOfIncome ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Bo tin mas fuente di entrada?
                                            </Typography>
                                            <RadioGroup aria-label="hasMoreSourceOfIncome" name="hasMoreSourceOfIncome" value={JSON.parse(value)} onChange={onChange} row>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasMoreSourceOfIncome && <p style={{ color: '#bf1650' }}>{errors.hasMoreSourceOfIncome.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasMoreSourceOfIncome')) ?
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary"
                                            className={classes.inputTitle}
                                        >
                                            Por fabor spesifiká esaki
                                        </Typography>
                                        <Controller
                                            as={TextField}
                                            id="moreSourceOfIncome"
                                            name="moreSourceOfIncome"
                                            label="Por fabor spesifiká esaki"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            minRows={3}
                                            control={control}
                                            error={errors.moreSourceOfIncome ? true : false}
                                        />
                                        {errors.moreSourceOfIncome && <p style={{ color: '#bf1650' }}>{errors.moreSourceOfIncome.message}</p>}
                                    </Grid> :
                                    null
                            }
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="hasOwnHouse"
                                    //error={errors.hasOwnHouse ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                24. Bo ta biba den bo mes kas?
                                            </Typography>
                                            <RadioGroup aria-label="hasOwnHouse" name="hasOwnHouse" value={JSON.parse(value)} onChange={onChange} row>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasOwnHouse && <p style={{ color: '#bf1650' }}>{errors.hasOwnHouse.message}</p>}
                            </Grid>
                            {

                                JSON.parse(watch('hasOwnHouse')) !== null &&
                                <>
                                    {
                                        !JSON.parse(watch('hasOwnHouse')) &&
                                        <Grid item xs={12}>
                                            <Controller
                                                control={control}
                                                as={
                                                    <RadioGroup aria-label="notOwnHouse" name="notOwnHouse" row>
                                                        <FormControlLabel value='inherit' control={<Radio />} label="Mi a heredá e kas" />
                                                        <FormControlLabel value='rent' control={<Radio />} label="Mi ta biba den kas di hür" />
                                                        <FormControlLabel value='fkp' control={<Radio />} label="Mi ta biba den kas di FKP" />
                                                        <FormControlLabel value='liveIn' control={<Radio />} label="Mi ta biba otro kaminda" />
                                                        <FormControlLabel value='other' control={<Radio />} label="Otro" />
                                                    </RadioGroup>
                                                }
                                                variant="outlined"
                                                name="notOwnHouse"
                                            //error={errors.notOwnHouse ? true : false}
                                            />
                                            {errors.notOwnHouse && <p style={{ color: '#bf1650' }}>{errors.notOwnHouse.message}</p>}
                                        </Grid>
                                    }
                                    {
                                        JSON.parse(watch('hasOwnHouse')) || (!JSON.parse(watch('hasOwnHouse')) && watch('notOwnHouse') !== '') ?
                                            <>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        color="textPrimary"
                                                        className={classes.inputTitle}
                                                    >
                                                        Adrès
                                                    </Typography>
                                                    <Controller
                                                        as={TextField}
                                                        id="houseAddress"
                                                        name="houseAddress"
                                                        label="Adrès"
                                                        variant="outlined"
                                                        fullWidth
                                                        control={control}
                                                        error={errors.houseAddress ? true : false}
                                                    />
                                                    {errors.houseAddress && <p style={{ color: '#bf1650' }}>{errors.houseAddress.message}</p>}
                                                </Grid>
                                                {watch('notOwnHouse') === 'other' &&
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="textPrimary"
                                                            className={classes.inputTitle}
                                                        >
                                                            Otro
                                                        </Typography>
                                                        <Controller
                                                            as={TextField}
                                                            id="otherHousing"
                                                            name="otherHousing"
                                                            label="Otro"
                                                            variant="outlined"
                                                            fullWidth
                                                            control={control}
                                                            error={errors.otherHousing ? true : false}
                                                        />
                                                        {errors.otherHousing && <p style={{ color: '#bf1650' }}>{errors.otherHousing.message}</p>}
                                                    </Grid>}
                                                {
                                                    JSON.parse(watch('hasOwnHouse')) ?
                                                        <>
                                                            <Grid item xs={12} sm={6}>
                                                                <Controller
                                                                    control={control}
                                                                    name="payingMortgage"
                                                                    error={errors.payingMortgage ? true : false}
                                                                    render={({ onChange, value }) => (
                                                                        <>
                                                                            <Typography
                                                                                variant="subtitle1"
                                                                                color="textPrimary"
                                                                                className={classes.inputTitle}
                                                                            >
                                                                                Bo ta paga hipotek?
                                                                            </Typography>
                                                                            <RadioGroup aria-label="payingMortgage" name="payingMortgage" value={JSON.parse(value)} onChange={onChange} row>
                                                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                                                            </RadioGroup>
                                                                        </>
                                                                    )}
                                                                />
                                                                {errors.payingMortgage && <p style={{ color: '#bf1650' }}>{errors.payingMortgage.message}</p>}
                                                            </Grid>
                                                            {
                                                                JSON.parse(watch('payingMortgage')) &&
                                                                <Grid item xs={12} sm={6}>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        color="textPrimary"
                                                                        className={classes.inputTitle}
                                                                    >
                                                                        Kuantu bo ta paga pa luna na hipotek?
                                                                    </Typography>
                                                                    <Controller
                                                                        as={TextField}
                                                                        id="houseMortgageDebt"
                                                                        name="houseMortgageDebt"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        control={control}
                                                                        error={errors.houseMortgageDebt ? true : false}
                                                                    />
                                                                    {errors.houseMortgageDebt && <p style={{ color: '#bf1650' }}>{errors.houseMortgageDebt.message}</p>}
                                                                </Grid>
                                                            }
                                                            {
                                                                watch('payingMortgage') !== null && !JSON.parse(watch('payingMortgage')) &&
                                                                <Grid item xs={12} sm={6}>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        color="textPrimary"
                                                                        className={classes.inputTitle}
                                                                    >
                                                                        Dikon nò?
                                                                    </Typography>
                                                                    <Controller
                                                                        as={TextField}
                                                                        id="reasonNotPayingMortgage"
                                                                        name="reasonNotPayingMortgage"
                                                                        label="Dikon nò?"
                                                                        variant="outlined"
                                                                        multiline
                                                                        minRows={3}
                                                                        fullWidth
                                                                        control={control}
                                                                        error={errors.reasonNotPayingMortgage ? true : false}
                                                                    />
                                                                    {errors.reasonNotPayingMortgage && <p style={{ color: '#bf1650' }}>{errors.reasonNotPayingMortgage.message}</p>}
                                                                </Grid>
                                                            }

                                                        </>
                                                        :
                                                        ['rent', 'fkp'].includes(watch('notOwnHouse')) ?
                                                            <Grid item xs={12} sm={6}>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    color="textPrimary"
                                                                    className={classes.inputTitle}
                                                                >
                                                                    Kuantu sèn bo ta paga na hür?
                                                                </Typography>
                                                                <Controller
                                                                    as={TextField}
                                                                    id="houseRentalPrice"
                                                                    name="houseRentalPrice"
                                                                    label="Kuantu sèn bo ta paga na hür?"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    control={control}
                                                                    error={errors.houseRentalPrice ? true : false}
                                                                />
                                                                {errors.houseRentalPrice && <p style={{ color: '#bf1650' }}>{errors.houseRentalPrice.message}</p>}
                                                            </Grid> : watch('notOwnHouse') === 'liveIn' &&
                                                            <>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        color="textPrimary"
                                                                        className={classes.inputTitle}
                                                                    >
                                                                        Por fabor spesifiká na unda bo ta kedando
                                                                    </Typography>
                                                                    <Controller
                                                                        as={TextField}
                                                                        id="liveInDescription"
                                                                        name="liveInDescription"
                                                                        label="Por fabor spesifiká na unda bo ta kedando"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        control={control}
                                                                        error={errors.liveInDescription ? true : false}
                                                                    />
                                                                    {errors.liveInDescription && <p style={{ color: '#bf1650' }}>{errors.liveInDescription.message}</p>}
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        color="textPrimary"
                                                                        className={classes.inputTitle}
                                                                    >
                                                                        Kiko ta bo kontribushon pa luna aki?
                                                                    </Typography>
                                                                    <Controller
                                                                        as={TextField}
                                                                        id="houseContribution"
                                                                        name="houseContribution"
                                                                        label="Kiko ta bo kontribushon pa luna aki?"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        control={control}
                                                                        error={errors.houseContribution ? true : false}
                                                                    />
                                                                    {errors.houseContribution && <p style={{ color: '#bf1650' }}>{errors.houseContribution.message}</p>}
                                                                </Grid>
                                                            </>

                                                }
                                                {!JSON.parse(watch('hasOwnHouse')) && watch('notOwnHouse') !== 'other' &&
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="textPrimary"
                                                            className={classes.inputTitle}
                                                        >
                                                            Ken mas ta biba den kas kubo? (skohe mas opshon si esaki ta e kaso)
                                                        </Typography>
                                                        {["Kasá", "Pareha", "Yu", "Ruman", "Otro famia", "Amigu/amiga"].map(name => (
                                                            <FormControlLabel
                                                                control={
                                                                    <Controller
                                                                        name="houseResidents"
                                                                        render={({ onChange, value }) => {
                                                                            return (
                                                                                <Checkbox
                                                                                    checked={value?.includes(name) || false}
                                                                                    onChange={() => onChange(handleHouseResidentsSelect(name))}

                                                                                />
                                                                            );
                                                                        }}
                                                                        control={control}
                                                                    />
                                                                }
                                                                key={name}
                                                                label={name}
                                                            />
                                                        ))}
                                                        {errors.dependents && <p style={{ color: '#bf1650' }}>{errors.dependents.message}</p>}
                                                    </Grid>}
                                                {
                                                    ['rent', 'fkp'].includes(watch('notOwnHouse')) &&
                                                    <>
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography
                                                                variant="subtitle1"
                                                                color="textPrimary"
                                                                className={classes.inputTitle}
                                                            >
                                                                ‘Upload’ kòntrakt di hur of apoderashon
                                                            </Typography>
                                                            <Controller
                                                                classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                                                as={DropzoneArea}
                                                                name="proofOfRentalContract"
                                                                dropzoneText="‘Upload’ kòntrakt di hur of apoderashon (image/* òf .pdf)"
                                                                control={control}
                                                                error={errors.proofOfRentalContract ? true : false}
                                                                onDrop={(files) => onDropHandler(files, 16)}
                                                                onDelete={(file) => onDeleteHandler(file, 16)}
                                                                filesLimit={1}
                                                                acceptedFiles={['image/*', '.pdf']}
                                                                showPreviews={true}
                                                                showPreviewsInDropzone={false}
                                                                useChipsForPreview
                                                                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                                previewChipProps={{ classes: { root: classes.previewChip } }}
                                                                previewText="Selected files"
                                                            />
                                                            {errors.proofOfRentalContract && <p style={{ color: '#bf1650' }}>{errors.proofOfRentalContract.message}</p>}
                                                        </Grid>
                                                        {
                                                            watch('notOwnHouse') === 'rent' &&
                                                            (<Grid item xs={12} sm={6}>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    color="textPrimary"
                                                                    className={classes.inputTitle}
                                                                >
                                                                    ‘Upload’ prueba di pago
                                                                </Typography>
                                                                <Controller
                                                                    classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                                                    as={DropzoneArea}
                                                                    name="proofOfRentalPayment"
                                                                    dropzoneText="‘Upload’ prueba di pago (image/* òf .pdf)"
                                                                    control={control}
                                                                    error={errors.proofOfRentalPayment ? true : false}
                                                                    onDrop={(files) => onDropHandler(files, 17)}
                                                                    onDelete={(file) => onDeleteHandler(file, 17)}
                                                                    filesLimit={1}
                                                                    acceptedFiles={['image/*', '.pdf']}
                                                                    showPreviews={true}
                                                                    showPreviewsInDropzone={false}
                                                                    useChipsForPreview
                                                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                                    previewChipProps={{ classes: { root: classes.previewChip } }}
                                                                    previewText="Selected files"
                                                                />
                                                                {errors.proofOfRentalPayment && <p style={{ color: '#bf1650' }}>{errors.proofOfRentalPayment.message}</p>}
                                                            </Grid>)
                                                        }
                                                    </>
                                                }

                                            </>
                                            : null
                                    }
                                </>
                            }


                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="hasSignupFkp"
                                    error={errors.hasSignupFkp ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Bo ta inskribí na FKP pa bo risibí un kas?
                                            </Typography>
                                            <RadioGroup aria-label="hasSignupFkp" name="hasSignupFkp" value={JSON.parse(value)} onChange={onChange} row>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasSignupFkp && <p style={{ color: '#bf1650' }}>{errors.hasSignupFkp.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasSignupFkp')) ?
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                For di ki aña?
                                            </Typography>
                                            <Controller
                                                id="signupFkpYear"
                                                name="signupFkpYear"
                                                render={({ value, onChange }) => (
                                                    <TextField
                                                        variant="outlined"
                                                        label="For di ki aña?"
                                                        fullWidth
                                                        type='number'
                                                        value={value || ''}
                                                        onChange={onChange}
                                                        error={errors.signupFkpYear ? true : false}
                                                    />
                                                )}
                                                control={control}
                                            />
                                            {errors.signupFkpYear && <p style={{ color: '#bf1650' }}>{errors.signupFkpYear.message}</p>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Kuantu punto bo tin di spar?
                                            </Typography>
                                            <Controller
                                                as={TextField}
                                                id="fkpPoints"
                                                name="fkpPoints"
                                                label="Kuantu punto bo tin di spar?"
                                                variant="outlined"
                                                type='number'
                                                fullWidth
                                                control={control}
                                                error={errors.fkpPoints ? true : false}
                                            />
                                            {errors.fkpPoints && <p style={{ color: '#bf1650' }}>{errors.fkpPoints.message}</p>}
                                        </Grid>
                                    </> : null
                            }
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="hasDependents"
                                    error={errors.hasDependents ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                25. Tin mas persona ta depende di bo finansieramente? (skohe mas opshon si esaki ta e kaso)
                                            </Typography>
                                            <RadioGroup aria-label="dependents" name="hasDependents" value={JSON.parse(value)} onChange={onChange} row>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasDependents && <p style={{ color: '#bf1650' }}>{errors.hasDependents.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasDependents')) ?
                                    <Grid item xs={12} >
                                        {["Kasá", "Pareha", "Yu", "Ruman", "Otro famia", "Amigu/amiga"].map(name => (
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        name="dependents"
                                                        render={({ onChange, value }) => {
                                                            return (
                                                                <Checkbox
                                                                    checked={value?.includes(name) || false}
                                                                    onChange={() => onChange(handleDependentsSelect(name))}

                                                                />
                                                            );
                                                        }}
                                                        control={control}
                                                    />
                                                }
                                                key={name}
                                                label={name}
                                            />
                                        ))}
                                        {errors.dependents && <p style={{ color: '#bf1650' }}>{errors.dependents.message}</p>}
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12}>
                                <Typography variant="h6" color="primary" className={classes.title}>
                                    Enseñansa
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    26. Kua ta e nivel di skol mas haltu ku bo a kaba?
                                </Typography>
                                <Controller
                                    control={control}
                                    as={
                                        <Select>
                                            <MenuItem value="None">Niun</MenuItem>
                                            <MenuItem value="BASIS">skol basiko</MenuItem>
                                            <MenuItem value="AGO">AGO</MenuItem>
                                            <MenuItem value="VSBO">VSBO</MenuItem>
                                            <MenuItem value="SBO">SBO</MenuItem>
                                            <MenuItem value="ZMLK">ZMLK</MenuItem>
                                            <MenuItem value="MLK">MLK</MenuItem>
                                            <MenuItem value="LTS">LTS</MenuItem>
                                            <MenuItem value="MTS">MTS</MenuItem>
                                            <MenuItem value="HTS">HTS</MenuItem>
                                            <MenuItem value="VSBO/MAVO">VSBO/MAVO</MenuItem>
                                            <MenuItem value="Huishoudschool">Huishoudschool</MenuItem>
                                            <MenuItem value="Estudio profeshonal finalisá">Estudio profeshonal finalisá</MenuItem>
                                            <MenuItem value="Havo">Havo</MenuItem>
                                            <MenuItem value="VWO">VWO</MenuItem>
                                            <MenuItem value="Bachelor">Nivel Bachelor</MenuItem>
                                            <MenuItem value="Academic">Nivel Akadémiko</MenuItem>
                                            <MenuItem value="PHD">PHD</MenuItem>
                                        </Select>
                                    }
                                    variant="outlined"
                                    fullWidth
                                    name="education"
                                    error={errors.education ? true : false}
                                />
                                {errors.education && <p style={{ color: '#bf1650' }}>{errors.education.message}</p>}
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <Controller
                                    control={control}
                                    name="hasCertificate"
                                    error={errors.hasCertificate ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Bo tin diploma?
                                            </Typography>
                                            <RadioGroup aria-label="hasCertificate" name="hasCertificate" value={JSON.parse(value)} onChange={onChange}>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasCertificate && <p style={{ color: '#bf1650' }}>{errors.hasCertificate.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasCertificate')) ?
                                    <Grid item xs={6} sm={4}>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary"
                                            className={classes.inputTitle}
                                        >
                                            Na ki aña bo a risibí e diploma?
                                        </Typography>
                                        <Controller
                                            control={control}
                                            id="certificateYear"
                                            name="certificateYear"
                                            render={({ value, onChange }) => (
                                                <TextField
                                                    value={value || ''}
                                                    onChange={onChange}
                                                    label="Na ki aña bo a risibí e diploma?"
                                                    variant="outlined"
                                                    fullWidth
                                                    error={errors.certificateYear ? true : false}
                                                />
                                            )}
                                        />
                                        {errors.certificateYear && <p style={{ color: '#bf1650' }}>{errors.certificateYear.message}</p>}
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="hasOtherCertificate"
                                    error={errors.hasOtherCertificate ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                27. Bo a risibí sertifikado di algun kurso/workshòp ku ba partisipá na dje?
                                            </Typography>
                                            <RadioGroup aria-label="hasOtherCertificate" name="hasOtherCertificate" value={JSON.parse(value)} onChange={onChange} row>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasOtherCertificate && <p style={{ color: '#bf1650' }}>{errors.hasOtherCertificate.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasOtherCertificate')) ?
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Por fabor indiká sertifikado di kua kurso/workshòp
                                            </Typography>
                                            <Controller
                                                as={TextField}
                                                id="otherCertificateDescription"
                                                name="otherCertificateDescription"
                                                label="Por fabor indiká sertifikado di kua kurso/workshòp"
                                                variant="outlined"
                                                fullWidth
                                                control={control}
                                                error={errors.otherCertificateDescription ? true : false}
                                            />
                                            {errors.otherCertificateDescription && <p style={{ color: '#bf1650' }}>{errors.otherCertificateDescription.message}</p>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Na ki aña bo a risibí e sertifikado?
                                            </Typography>
                                            <Controller
                                                as={TextField}
                                                id="otherCertificateYear"
                                                name="otherCertificateYear"
                                                label="Na ki aña bo a risibí e diploma?"
                                                variant="outlined"
                                                fullWidth
                                                control={control}
                                                error={errors.otherCertificateYear ? true : false}
                                            />
                                            {errors.otherCertificateYear && <p style={{ color: '#bf1650' }}>{errors.otherCertificateYear.message}</p>}
                                        </Grid>
                                    </>
                                    : null
                            }
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    control={control}
                                    name="hasCertificateWorkExperience"
                                    error={errors.hasCertificateWorkExperience ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                28. Bo a yega di traha den e sektor ku bo a studia aden?
                                            </Typography>
                                            <RadioGroup aria-label="hasCertificateWorkExperience" name="hasCertificateWorkExperience" value={JSON.parse(value)} onChange={onChange} row>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasCertificateWorkExperience && <p style={{ color: '#bf1650' }}>{errors.hasCertificateWorkExperience.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasCertificateWorkExperience')) ?
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary"
                                            className={classes.inputTitle}
                                        >
                                            Na kua kompania?
                                        </Typography>
                                        <Controller
                                            as={TextField}
                                            id="certificateWorkExperienceCompany"
                                            name="certificateWorkExperienceCompany"
                                            label="Na kua kompania?"
                                            variant="outlined"
                                            fullWidth
                                            control={control}
                                            error={errors.certificateWorkExperienceCompany ? true : false}
                                        />
                                        {errors.certificateWorkExperienceCompany && <p style={{ color: '#bf1650' }}>{errors.certificateWorkExperienceCompany.message}</p>}
                                    </Grid>
                                    :
                                    null

                            }
                            <Grid item xs={12}>
                                <Typography variant="h6" color="primary" className={classes.title}>
                                    Salú físiko i mental
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}>
                                    29. Kon bo estado di salú físiko ta?
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    Kon bo mobilidat ta?
                                </Typography>
                                <Controller
                                    control={control}
                                    as={
                                        <Select>
                                            <MenuItem value="good">Mi por kana bon</MenuItem>
                                            <MenuItem value="moderate">Mi por kana basta bon</MenuItem>
                                            <MenuItem value="bad">Mi no por kana</MenuItem>
                                        </Select>
                                    }
                                    variant="outlined"
                                    fullWidth
                                    name="mobility"
                                    error={errors.mobility ? true : false}
                                />
                                {errors.mobility && <p style={{ color: '#bf1650' }}>{errors.mobility.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    Kon bo oidu ta?
                                </Typography>
                                <Controller
                                    control={control}
                                    as={
                                        <Select>
                                            <MenuItem value="good">Bon</MenuItem>
                                            <MenuItem value="moderate">Mi tin redukshon di oido</MenuItem>
                                            <MenuItem value="bad">Dof</MenuItem>
                                            <MenuItem value="veryBad">Surdu</MenuItem>
                                        </Select>
                                    }
                                    variant="outlined"
                                    fullWidth
                                    name="hearing"
                                    error={errors.hearing ? true : false}
                                />
                                {errors.hearing && <p style={{ color: '#bf1650' }}>{errors.hearing.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    Kon bo bista ta?
                                </Typography>
                                <Controller
                                    control={control}
                                    as={
                                        <Select>
                                            <MenuItem value="good">Bon</MenuItem>
                                            <MenuItem value="moderate">Basta bon</MenuItem>
                                            <MenuItem value="bad">Mi no ta mira bon</MenuItem>
                                            <MenuItem value="veryBad">Siegu</MenuItem>
                                        </Select>
                                    }
                                    variant="outlined"
                                    fullWidth
                                    name="visibility"
                                    error={errors.visibility ? true : false}
                                />
                                {errors.visibility && <p style={{ color: '#bf1650' }}>{errors.visibility.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    Kon bo abla ta?
                                </Typography>
                                <Controller
                                    control={control}
                                    as={
                                        <Select>
                                            <MenuItem value="clear">Kla</MenuItem>
                                            <MenuItem value="stutter">Mi ta gaga</MenuItem>
                                            <MenuItem value="swish">Mi ta sles</MenuItem>
                                            <MenuItem value="other">Otro</MenuItem>
                                        </Select>
                                    }
                                    variant="outlined"
                                    fullWidth
                                    name="speakingAbility"
                                    error={errors.speakingAbility ? true : false}
                                />
                                {errors.speakingAbility && <p style={{ color: '#bf1650' }}>{errors.speakingAbility.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={JSON.parse(watch('hasAdiction')) ? 6 : false}>
                                <Controller
                                    control={control}
                                    name="hasAdiction"
                                    error={errors.hasAdiction ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Bo ta sufri di un òf mas adikshon?
                                            </Typography>
                                            <RadioGroup aria-label="hasAdiction" name="hasAdiction" value={JSON.parse(value)} onChange={onChange}>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasAdiction && <p style={{ color: '#bf1650' }}>{errors.hasAdiction.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasAdiction')) ?
                                    <Grid item xs={6} sm={JSON.parse(watch('hasAdictionTreatment')) ? 3 : false}>
                                        <Controller
                                            control={control}
                                            name="hasAdictionTreatment"
                                            error={errors.hasAdictionTreatment ? true : false}
                                            render={({ onChange, value }) => (
                                                <>
                                                    <Typography
                                                        variant="subtitle1"
                                                        color="textPrimary"
                                                        className={classes.inputTitle}
                                                    >
                                                        Bo ta hañando yudansa di un instansia?
                                                    </Typography>
                                                    <RadioGroup aria-label="hasAdictionTreatment" name="hasAdictionTreatment" value={JSON.parse(value)} onChange={onChange}>
                                                        <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                        <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                                    </RadioGroup>
                                                </>
                                            )}
                                        />
                                        {errors.hasAdictionTreatment && <p style={{ color: '#bf1650' }}>{errors.hasAdictionTreatment.message}</p>}
                                    </Grid>

                                    : null
                            }
                            {
                                JSON.parse(watch('hasAdictionTreatment')) ?
                                    <Grid item xs={6} sm={3}>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary"
                                            className={classes.inputTitle}
                                        >
                                            Por fabor indiká kua instansia
                                        </Typography>
                                        <Controller
                                            as={TextField}
                                            id="adictionTreatmentCenter"
                                            name="adictionTreatmentCenter"
                                            label="Por fabor indiká kua instansia"
                                            variant="outlined"
                                            fullWidth
                                            control={control}
                                            error={errors.adictionTreatmentCenter ? true : false}
                                        />
                                        {errors.adictionTreatmentCenter && <p style={{ color: '#bf1650' }}>{errors.adictionTreatmentCenter.message}</p>}
                                    </Grid> : null
                            }
                            <Grid item xs={12} sm={JSON.parse(watch('hasDiseases')) ? 6 : false}>
                                <Controller
                                    control={control}
                                    name="hasDiseases"
                                    error={errors.hasDiseases ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                30. Bo ta sufri di algun malesa
                                            </Typography>
                                            <RadioGroup aria-label="hasDiseases" name="hasDiseases" value={JSON.parse(value)} onChange={onChange}>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasDiseases && <p style={{ color: '#bf1650' }}>{errors.hasDiseases.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasDiseases')) ?
                                    <Grid item xs={12} >
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary"
                                            className={classes.inputTitle}
                                        >
                                            Skohe mas opshon si esaki ta e kaso
                                        </Typography>
                                        {["Alergia", "Asma", "Anemia", "Epilepsia", "Malesa di kurason i ader", "Preshon haltu", "Malesa di kueru", "Kanser", "Malesa di higra", "Malesa di stoma i tripa", "Malesa di nir", "Romatismo", "Keho di lomba i nèk", "Sikkel sèl", "Malesa venériko", "Diabetis", "Otro"]
                                            .map(name => (
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name="diseases"
                                                            render={({ onChange, value }) => {
                                                                return (
                                                                    <Checkbox
                                                                        checked={value?.includes(name) || false}
                                                                        onChange={() => onChange(handleDiseasesSelect(name))}

                                                                    />
                                                                );
                                                            }}
                                                            control={control}
                                                        />
                                                    }
                                                    key={name}
                                                    label={name}
                                                />
                                            ))}
                                        {errors.diseases && <p style={{ color: '#bf1650' }}>{errors.diseases.message}</p>}
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="hasMedicalTreatment"
                                    error={errors.hasMedicalTreatment ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                31. Bo ta bou di tratamentu di un médiko òf paramédiko?
                                            </Typography>
                                            <RadioGroup aria-label="hasMedicalTreatment" name="hasMedicalTreatment" value={JSON.parse(value)} onChange={onChange}>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasMedicalTreatment && <p style={{ color: '#bf1650' }}>{errors.hasMedicalTreatment.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasMedicalTreatment')) &&
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary"
                                            className={classes.inputTitle}
                                        >
                                            Ki tipo di médiko òf paramédiko?
                                        </Typography>
                                        <Controller
                                            control={control}
                                            as={
                                                <Select>
                                                    <MenuItem value="dietician">Dietista</MenuItem>
                                                    <MenuItem value="logopedist">Logopedist</MenuItem>
                                                    <MenuItem value="fysioterapist">Fysioterapista</MenuItem>
                                                    <MenuItem value="generalPractitioner">Dòkter di kas</MenuItem>
                                                    <MenuItem value="specialist">Spesialista médiko</MenuItem>
                                                    <MenuItem value="psychologist">Sikólogo</MenuItem>
                                                    <MenuItem value="psychiatrist">Sikiatra</MenuItem>
                                                    <MenuItem value="dentist">Dentista</MenuItem>
                                                    <MenuItem value="other">Otro</MenuItem>
                                                </Select>
                                            }
                                            variant="outlined"
                                            fullWidth
                                            name="medicalTreatment"
                                            error={errors.medicalTreatment ? true : false}
                                        />
                                        {errors.medicalTreatment && <p style={{ color: '#bf1650' }}>{errors.medicalTreatment.message}</p>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary"
                                            className={classes.inputTitle}
                                        >
                                            Ki ta e nòmber di e médiko òf paramédiko?
                                        </Typography>
                                        <Controller
                                            as={TextField}
                                            id="medicalPractitionerName"
                                            name="medicalPractitionerName"
                                            label="Ki ta e nòmber di e médiko òf paramédiko?"
                                            variant="outlined"
                                            fullWidth
                                            control={control}
                                            error={errors.medicalPractitionerName ? true : false}
                                        />
                                        {errors.medicalPractitionerName && <p style={{ color: '#bf1650' }}>{errors.medicalPractitionerName.message}</p>}
                                    </Grid>
                                </>
                            }
                            {
                                ["specialist", "psychologist", "psychiatrist"].includes(watch('medicalTreatment')) ?
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary"
                                            className={classes.inputTitle}
                                        >
                                            Por fabor ‘upload’ e karta di bo {
                                                watch('medicalTreatment') === 'specialist' ? 'Spesialista médiko' :
                                                    watch('medicalTreatment') === 'psychologist' ? 'Sikólogo' : watch('medicalTreatment') === 'psychologist' ? 'Sikiatra' : ''}
                                        </Typography>
                                        <Controller
                                            classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                            as={DropzoneArea}
                                            name="proofOfMedicalTreatment"
                                            dropzoneText={`Por fabor ‘upload’ e karta di bo ${watch('medicalTreatment') === 'specialist' ? 'Spesialista médiko' :
                                                watch('medicalTreatment') === 'psychologist' ? 'Sikólogo' : watch('medicalTreatment') === 'psychologist' ? 'Sikiatra' : ''} (image/* òf .pdf)`}
                                            control={control}
                                            error={errors.proofOfMedicalTreatment ? true : false}
                                            onDrop={(files) => onDropHandler(files, watch('medicalTreatment') === 'specialist' ? 12 : watch('medicalTreatment') === 'psychologist' ? 13 : watch('medicalTreatment') === 'psychiatrist' ? 14 : 12)}
                                            onDelete={(file) => onDeleteHandler(file, watch('medicalTreatment') === 'specialist' ? 12 : watch('medicalTreatment') === 'psychologist' ? 13 : watch('medicalTreatment') === 'psychiatrist' ? 14 : 12)}
                                            filesLimit={1}
                                            acceptedFiles={['image/*', '.pdf']}
                                            showPreviews={true}
                                            showPreviewsInDropzone={false}
                                            useChipsForPreview
                                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                            previewChipProps={{ classes: { root: classes.previewChip } }}
                                            previewText="Selected files"
                                        />
                                        {errors.proofOfMedicalTreatment && <p style={{ color: '#bf1650' }}>{errors.proofOfMedicalTreatment.message}</p>}
                                    </Grid> : null
                            }
                            <Grid item xs={12} >
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    32. Dor di bo limitashon bo ta hasi huzo di?
                                    (skohe mas opshon si esaki ta e kaso)
                                </Typography>
                                {["Rolatòr", "Ròlstül", "Aparato pa oido", "Implantashon na e oido", "Kachó ku ta kompaña un siegu", "Garoti", "Otro"]
                                    .map(name => (
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    name="equipments"
                                                    render={({ onChange, value }) => {
                                                        return (
                                                            <Checkbox
                                                                checked={value?.includes(name) || false}
                                                                onChange={() => onChange(handleEquipmentsSelect(name))}

                                                            />
                                                        );
                                                    }}
                                                    control={control}
                                                />
                                            }
                                            key={name}
                                            label={name}
                                        />
                                    ))}
                                {errors.equipments && <p style={{ color: '#bf1650' }}>{errors.equipments.message}</p>}
                            </Grid>
                            <Grid item xs={12} >
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    33. Kua instansia ta guia bo den esaki?
                                    (skohe mas opshon si esaki ta e kaso)
                                </Typography>
                                {["Mgr. Verriet", "Mi Abilidat", "Wit Gele Kruis", "Sosiedat di Siegu", "Otro"]
                                    .map(name => (
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    name="treatmentCenters"
                                                    render={({ onChange, value }) => {
                                                        return (
                                                            <Checkbox
                                                                checked={value?.includes(name) || false}
                                                                onChange={() => onChange(handleTreatmentCentersSelect(name))}

                                                            />
                                                        );
                                                    }}
                                                    control={control}
                                                />
                                            }
                                            key={name}
                                            label={name}
                                        />
                                    ))}
                                {errors.treatmentCenters && <p style={{ color: '#bf1650' }}>{errors.treatmentCenters.message}</p>}
                            </Grid>
                            {
                                watch('treatmentCenters').includes('Otro') &&
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant="subtitle1"
                                        color="textPrimary"
                                        className={classes.inputTitle}
                                    >
                                        Otro instansia
                                    </Typography>
                                    <Controller
                                        as={TextField}
                                        id="otherTreatmentCenter"
                                        name="otherTreatmentCenter"
                                        label="Otro instansia"
                                        variant="outlined"
                                        fullWidth
                                        control={control}
                                        error={errors.otherTreatmentCenter ? true : false}
                                    />
                                    {errors.otherTreatmentCenter && <p style={{ color: '#bf1650' }}>{errors.otherTreatmentCenter.message}</p>}
                                </Grid>

                            }
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="hasPsychologicalLimitation"
                                    error={errors.hasPsychologicalLimitation ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                34. Bo tin problema sígiko?
                                            </Typography>
                                            <RadioGroup aria-label="hasPsychologicalLimitation" name="hasPsychologicalLimitation" value={JSON.parse(value)} onChange={onChange}>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasPsychologicalLimitation && <p style={{ color: '#bf1650' }}>{errors.hasPsychologicalLimitation.message}</p>}
                            </Grid>
                            {
                                watch('hasPsychologicalLimitation') && JSON.parse(watch('hasPsychologicalLimitation')) &&
                                <Grid item xs={12} sm={JSON.parse(watch('hasPsychologicalLimitationTreatment')) ? 6 : false}>
                                    <Controller
                                        control={control}
                                        name="hasPsychologicalLimitationTreatment"
                                        error={errors.hasPsychologicalLimitationTreatment ? true : false}
                                        render={({ onChange, value }) => (
                                            <>
                                                <Typography
                                                    variant="subtitle1"
                                                    color="textPrimary"
                                                    className={classes.inputTitle}
                                                >
                                                    Bo ta haña yudansa di un instansia?
                                                </Typography>
                                                <RadioGroup aria-label="hasPsychologicalLimitationTreatment" name="hasPsychologicalLimitationTreatment" value={JSON.parse(value)} onChange={onChange}>
                                                    <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                    <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                                </RadioGroup>
                                            </>
                                        )}
                                    />
                                    {errors.hasPsychologicalLimitationTreatment && <p style={{ color: '#bf1650' }}>{errors.hasPsychologicalLimitationTreatment.message}</p>}
                                </Grid>
                            }
                            {
                                JSON.parse(watch('hasPsychologicalLimitationTreatment')) ?
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary"
                                            className={classes.inputTitle}
                                        >
                                            Por fabor indiká kua instansia?
                                        </Typography>
                                        <Controller
                                            as={TextField}
                                            id="psychologicalLimitationCenter"
                                            name="psychologicalLimitationCenter"
                                            label="Por fabor indiká kua instansia?"
                                            variant="outlined"
                                            fullWidth
                                            control={control}
                                            error={errors.psychologicalLimitationCenter ? true : false}
                                        />
                                        {errors.psychologicalLimitationCenter && <p style={{ color: '#bf1650' }}>{errors.psychologicalLimitationCenter.message}</p>}
                                    </Grid> : null
                            }
                            {
                                JSON.parse(watch('hasPsychologicalLimitation')) ?
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="hasPsychologicalLimitationDiagnostic"
                                            error={errors.hasPsychologicalLimitationTreatment ? true : false}
                                            render={({ onChange, value }) => (
                                                <>
                                                    <Typography
                                                        variant="subtitle1"
                                                        color="textPrimary"
                                                        className={classes.inputTitle}
                                                    >
                                                        Tin un diagnóstiko?
                                                    </Typography>
                                                    <RadioGroup aria-label="hasPsychologicalLimitationDiagnostic" name="hasPsychologicalLimitationDiagnostic" value={JSON.parse(value)} onChange={onChange}>
                                                        <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                        <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                                    </RadioGroup>
                                                </>
                                            )}
                                        />
                                        {errors.hasPsychologicalLimitationDiagnostic && <p style={{ color: '#bf1650' }}>{errors.hasPsychologicalLimitationDiagnostic.message}</p>}
                                    </Grid> : null
                            }
                            {
                                JSON.parse(watch('hasPsychologicalLimitationDiagnostic')) ?
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Ken a hasi e diagnóstiko aki?
                                            </Typography>
                                            <Controller
                                                as={TextField}
                                                id="psychologicalLimitationDiagnostician"
                                                name="psychologicalLimitationDiagnostician"
                                                label="Ken a hasi e diagnóstiko aki?"
                                                variant="outlined"
                                                fullWidth
                                                control={control}
                                                error={errors.psychologicalLimitationDiagnostician ? true : false}
                                            />
                                            {errors.psychologicalLimitationDiagnostician && <p style={{ color: '#bf1650' }}>{errors.psychologicalLimitationDiagnostician.message}</p>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Fecha di diagnóstiko
                                            </Typography>
                                            <DateDropdown
                                                name="psychologicalLimitationDiagnosticDate"
                                                startYear={new Date().getFullYear() - 10}
                                                endYear={new Date().getFullYear() + 10}
                                                register={register}
                                                setDateValue={handlePsychologicalLimitationDiagnosticDate}

                                            />
                                            {errors.psychologicalLimitationDiagnosticDate && <p style={{ color: '#bf1650' }}>{errors.psychologicalLimitationDiagnosticDate.message}</p>}
                                        </Grid>
                                        <Grid item xs={12} sm={JSON.parse(watch('hasPsychologicalLimitationDiagnosticReport')) ? 6 : false}>
                                            <Controller
                                                control={control}
                                                name="hasPsychologicalLimitationDiagnosticReport"
                                                error={errors.hasPsychologicalLimitationDiagnosticReport ? true : false}
                                                render={({ onChange, value }) => (
                                                    <>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="textPrimary"
                                                            className={classes.inputTitle}
                                                        >
                                                            Tin un rapòrt di e diagnóstiko?
                                                        </Typography>
                                                        <RadioGroup aria-label="hasPsychologicalLimitationDiagnosticReport" name="hasPsychologicalLimitationDiagnosticReport" value={JSON.parse(value)} onChange={onChange}>
                                                            <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                            <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                                        </RadioGroup>
                                                    </>
                                                )}
                                            />
                                            {errors.hasPsychologicalLimitationDiagnosticReport && <p style={{ color: '#bf1650' }}>{errors.hasPsychologicalLimitationDiagnosticReport.message}</p>}
                                        </Grid>
                                        {
                                            JSON.parse(watch('hasPsychologicalLimitationDiagnosticReport')) ?
                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        color="textPrimary"
                                                        className={classes.inputTitle}
                                                    >
                                                        ‘Upload’ e karta di diagnóstiko di bo médiko
                                                    </Typography>
                                                    <Controller
                                                        classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                                        as={DropzoneArea}
                                                        name="proofOfPsychologicalLimitationDiagnosticReport"
                                                        dropzoneText="‘Upload’ e karta di diagnóstiko di bo médiko (image/* òf .pdf)"
                                                        control={control}
                                                        error={errors.proofOfPsychologicalLimitationDiagnosticReport ? true : false}
                                                        onDrop={(files) => onDropHandler(files, 18)}
                                                        onDelete={(file) => onDeleteHandler(file, 18)}
                                                        filesLimit={1}
                                                        acceptedFiles={['image/*', '.pdf']}
                                                        showPreviews={true}
                                                        showPreviewsInDropzone={false}
                                                        useChipsForPreview
                                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                        previewChipProps={{ classes: { root: classes.previewChip } }}
                                                        previewText="Selected files"
                                                    />
                                                    {errors.proofOfPsychologicalLimitationDiagnosticReport && <p style={{ color: '#bf1650' }}>{errors.proofOfPsychologicalLimitationDiagnosticReport.message}</p>}
                                                </Grid> : null
                                        }
                                    </>
                                    : null
                            }

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="hasMentalDisorder"
                                    error={errors.hasMentalDisorder ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Bo tin retardashon mental ?
                                            </Typography>
                                            <RadioGroup aria-label="hasMentalDisorder" name="hasMentalDisorder" value={JSON.parse(value)} onChange={onChange}>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasMentalDisorder && <p style={{ color: '#bf1650' }}>{errors.hasMentalDisorder.message}</p>}
                            </Grid>
                            {
                                watch('hasMentalDisorder') && JSON.parse(watch('hasMentalDisorder')) &&
                                <Grid item xs={12} sm={JSON.parse(watch('hasMentalDisorderTreatment')) ? 6 : false}>
                                    <Controller
                                        control={control}
                                        name="hasMentalDisorderTreatment"
                                        error={errors.hasMentalDisorderTreatment ? true : false}
                                        render={({ onChange, value }) => (
                                            <>
                                                <Typography
                                                    variant="subtitle1"
                                                    color="textPrimary"
                                                    className={classes.inputTitle}
                                                >
                                                    Bo ta haña yudansa di un instansia?
                                                </Typography>
                                                <RadioGroup aria-label="hasMentalDisorderTreatment" name="hasMentalDisorderTreatment" value={JSON.parse(value)} onChange={onChange}>
                                                    <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                    <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                                </RadioGroup>
                                            </>
                                        )}
                                    />
                                    {errors.hasMentalDisorderTreatment && <p style={{ color: '#bf1650' }}>{errors.hasMentalDisorderTreatment.message}</p>}
                                </Grid>
                            }
                            {
                                JSON.parse(watch('hasMentalDisorderTreatment')) &&
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant="subtitle1"
                                        color="textPrimary"
                                        className={classes.inputTitle}
                                    >
                                        Por fabor indiká kua instansia?
                                    </Typography>
                                    <Controller
                                        as={TextField}
                                        id="mentalDisorderTreatmentCenter"
                                        name="mentalDisorderTreatmentCenter"
                                        label="Por fabor indiká kua instansia?"
                                        variant="outlined"
                                        fullWidth
                                        control={control}
                                        error={errors.mentalDisorderTreatmentCenter ? true : false}
                                    />
                                    {errors.mentalDisorderTreatmentCenter && <p style={{ color: '#bf1650' }}>{errors.mentalDisorderTreatmentCenter.message}</p>}
                                </Grid>
                            }
                            {
                                JSON.parse(watch('hasMentalDisorder')) ?
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="hasMentalDisorderDiagnostic"
                                            error={errors.hasMentalDisorderTreatment ? true : false}
                                            render={({ onChange, value }) => (
                                                <>
                                                    <Typography
                                                        variant="subtitle1"
                                                        color="textPrimary"
                                                        className={classes.inputTitle}
                                                    >
                                                        Tin un diagnóstiko?
                                                    </Typography>
                                                    <RadioGroup aria-label="hasMentalDisorderDiagnostic" name="hasMentalDisorderDiagnostic" value={JSON.parse(value)} onChange={onChange}>
                                                        <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                        <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                                    </RadioGroup>
                                                </>
                                            )}
                                        />
                                        {errors.hasMentalDisorderDiagnostic && <p style={{ color: '#bf1650' }}>{errors.hasMentalDisorderDiagnostic.message}</p>}
                                    </Grid> : null
                            }
                            {
                                JSON.parse(watch('hasMentalDisorderDiagnostic')) ?
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Ken a hasi e diagnóstiko aki?
                                            </Typography>
                                            <Controller
                                                as={TextField}
                                                id="mentalDisorderDiagnostician"
                                                name="mentalDisorderDiagnostician"
                                                label="Ken a hasi e diagnóstiko aki?"
                                                variant="outlined"
                                                fullWidth
                                                control={control}
                                                error={errors.mentalDisorderDiagnostician ? true : false}
                                            />
                                            {errors.mentalDisorderDiagnostician && <p style={{ color: '#bf1650' }}>{errors.mentalDisorderDiagnostician.message}</p>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Fecha di diagnóstiko
                                            </Typography>
                                            <DateDropdown
                                                name="mentalDisorderDiagnosticDate"
                                                startYear={new Date().getFullYear() - 10}
                                                endYear={new Date().getFullYear() + 10}
                                                register={register}
                                                setDateValue={handleMentalDisorderDiagnosticDate}

                                            />
                                            {errors.mentalDisorderDiagnosticDate && <p style={{ color: '#bf1650' }}>{errors.mentalDisorderDiagnosticDate.message}</p>}
                                        </Grid>
                                        <Grid item xs={12} sm={JSON.parse(watch('hasMentalDisorderDiagnosticReport')) ? 6 : false}>
                                            <Controller
                                                control={control}
                                                name="hasMentalDisorderDiagnosticReport"
                                                error={errors.hasMentalDisorderDiagnosisDateReport ? true : false}
                                                render={({ onChange, value }) => (
                                                    <>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="textPrimary"
                                                            className={classes.inputTitle}
                                                        >
                                                            Tin un rapòrt di e diagnóstiko?
                                                        </Typography>
                                                        <RadioGroup aria-label="hasMentalDisorderDiagnosticReport" name="hasMentalDisorderDiagnosticReport" value={JSON.parse(value)} onChange={onChange}>
                                                            <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                            <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                                        </RadioGroup>
                                                    </>
                                                )}
                                            />
                                            {errors.hasMentalDisorderDiagnosticReport && <p style={{ color: '#bf1650' }}>{errors.hasMentalDisorderDiagnosticReport.message}</p>}
                                        </Grid>
                                        {
                                            JSON.parse(watch('hasMentalDisorderDiagnosticReport')) ?
                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        color="textPrimary"
                                                        className={classes.inputTitle}
                                                    >
                                                        ‘Upload’ e karta di diagnóstiko di bo médiko
                                                    </Typography>
                                                    <Controller
                                                        classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                                                        as={DropzoneArea}
                                                        name="proofOfMentalDisorderDiagnosticReport"
                                                        dropzoneText="‘Upload’ e karta di diagnóstiko di bo médiko (image/* òf .pdf)"
                                                        control={control}
                                                        error={errors.proofOfMentalDisorderDiagnosticReport ? true : false}
                                                        onDrop={(files) => onDropHandler(files, 19)}
                                                        onDelete={(file) => onDeleteHandler(file, 19)}
                                                        filesLimit={1}
                                                        acceptedFiles={['image/*', '.pdf']}
                                                        showPreviews={true}
                                                        showPreviewsInDropzone={false}
                                                        useChipsForPreview
                                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                        previewChipProps={{ classes: { root: classes.previewChip } }}
                                                        previewText="Selected files"
                                                    />
                                                    {errors.proofOfMentalDisorderDiagnosticReport && <p style={{ color: '#bf1650' }}>{errors.proofOfMentalDisorderDiagnosticReport.message}</p>}
                                                </Grid> : null
                                        }
                                    </>
                                    : null
                            }
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="hasPsychologicalLimitationChild"
                                    error={errors.hasPsychologicalLimitationChild ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                35. Bo tin yu ku limitashon mental?
                                            </Typography>
                                            <RadioGroup aria-label="hasPsychologicalLimitationChild" name="hasPsychologicalLimitationChild" value={JSON.parse(value)} onChange={onChange}>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasPsychologicalLimitationChild && <p style={{ color: '#bf1650' }}>{errors.hasPsychologicalLimitationChild.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    className={classes.inputTitle}
                                >
                                    36. Ki tipo di seguro bo tin?
                                </Typography>
                                <Controller
                                    control={control}
                                    as={
                                        <Select>
                                            <MenuItem value="SVB">SVB</MenuItem>
                                            <MenuItem value="SVB+">SVB+</MenuItem>
                                            <MenuItem value="Ennia">Ennia</MenuItem>
                                            <MenuItem value="FZOG">FZOG</MenuItem>
                                            <MenuItem value="Partikulir">Partikulir</MenuItem>
                                            <MenuItem value="other">Otro</MenuItem>
                                        </Select>
                                    }
                                    variant="outlined"
                                    fullWidth
                                    name="insurance"
                                    error={errors.insurance ? true : false}
                                />
                                {errors.insurance && <p style={{ color: '#bf1650' }}>{errors.insurance.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            </Grid>
                            <Grid item xs={12} sm={JSON.parse(watch('useMedicalSupplies')) ? 6 : false}>
                                <Controller
                                    control={control}
                                    name="useMedicalSupplies"
                                    error={errors.useMedicalSupplies ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                37. Bo ta usa medikamentu?
                                            </Typography>
                                            <RadioGroup aria-label="useMedicalSupplies" name="useMedicalSupplies" value={JSON.parse(value)} onChange={onChange}>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.useMedicalSupplies && <p style={{ color: '#bf1650' }}>{errors.useMedicalSupplies.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('useMedicalSupplies')) &&
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant="subtitle1"
                                        color="textPrimary"
                                        className={classes.inputTitle}
                                    >
                                        Ki tipo di medikamentu?
                                    </Typography>
                                    <Controller
                                        as={TextField}
                                        id="medicalSupplies"
                                        name="medicalSupplies"
                                        label="Ki tipo di medikamentu?"
                                        variant="outlined"
                                        fullWidth
                                        control={control}
                                        error={errors.medicalSupplies ? true : false}
                                    />
                                    {errors.medicalSupplies && <p style={{ color: '#bf1650' }}>{errors.medicalSupplies.message}</p>}
                                </Grid>

                            }
                            <Grid item xs={12} sm={JSON.parse(watch('hasWelfare')) ? 6 : null}>
                                <Controller
                                    control={control}
                                    name="hasWelfare"
                                    error={errors.hasWelfare ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                38. Bo ta risibí yudansa sosial pa motibu di bo estado di salú?
                                            </Typography>
                                            <RadioGroup aria-label="hasWelfare" name="hasWelfare" value={JSON.parse(value)} onChange={onChange}>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasWelfare && <p style={{ color: '#bf1650' }}>{errors.hasWelfare.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasWelfare')) &&
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary"
                                            className={classes.inputTitle}
                                        >
                                            Ki tipo di yudansa?
                                        </Typography>
                                        <Controller
                                            as={TextField}
                                            id="welfare"
                                            name="welfare"
                                            label="Ki tipo di yudansa?"
                                            variant="outlined"
                                            fullWidth
                                            control={control}
                                            error={errors.welfare ? true : false}
                                        />
                                        {errors.welfare && <p style={{ color: '#bf1650' }}>{errors.welfare.message}</p>}
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    control={control}
                                    name="hasFuneralInsurance"
                                    error={errors.hasFuneralInsurance ? true : false}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                39. Bo tin seguro di entiero?
                                            </Typography>
                                            <RadioGroup aria-label="hasFuneralInsurance" name="hasFuneralInsurance" value={JSON.parse(value)} onChange={onChange}>
                                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel value={false} control={<Radio />} label="Nò" />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                                {errors.hasFuneralInsurance && <p style={{ color: '#bf1650' }}>{errors.hasFuneralInsurance.message}</p>}
                            </Grid>
                            {
                                JSON.parse(watch('hasFuneralInsurance')) &&
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant="subtitle1"
                                        color="textPrimary"
                                        className={classes.inputTitle}
                                    >
                                        Por fabor indiká na kua kompania
                                    </Typography>
                                    <Controller
                                        control={control}
                                        as={
                                            <Select>
                                                <MenuItem value="AON Dutch Caribbean">AON Dutch Caribbean</MenuItem>
                                                <MenuItem value="Ennia">Ennia</MenuItem>
                                                <MenuItem value="Entiero na Quota">Entiero na Quota</MenuItem>
                                                <MenuItem value="Extura Curaçao">Extura Curaçao</MenuItem>
                                                <MenuItem value="International Insurance N.V.">International Insurance N.V.</MenuItem>
                                                <MenuItem value="Progressive Insurance">Progressive Insurance</MenuItem>
                                                <MenuItem value="Seguros Anta">Seguros Anta</MenuItem>
                                                <MenuItem value="Guardian Group">Guardian Group</MenuItem>
                                                <MenuItem value="Aska Verzekeringen">Aska Verzekeringen</MenuItem>
                                                <MenuItem value="Inter-Assure Insurance">Inter-Assure Insurance</MenuItem>
                                                <MenuItem value="Nagico Insurances">Nagico Insurances</MenuItem>
                                                <MenuItem value="Sagicor Capital Life">Sagicor Capital Life</MenuItem>
                                                <MenuItem value="Onderlinge hulp">Onderlinge hulp</MenuItem>
                                                <MenuItem value="The New India Assurance CO LTD">The New India Assurance CO LTD</MenuItem>
                                                <MenuItem value="El Tributo">El Tributo</MenuItem>
                                                <MenuItem value="B-sure">B-sure</MenuItem>
                                                <MenuItem value="AVM uitvaartverzekeringen">AVM uitvaartverzekeringen</MenuItem>
                                                <MenuItem value="El Señorial">El Señorial</MenuItem>
                                                <MenuItem value="El Consolador">El Consolador</MenuItem>
                                                <MenuItem value="Sitter">Sitter</MenuItem>
                                                <MenuItem value="Otro">Otro</MenuItem>
                                            </Select>
                                        }
                                        variant="outlined"
                                        fullWidth
                                        name="funeralInsurance"
                                        error={errors.funeralInsurance ? true : false}
                                    />
                                    {errors.funeralInsurance && <p style={{ color: '#bf1650' }}>{errors.funeralInsurance.message}</p>}
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="confirmation"
                                    render={({ onChange, value }) => (

                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                className={classes.inputTitle}
                                            >
                                                Konfirmashon
                                            </Typography>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        name="confirmation"
                                                        value={value}
                                                        onChange={e => onChange(e.target.checked)}
                                                        style={{ alignSelf: 'start', paddingTop: 0 }}
                                                    />}

                                                label="Mi ta deklará ku mi a kontestá tur e preguntanan di e formulario mas kompleto posibel i segun bèrdat.
                        Mi ta pèrmití e autoridatnan di Gobiernu di Korsou pa kontrolá si mi ta bin na remarke pa mi petishon, pa bishitá kaminda mi ta biba kontrolando esey i pa verifiká mi datos nan i interkambiá mi informashon ku otro instansia relevante, por ehempel registro nan di residente ('Kranshi'), di trahador (SVB, Kamera di Komersio, MEO, posibel dunador nan di trabou, etc.), di poseshon di bien nan immobil (por ehempel kas, outo, boto), departamentu di estadistika, etc.
                        Mi ta komprondé ku dunamentu di informashon inkompleto i/òf inkorekto i/òf uso di dokumentunan falsu ta un hecho kastigabel, i por tin komo konsekuensha ku ta revoká e desishon di duna ònderstant i ku lo pasa e kaso den man di òutoridatnan hudisial, i ku lo rekobrá fondo nan ku a risibí."
                                                labelPlacement="end"
                                            />
                                        </>
                                    )}
                                />
                                {errors.confirmation && <p style={{ color: '#bf1650' }}>{errors.confirmation.message}</p>}
                            </Grid>
                            <Grid container item alignItems="center" justifyContent="center" xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color={isLoading ? 'default' : 'primary'}
                                    size="large"
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {isLoading ? <CircularProgress /> : 'Entregá petishon'}
                                </Button>
                            </Grid>
                            <Grid container item alignItems="center" justifyContent="center" xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    type="button"
                                    color="secondary"
                                    size="large"
                                    onClick={() => reset()}
                                >
                                    Kanselá
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                {Object.values(errors).length !== 0 && <Alert severity='error'>{Object.values(errors).map((error, key) => <p key={key}>{error.message}</p>)}</Alert>}
                            </Grid>
                        </Grid>
                    </div >
    );
};

General.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
};

export default General;
