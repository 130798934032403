import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Topbar, Footer, Sidebar } from './components';
import { useKeycloak } from '@react-keycloak/web';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
    },
}));

const Main = props => {
    const { children } = props;
    const [keyCloak] = useKeycloak();
    const classes = useStyles();

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const pages = {
        admin: {
            title: 'Admin',
            id: 'admin',
            role: 'SoawAdmin',
            children: {
                requests: {
                    title: 'Lista di Petishon',
                    href: '/admin'
                },
                googleMapsSearch: {
                    title: 'Google Maps',
                    href: '/google-maps-search'
                },
                dashboard: {
                    title: 'Dashboard',
                    href: '/dashboard'
                },
            }
        },
        request: {
            title: 'Petishon',
            id: 'request',
            role: 'SoawClient',
            children: {
                resilienceForm: {
                    title: 'Karchi sosial 2',
                    href: '/resilience-form'
                },
                aidForm: {
                    title: 'Bijstand',
                    href: '/request-form-stepper'
                },
                foodstampsForm: {
                    title: 'Voedselpakket',
                    href: '/foodpackage-form'
                },
                status: {
                    title: 'Estado Bijstand',
                    href: '/request-status'
                },
                reapply: {
                    title: 'Aplíka di nobo',
                    href: '/request-form-stepper?reapply=true'
                }
            }

        },
        signup: {
            title: 'Registra',
            id: 'signup',
            href: '/signup',
            onClick: () => keyCloak.register()
        },
        signin: {
            title: 'Login',
            id: 'login',
            href: '/signin',
            onClick: () => keyCloak.login()
        },
        signout: {
            title: 'Logout',
            id: 'logout',
            href: '/signout',
            onClick: () => keyCloak.logout()
        },
        account: {
            title: 'Kuenta',
            id: 'account',
            children: {
                signup: {
                    title: 'Registra',
                    href: '/signup',
                    onClick: () => keyCloak.register()
                },
                signin: {
                    title: 'Login',
                    href: '/signin',
                    onClick: () => keyCloak.login()
                },
                signout: {
                    title: 'Logout',
                    href: '/signout',
                    onClick: () => keyCloak.logout()
                }
            },
        },
    };

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    const open = isMd ? false : openSidebar;

    return (
        <div
            className={clsx({
                [classes.root]: true,
            })}
        >
            <Topbar onSidebarOpen={handleSidebarOpen} pages={pages} />

            <Sidebar
                onClose={handleSidebarClose}
                open={open}
                variant="temporary"
                pages={pages}
            />
            <main>{children}</main>
            <Footer pages={pages} />
        </div>
    );
};

Main.propTypes = {
    children: PropTypes.node,
};

export default Main;
