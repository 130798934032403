import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRouteWithLayout = props => {
    const { layout: Layout, component: Component, roles, ...rest } = props;
    const [keycloak] = useKeycloak();

    const isAuthorized = (roles) => {
        if (keycloak && roles) {
            return roles.some(r => {
                const realm = keycloak.hasRealmRole(r);
                const resource = keycloak.hasResourceRole(r);
                return realm || resource;
            })
        }
        return false;
    }
    return (
        <Route
            {...rest}
            render={matchProps => {
                return isAuthorized(roles) ?
                    <Layout>
                        <Component {...matchProps} />
                    </Layout>
                    :
                    <Redirect to={{ pathname: '/', }} />
            }}
        />
    );
};

PrivateRouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
};

export default PrivateRouteWithLayout;
