import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { LearnMoreLink } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
    root: {},
    formContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
        maxWidth: 500,
        margin: `0 auto`,
    },
    section: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    label: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
}));

const Error = props => {
    const { history } = props;

    const classes = useStyles();


    return (
        <div className={classes.root}>
            <Section className={classes.section}>
                <div className={classes.formContainer}>
                    <SectionHeader
                        label="404"
                        title="Uh oh."
                        subtitle={
                            <span>
                                Na e momentu aki nos ta hasiendo kambionan tekniko pa duna kliente ku mester apliká un miho servisio. Nos kier pidi un i tur nos diskulpa pa e inkubenensia i pidibu pa registra awe despues di 4:30
                            </span>
                        }
                        titleProps={{
                            variant: 'h3',
                        }}
                        labelProps={{
                            color: 'secondary',
                            className: classes.label,
                            variant: 'h5',
                        }}
                        disableGutter
                    />
                </div>
            </Section>
        </div>
    );
};

export default withRouter(Error);
